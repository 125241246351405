import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Header from '../Pages/Header';
import Sidebar from '../Pages/Sidebar';
import axios from 'axios';
import { PAY_URL } from '../../../BaseURL';
import toast from 'react-hot-toast';
import { Pagination, Search } from '../Table';
import CopyRight from '../Pages/CopyRight';
import Loader from '../Pages/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDepartment } from '../../../Store/User/Department/UserDepartment';
import { getUserDesignation } from '../../../Store/User/Designation/getUserDesignation';
import { getUserBank } from '../../../Store/User/Bank/UserBank';
import { MultiSelect } from "react-multi-select-component";
import { Collapse, Dropdown } from 'react-bootstrap';


const Report = () => {

    const navigate = useNavigate();
    const [entity, setEntity] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [limit, setlimit] = useState(10);
    const [disable, setDisable] = useState(true);
    const [month, setMonth] = useState('');
    const [disable2, setDisable2] = useState(false);
    const dispatch = useDispatch();

    const [isStop, setIsStop] = useState('');
    const [selectedDepartment, setSelectedDepartment] = useState([]);
    const [selectedDesignation, setSelectedDesignation] = useState([]);
    const [selectedBank, setSelectedBank] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState([]);
    const [openFilter, setOpenFilter] = useState(false);
    const [selectAll, setSelectAll] = useState(false);

    const [fullFilteredData, setFullFilteredData] = useState([]);
    const [elapsedTime, setElapsedTime] = useState({ minutes: 0, seconds: 0 });

    useEffect(() => {
        if (localStorage.getItem('PAY_USER_TOKEN') === null) {
            navigate("/user/login");
        }

        if (disable === true) {
            getEmployee();
            setEntity([]);
        }
        // eslint-disable-next-line
    }, [disable, navigate]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([
                    dispatch(getUserDepartment()),
                    dispatch(getUserDesignation()),
                    dispatch(getUserBank())
                ]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [dispatch]);

    const deapartmentData = useSelector(state => state?.getUserDepartment?.user?.data);
    const designationData = useSelector(state => state?.getUserDesignation?.user?.data);
    const bankData = useSelector(state => state?.getUserBank?.user?.data);

    const commentsData = useMemo(() => {
        let computedComments = entity;

        if (search) {
            computedComments = computedComments.filter(
                (report) =>
                    report?.full_name?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    // report?.mobile_number?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    report?.card_no?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    report?.employee_id?.toString()?.toLowerCase()?.includes(search?.toLowerCase())
                // report?.adhar_no?.toString()?.toLowerCase()?.includes(search?.toLowerCase()) ||
                // report?.email?.toLowerCase()?.includes(search?.toLowerCase())
            );
        }

        if (selectedDesignation?.length > 0) {
            computedComments = computedComments.filter((report) => {
                return selectedDesignation.some(desig => desig.value === report.designation?._id);
            });
        }

        if (selectedDepartment?.length > 0) {
            computedComments = computedComments.filter((report) => {

                const salary = report?.salary_id;
                if (!salary) {
                    return false;
                }
                return selectedDepartment.some(de => de.value === salary?.department?._id);
            });
        }

        if (selectedBank?.length > 0) {
            computedComments = computedComments.filter((report) => {

                const salary = report?.salary_id;
                if (!salary) {
                    return false;
                }
                return selectedBank.some(bank => bank.value === salary?.bank_name?._id);
            });
        }

        if (isStop !== '') {
            const isStopped = isStop === '0' ? false : true;

            computedComments = computedComments.filter((report) => {
                const salary = report?.salary_id;
                if (!salary) {
                    return false;
                }

                return salary.is_stop_salary === isStopped;
            });
        }

        setTotalItems(computedComments?.length);
        setFullFilteredData(computedComments);

        // Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort(
                (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        return computedComments?.slice(
            (currentPage - 1) * limit,
            (currentPage - 1) * limit + limit
        );

    }, [currentPage, search, limit, sorting, entity, selectedDesignation, selectedDepartment, selectedBank, isStop]);

    const getEmployee = (e) => {
        const myurl = `${PAY_URL}/user/get-all-employee?month=${month}`;
        axios({
            method: "get",
            url: myurl,
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('PAY_USER_TOKEN') },
        }).then(async (response) => {
            if (response?.data?.success) {
                const data = response?.data?.data
                const filterData = data.filter((e) => e.firm_id === localStorage.getItem('PAY_USER_FIRM_ID') && e?.salary_id !== null);

                setEntity(filterData);
                setDisable(false);
            } else {
                toast.error("Something went wrong");
                setDisable(false);
            }
        }).catch((error) => {
            toast.error(error.response.data.message || "Something went wrong");
            console?.log("Errors", error);
            setDisable(false);
        });
    }

    const handleMonthChange = (event) => {
        const selectedMonth = event.target.value;
        setMonth(selectedMonth);
        setDisable(true);
    };

    const handleDepartment = (e) => {
        setSelectedDepartment(e);
    }
    const handleDesignation = (e) => {
        setSelectedDesignation(e)
    }
    const handleBank = (e) => {
        setSelectedBank(e)
    }

    const handleCheckEmployee = (e, id) => {
        if (e.target.checked) {
            setSelectedEmployee(prevState => [...prevState, { id: id }]);
        } else {
            setSelectedEmployee(prevState => prevState.filter(emp => emp.id !== id));
        }
    }


    // ===== With out filter data checking=====
    // useEffect(() => {
    //     const allSelected = entity?.length > 0 && selectedEmployee?.length === entity?.length;
    //     setSelectAll(allSelected);
    // }, [entity, selectedEmployee]);

    // const handleCheckAllEmpployee = (e) => {
    //     const isChecked = e.target.checked;
    //     setSelectAll(isChecked);

    //     if (isChecked) {
    //         const allEmployeeIds = entity?.map(elem => ({ id: elem?._id }));
    //         setSelectedEmployee(allEmployeeIds);
    //     } else {
    //         setSelectedEmployee([]);
    //     }
    // }

    // ===== With filter data checking=====

    const handleCheckAllEmpployee = (e) => {
        const isChecked = e.target.checked;
        setSelectAll(isChecked);

        if (isChecked) {
            const allEmployeeIds = fullFilteredData?.map(elem => ({ id: elem?._id }));
            setSelectedEmployee(allEmployeeIds);
        } else {
            setSelectedEmployee([]);
        }
    };

    useEffect(() => {
        const allSelected = fullFilteredData?.length > 0 && selectedEmployee?.length === fullFilteredData?.length;
        setSelectAll(allSelected);
    }, [fullFilteredData, selectedEmployee]);

    const handleRefresh = () => {
        setSearch("");
        setSorting({ field: "", order: "" });
        setCurrentPage(1);
        setSelectedEmployee([]);
        setSelectedDepartment([]);
        setSelectedDesignation([]);
        setDisable(true);
    }

    const handleReport = async (id) => {
        if (!month) {
            toast.error('Please select a month.');
            return;
        }
        try {
            setElapsedTime({ minutes: 0, seconds: 0 });

            const startTime = new Date().getTime(); // Capture the start time

            const timerInterval = setInterval(() => {
                const currentTime = new Date().getTime();
                const totalSeconds = Math.floor((currentTime - startTime) / 1000);
                const minutes = Math.floor(totalSeconds / 60);
                const seconds = totalSeconds % 60;
                setElapsedTime({ minutes, seconds });
            }, 1000);

            setDisable2(true);
            const salaryResponse = await axios.post(
                `${PAY_URL}/user/mutiple-employee-salary`,
                {
                    firm_id: localStorage.getItem('PAY_USER_FIRM_ID'),
                    employees: JSON.stringify(selectedEmployee),
                    year_id: localStorage.getItem('PAY_USER_YEAR_ID'),
                    month: month,
                },
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        Authorization: 'Bearer ' + localStorage.getItem('PAY_USER_TOKEN'),
                    },
                }
            );

            if (salaryResponse.data.success === true) {
                const reportResponse = await axios.post(
                    `${PAY_URL}/user/employee-report`,
                    {
                        firm_id: localStorage.getItem('PAY_USER_FIRM_ID'),
                        year_id: localStorage.getItem('PAY_USER_YEAR_ID'),
                        month: month,
                        employee: JSON.stringify(salaryResponse?.data?.data),
                    },
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            Authorization: 'Bearer ' + localStorage.getItem('PAY_USER_TOKEN'),
                        },
                    }
                );

                clearInterval(timerInterval);

                if (reportResponse.data.success) {
                    // console.log(reportResponse.data.data, 'FINAL DATA');
                    const finalData = reportResponse.data.data;
                    console.log(finalData, '@@')
                    // navigate('/user/view-report', { state: finalData })
                    navigate('/user/report-list')
                    toast.success(reportResponse.data.message);
                } else {
                    toast.error(reportResponse.data.message);
                }
            } else {
                toast.error(salaryResponse.data.message);
            }
            setDisable2(false);
        } catch (error) {
            console.error(error);
            toast.error(error?.response?.data?.message || 'Something went wrong');
            setDisable2(false);
        }
    }

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const handleOpen = () => {
        setIsSidebarOpen(!isSidebarOpen)
    }


    return (
        <div className={`main-wrapper ${isSidebarOpen ? 'slide-nav' : ''}`}>
            <Header handleOpen={handleOpen} />
            <Sidebar />

            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <h3 className="page-title user-title">Report Management</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/user/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Report Management</li>
                                </ul>
                            </div>
                            <div className="col-auto float-end ms-auto">
                                {/* <Link to='/user/manage-salary' className="btn btn-success"><i className="fa-solid fa-plus"></i> Add Salary</Link> */}
                            </div>
                        </div>
                    </div>

                    <div className="row filter-row">


                    </div>
                    <div className='row align-items-center d-flex justify-content-end'>
                        <div className='col-md-4 col-lg-4 col-sm-12'>
                            <div className="row">
                                <div className="col-md-12 mb-2">
                                    <select className="form-select form-control" value={month} onChange={handleMonthChange}
                                        aria-label="Default select example">
                                        <option value=''>Select Month</option>
                                        <option value='1'>January</option>
                                        <option value='2'>February</option>
                                        <option value='3'>March</option>
                                        <option value='4'>April</option>
                                        <option value='5'>May</option>
                                        <option value='6'>June</option>
                                        <option value='7'>July</option>
                                        <option value='8'>August</option>
                                        <option value='9'>September</option>
                                        <option value='10'>October</option>
                                        <option value='11'>November</option>
                                        <option value='12'>December</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-lg-8 col-md-8 justify-content-end">
                            <div className="row justify-content-end">
                                <div className="col-md-12 mb-2">
                                    <div className="justify-content-end d-flex align-center flex-wrap gap-2">
                                        <div className="seletePage d-flex gap-2 align-items-center ">
                                            <p className="mb-0">Rows per page:</p>
                                            <div className="d-flex align-items-center justify-content-center gap-2">
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        variant="none"
                                                        id="dropdown-basic"
                                                        style={{
                                                            cursor: "auto",
                                                            backgroundColor: "white",
                                                            borderColor: "#d5dbe0",
                                                            paddingBottom: "3px",
                                                            paddingTop: "3px",
                                                        }}
                                                        className='form-control selectWidth'
                                                    >
                                                        {limit}&nbsp;
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {limit !== 10 ? (
                                                            <>
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setlimit(10);
                                                                    }}
                                                                >
                                                                    10
                                                                </Dropdown.Item>
                                                            </>
                                                        ) : null}

                                                        {limit !== 20 ? (
                                                            <>
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setlimit(20);
                                                                    }}
                                                                >
                                                                    20
                                                                </Dropdown.Item>
                                                            </>
                                                        ) : null}

                                                        {limit !== 30 ? (
                                                            <>
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setlimit(30);
                                                                    }}
                                                                >
                                                                    30
                                                                </Dropdown.Item>
                                                            </>
                                                        ) : null}

                                                        {limit !== 50 ? (
                                                            <>
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setlimit(50);
                                                                    }}
                                                                >
                                                                    50
                                                                </Dropdown.Item>
                                                            </>
                                                        ) : null}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <div className="refreshPage">
                                                    <button className="btn btn-refresh" type='button' onClick={() => setOpenFilter(!openFilter)}
                                                        aria-controls="example-collapse-text"
                                                        aria-expanded={openFilter}>
                                                        <i className="fa-solid fa-filter"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="seletePage d-flex gap-2 align-items-center ">
                                            <div className="d-flex align-items-center justify-content-center gap-2">
                                                <div className="refreshPage">
                                                    <button className="btn btn-refresh" type='button' onClick={handleRefresh}>
                                                        <i className="fa-solid fa-arrows-rotate"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tableSearch">
                                            <form action="">
                                                <Search
                                                    onSearch={(value) => {
                                                        setSearch(value);
                                                        setCurrentPage(1);
                                                    }} />
                                                <span className="search">
                                                    <i className="fa-solid fa-magnifying-glass"></i>
                                                </span>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Collapse in={openFilter}>
                        <div className='row filter-row mt-2' id="example-collapse-text">
                            <div className='col-md-3 col-lg-3 col-sm-12'>
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        {deapartmentData && deapartmentData.length > 0 ? (
                                            <>
                                                <label htmlFor="othour" className="form-label">Department</label>

                                                <MultiSelect
                                                    options={deapartmentData.map(e => ({ label: e.name, value: e._id }))}
                                                    value={selectedDepartment}
                                                    onChange={handleDepartment}
                                                    labelledBy="Select Department"
                                                />
                                            </>
                                        ) : (
                                            <p>Loading departments...</p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-3 col-lg-3 col-sm-12'>
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        {designationData && designationData?.length > 0 ? (
                                            <>
                                                <label htmlFor="othour" className="form-label">Designation</label>
                                                <MultiSelect
                                                    options={designationData?.map(e => ({ label: e.name, value: e._id }))}
                                                    value={selectedDesignation}
                                                    onChange={handleDesignation}
                                                    labelledBy="Select Designation"
                                                />
                                            </>
                                        ) : (
                                            <p>Loading designation...</p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-3 col-lg-3 col-sm-12'>
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        {bankData && bankData?.length > 0 ? (
                                            <>
                                                <label htmlFor="othour" className="form-label">Bank</label>
                                                <MultiSelect
                                                    options={bankData?.map(e => ({ label: e.name, value: e._id }))}
                                                    value={selectedBank}
                                                    onChange={handleBank}
                                                    labelledBy="Select Bank"
                                                />
                                            </>
                                        ) : (
                                            <p>Loading bank...</p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className='col-sm-12 col-lg-3 col-md-3'>
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        <label htmlFor="othour" className="form-label">Stop Salary</label>
                                        <select className="form-select form-control" value={isStop} onChange={(e) => setIsStop(e.target.value)}
                                            aria-label="Default select example">
                                            <option value=''>Both</option>
                                            <option value='1'>Yes </option>
                                            <option value='0'>No</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Collapse>

                    <div className='row align-items-center d-flex mb-2 justify-content-start'>
                        <div className='col-sm-12 col-lg-3 col-md-3 justify-content-start'>
                            {selectedEmployee?.length > 0 ? (
                                <button type="button" className="btn btn-success w-100" onClick={handleReport} disabled={disable2}>
                                    {disable2 ? `Processing... (${elapsedTime.minutes}m ${elapsedTime.seconds}s)` : `Generate Report [${selectedEmployee?.length}]`}</button>
                            ) : null}
                        </div>
                    </div>

                    {disable === false ? (
                        <div className="card p-3 ">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="table-responsive">
                                        <table className="table table-striped custom-table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <div className='d-flex justify-content-start align-items-center gap-3'>
                                                            {commentsData?.length > 0 ? (
                                                                <input className="form-check-input mt-0" type="checkbox" checked={selectAll}
                                                                    id="flexCheckDefault"
                                                                    onChange={handleCheckAllEmpployee}
                                                                    style={{ width: "20px", height: "20px" }}
                                                                />
                                                            ) : null}
                                                            Sr.
                                                        </div>
                                                    </th>
                                                    <th>Employee</th>
                                                    <th>Id</th>
                                                    <th>Card Id</th>
                                                    <th>Aadhar Card</th>
                                                    <th>Department</th>
                                                    <th>Designation</th>
                                                    {/* <th>Email</th>
                                                    <th>Mobile</th> */}
                                                    <th>Bank</th>
                                                    <th>Stop Salary</th>
                                                    {/* <th className="text-end">Action</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {commentsData?.map((elem, i) =>
                                                    <tr key={elem?._id}>
                                                        {/* <td>{(currentPage - 1) * limit + i + 1}</td> */}
                                                        <td>
                                                            <div className='d-flex gap-3 justify-content-start align-items-center'>
                                                                <input className="form-check-input mt-0" type="checkbox"
                                                                    id="flexCheckDefault"
                                                                    checked={selectedEmployee.some(emp => emp.id === elem?._id)}
                                                                    onChange={(e) => handleCheckEmployee(e, elem?._id)}
                                                                    style={{ width: "20px", height: "20px" }}
                                                                />
                                                                {(currentPage - 1) * limit + i + 1}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {elem?.salary_id === null ? (
                                                                <span className="badge text-bg-warning" style={{ backgroundColor: "#fcf2da", fontSize: "12px", padding: "5px 8px" }}>{elem?.full_name}</span>
                                                            ) : (
                                                                elem?.salary_id?.is_stop_salary === true ? (
                                                                    <span className='badge text-bg-danger' style={{ fontSize: "12px", padding: "5px 8px" }}>{elem?.full_name}</span>
                                                                ) : (
                                                                    <span className="badge text-bg-user" style={{ fontSize: "12px", padding: "5px 8px" }}>{elem?.full_name}</span>
                                                                )
                                                            )}
                                                        </td>
                                                        <td>{elem?.employee_id}</td>
                                                        <td>{elem?.card_no ? elem?.card_no : '-'}</td>
                                                        <td>{elem?.adhar_no}</td>
                                                        <td>{elem?.salary_id?.department?.name || '-'}</td>
                                                        <td>{elem?.designation?.name}</td>
                                                        {/* <td>{elem?.email}</td> */}
                                                        {/* <td>{elem?.mobile_number}</td> */}
                                                        <td>{elem?.salary_id?.bank_name?.name || '-'}</td>
                                                        <td>
                                                            {elem.is_stop_salary === true ? (
                                                                <span className="badge text-bg-user">True</span>
                                                            ) : (
                                                                <span className="badge text-bg-danger">False</span>
                                                            )}
                                                        </td>
                                                        {/* <td className='text-center'>
                                                            <span className='icon-wrapper' data-tooltip="Generate And View Report" onClick={() => handleReport(elem?._id)} style={{ cursor: "pointer" }}>
                                                                <i className="fa-solid fa-file"></i>
                                                            </span>
                                                        </td> */}
                                                    </tr>
                                                )}

                                                {commentsData?.length === 0 ? (
                                                    <tr>
                                                        <td colspan="999">
                                                            <div className="no-table-data">
                                                                No Data Found!
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ) : null}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row align-center mt-3 user-account">
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-6">
                                            <div className="dataTables_info" id="DataTables_Table_0_info" role="status"
                                                aria-live="polite">Showing {Math.min(limit, totalItems)} from {totalItems} data</div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-6 ">
                                            <div className="dataTables_paginate paging_simple_numbers"
                                                id="DataTables_Table_0_paginate">
                                                <Pagination
                                                    total={totalItems}
                                                    itemsPerPage={limit}
                                                    currentPage={currentPage}
                                                    onPageChange={(page) => setCurrentPage(page)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : <Loader />}

                    <div className='card p-3'>
                        <h6><b>Note:</b></h6>
                        <ul style={{ fontSize: "12px" }}>
                            <li className='mt-1 d-flex'><div style={{ backgroundColor: "#3e42be", height: "15px", width: "15px", borderRadius: "5px", marginRight: "5px" }}></div>With Salary Employee Data</li>
                            <li className='mt-1 d-flex'><div style={{ backgroundColor: "#ff3131", height: "15px", width: "15px", borderRadius: "5px", marginRight: "5px" }}></div>Stop Salary Employee Data</li>
                            {/* <li className='mt-1 d-flex'><div style={{ backgroundColor: "#dfa900", height: "15px", width: "15px", borderRadius: "5px", marginRight: "5px" }}></div> Without Salary Employee Data</li> */}
                        </ul>
                    </div>

                    <CopyRight />
                </div>
            </div>
        </div >
    )
}

export default Report