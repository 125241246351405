import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Header from '../../Pages/Header'
import Sidebar from '../../Pages/Sidebar'
import { addUserGroup } from '../../../../Store/User/Group/AddGroup'
import CopyRight from '../../Pages/CopyRight'


const ManageGroup = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const data = location.state;
    // console.log(data, '@@')

    useEffect(() => {
        if (location.state) {
            setGroup(location.state?.name);
            setSelectValue(location.state?.status);
        }
    }, [location.state]);

    useEffect(() => {
        if (localStorage.getItem('PAY_USER_TOKEN') === null) {
            navigate("/user/login");
        }
    }, [navigate]);

    const [group, setGroup] = useState('');
    const [disable, setDisable] = useState(false);
    const [error, setError] = useState('');
    const [selectValue, setSelectValue] = useState('');


    const handleRadioChange = (event) => {
        setSelectValue(event.target.checked);
    };

    const handleSubmit = () => {
        if (validation()) {
            setDisable(true)
            const formData = new URLSearchParams();

            formData.append('name', group);
            if (data?._id) {
                formData.append('id', data?._id);
                formData.append('status', selectValue);
            }

            dispatch(addUserGroup(formData))
                .then((res) => {
                    // console.log(res, 'REs');

                    if (res.payload.success === true) {
                        navigate('/user/group-management');
                        setGroup('');
                    }
                    setDisable(false)
                }).catch((error) => {
                    console.log(error, 'Error')
                    setDisable(false)
                })
        }
    }

    const validation = () => {
        var isValid = true;
        let err = {};

        if (!group || !group?.trim()) {
            isValid = false;
            err['group_err'] = "Please enter group"
        }

        setError(err);
        return isValid;

    }

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const handleOpen = () => {
        setIsSidebarOpen(!isSidebarOpen)
    }

    return (
        <>
            <div className={`main-wrapper ${isSidebarOpen ? 'slide-nav' : ''}`}>
                <Header handleOpen={handleOpen} />
                <Sidebar />
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <div className="page-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h3 className="page-title user-title">{data?._id ? 'Edit' : 'Add'} Group</h3>
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/user/dashboard">Dashboard</Link></li>
                                        <li className="breadcrumb-item"><Link to="/user/group-management">Group Management</Link></li>
                                        <li className="breadcrumb-item active">{data?._id ? 'Edit' : 'Add'} Group</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="card p-3 basicform">
                            {/* <div className=" mb-3 titleForm border-bottom">
                                <h4>Bank</h4>
                            </div> */}

                            <div className="row card_id d-flex justify-content-start align-center">
                                <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                    <div className="cardNum">
                                        <div className="mb-3">
                                            <label htmlFor="Name" className="form-label">Name</label>
                                            <input type="text" className="form-control" id="Name"
                                                onChange={(e) => setGroup(e.target.value)} value={group}
                                            />
                                            <div className='error'>{error.group_err}</div>
                                        </div>
                                    </div>
                                </div>
                                {data?._id ? (
                                    <div className='col-md-4 col-lg-4 col-xxl-4 col-sm-12'>
                                        <div className="cardNum">
                                            <div className="mb-3">
                                                <label htmlFor="fileUpload" className="form-label">Status</label>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" role="switch" onChange={handleRadioChange} checked={selectValue} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="row card_id mt-4 sm-mt-3">
                                <div className=" col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                    <div className="cardNum">
                                        <div className="mb-3 d-flex gap-2">
                                            <button className="btn btn-success w-50" type='button' onClick={handleSubmit} disabled={disable}>{disable ? "Processing..." : (data?._id ? 'Update' : 'Submit')}</button>
                                            <button className="btn btn-outline-secondary w-50" type='button' onClick={() => setGroup('')}>Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <CopyRight />
                </div>
            </div>
        </>
    )
}

export default ManageGroup