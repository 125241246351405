import React, { useState } from 'react'
import Header from '../Pages/Header'
import Sidebar from '../Pages/Sidebar'
import { Link, useLocation } from 'react-router-dom'
import moment from 'moment'

const ViewReport = () => {

    const location = useLocation();

    const data = location.state;
    // console.log(data, '#@@');

    const numberToWords = (num) => {
        const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
        const teens = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
        const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

        if (num === 0) return 'Zero';

        let words = '';

        function convertThreeDigitNumber(num) {
            let result = '';

            if (num >= 100) {
                result += units[Math.floor(num / 100)] + ' Hundred ';
                num %= 100;
            }

            if (num >= 20) {
                result += tens[Math.floor(num / 10)] + ' ';
                num %= 10;
            }

            if (num >= 10) {
                result += teens[num - 10] + ' ';
                num = 0;
            }

            if (num > 0) {
                result += units[num] + ' ';
            }

            return result.trim();
        }

        const suffixes = ['', 'Thousand', 'Million', 'Billion', 'Trillion', 'Quadrillion', 'Quintillion'];

        let groupIndex = 0;
        while (num > 0) {
            const group = num % 1000;
            if (group !== 0) {
                words = convertThreeDigitNumber(group) + ' ' + suffixes[groupIndex] + ' ' + words;
            }
            num = Math.floor(num / 1000);
            groupIndex++;
        }

        return words.trim();
    }

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const handleOpen = () => {
        setIsSidebarOpen(!isSidebarOpen)
    }

    return (
        <div className={`main-wrapper ${isSidebarOpen ? 'slide-nav' : ''}`}>
            <Header handleOpen={handleOpen} />
            <Sidebar />

            <div className="page-wrapper">
                <div className="content container-fluid report-page">
                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <h3 className="page-title user-title">View Report</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/user/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to='/user/report-management'>Report Management</Link></li>
                                    <li className="breadcrumb-item active">View Report</li>
                                </ul>
                            </div>
                            <div className="col-auto float-end ms-auto d-flex gap-2">
                                <span><img src='/assets/img/xlsx.svg' alt='xlsx' style={{ width: "100%", height: "40px" }} /></span>
                                <span><img src='/assets/img/pdf.svg' alt='pdf' style={{ width: "100%", height: "40px" }} /> </span>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className='text-center reportLogo'>
                                                <img src="/assets/img/colorLogo.svg" className="inv-logo " alt="Logo" />
                                            </div>
                                            <h4 className="payslip-title">Payslip for the month of Feb 2019</h4>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col-12 m-b-20">
                                            <div className="invoice-details">
                                                <h4 className="text-uppercase">Payslip #49029</h4>
                                                <ul className="list-unstyled">
                                                    <li>Salary Month: <span>March, 2019</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-md-6 col-sm-12 m-b-20">
                                            <h5 className='mb-1'>From,</h5>
                                            <ul className="list-unstyled mb-0">
                                                <li>
                                                    <h5 className="mb-0"><strong>{localStorage.getItem('PAY_USER_FIRM_NAME')}</strong></h5>
                                                </li>
                                                <li>3864 Quiet Valley Lane,</li>
                                                <li>Sherman Oaks, CA, 91403</li>
                                                <li>Contact: +91 9865325498</li>
                                            </ul>
                                        </div>
                                        <div className="col-md-6 col-sm-12 m-b-20 text-end">
                                            <h5 className='mb-1'>To,</h5>
                                            <ul className="list-unstyled">
                                                <li>
                                                    <h5 className="mb-0"><strong>{data?.name}</strong></h5>
                                                </li>
                                                <li><span>{data?.designation?.name}</span></li>
                                                <li>Employee ID: {data?.employee_id}</li>
                                                <li>Joining Date: {moment(data?.joining_date).format('YYYY-MM-DD')}</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className='row mb-4'>
                                        <div className=" mb-3 titleForm border-bottom">
                                            <h4>Salary Information</h4>
                                        </div>

                                        <div className='row'>
                                            <div className='col-md-3 col-lg-3 col-sm-12'>
                                                <ul className="list-unstyled">
                                                    <li>
                                                        <h5 className="mb-0"><strong>Total Working Days:</strong> {data?.working_day}</h5>
                                                    </li>
                                                    <li><span></span></li>
                                                </ul>
                                            </div>

                                            <div className='col-md-3 col-lg-3 col-sm-12'>
                                                <ul className="list-unstyled">
                                                    <li>
                                                        <h5 className="mb-0"><strong>Total Present Days:</strong> {data?.total_present_Day}</h5>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className='col-md-3 col-lg-3 col-sm-12'>
                                                <ul className="list-unstyled">
                                                    <li>
                                                        <h5 className="mb-0"><strong>Total OT Days:</strong> {data?.total_ot_Day}</h5>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className='col-md-3 col-lg-3 col-sm-12'>
                                                <ul className="list-unstyled">
                                                    <li>
                                                        <h5 className="mb-0"><strong>Working Hours:</strong> {data?.working_hour}</h5>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6 mb-2">
                                            <div>
                                                <h4>Earnings</h4>
                                                <div className="table-responsive">
                                                    <table className="table table-hover">
                                                        <tbody>
                                                            {/* <tr>
                                                            <td><strong>Basic Salary</strong> <span
                                                                className="float-end">₹{data?.basic_salary}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>House Rent Allowance (H.R.A.)</strong> <span
                                                                className="float-end">₹{data?.hra_salary}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Conveyance</strong> <span
                                                                className="float-end">₹{data?.conveyance_allowance_salary}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Medical Allowance</strong> <span
                                                                className="float-end">₹{data?.medical_allowance_salary}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Other Allowance</strong> <span
                                                                className="float-end">₹{data?.other_salary}</span></td>
                                                        </tr> */}
                                                            <tr>
                                                                <td><strong>Present Day Salary</strong> <span
                                                                    className="float-end">₹{data?.present_day_salary}</span></td>
                                                            </tr>

                                                            <tr>
                                                                <td><strong>OT</strong> <span
                                                                    className="float-end">₹{data?.ot_salary}</span></td>
                                                            </tr>

                                                            <tr>
                                                                <td><strong>Extra Earning</strong> <span
                                                                    className="float-end">₹{data?.total_extra_earning}</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Gross Salary</strong> <span
                                                                        className="float-end"><strong>₹{data?.gross_salary}</strong></span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mb-2">
                                            <div>
                                                <h4>Deductions</h4>
                                                <div className="table-responsive">
                                                    <table className="table table-hover">
                                                        <tbody>
                                                            <tr>
                                                                <td><strong>Tax Deducted at Source (T.D.S.)</strong> <span
                                                                    className="float-end">₹{data?.tds}</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Provident Fund</strong> <span
                                                                    className="float-end">₹{data?.pf}</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Professional Tax</strong> <span
                                                                    className="float-end">₹{data?.pt}</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>ESI</strong> <span className="float-end">₹{data?.esi}</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Loan</strong> <span className="float-end">₹{data?.loan_deduction}</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Other Deduction</strong> <span className="float-end">₹{data?.total_other_deduction}</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Total Deductions</strong> <span
                                                                    className="float-end"><strong>₹{data?.total_deduction}</strong></span></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className="col-sm-12">
                                            <p><strong>Net Salary: ₹{data?.net_salary}</strong> ({numberToWords(data?.net_salary)})</p>
                                        </div>
                                    </div>

                                    <div className='row mt-2'>
                                        <div className='col-12 d-flex justify-content-center'>
                                            <div className=''>
                                                <img src='/assets/img/sign.jpg' style={{ width: "100%", height: "80px" }} alt='sign-img' />
                                                <h4 className='mt-2'>Authorized Sign</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ViewReport