import React from 'react'

const CopyRight = () => {
    return (
        <div className='copyright_footer'>
            <div className='text-center'>
                <p className='p-0 m-0'>Copyright © Designed & Developed by <a href='https://www.addonwebtech.com/' target='_blank' rel="noreferrer" style={{ color: "#3e42be", fontWeight: "bold" }}>addonwebtech.com</a> 2024</p>
            </div>
        </div>
    )
}

export default CopyRight