import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
import toast from "react-hot-toast";
import { PAY_URL } from "../../../BaseURL";


// export const adminLogin = createAsyncThunk(
//     '/login/adminLogin',

//     async (login) => {

//         const myurl = `${PAY_URL}/admin/login`;
//         const bodyFormData = new URLSearchParams();

//         bodyFormData.append('email', login.email);
//         bodyFormData.append('password', login.password)


//             await axios({
//                 method: 'post',
//                 url: myurl,
//                 data: bodyFormData,
//                 headers: {
//                     'Content-Type': 'application/x-www-form-urlencoded',
//                 },
//             }).then((response) => {
//                 const loginData = response.data;
//                 if (loginData.success === true) {
//                     localStorage.setItem('PAY_ADMIN_TOKEN', loginData?.data?.token);
//                     localStorage.setItem('PAY_ADMIN_NAME', loginData?.data?.name);
//                     localStorage.setItem('PAY_ADMIN_IMG', loginData?.data?.image);
//                     toast.success(loginData?.message)
//                     return loginData;
//                 } else {
//                     throw new Error(loginData?.message);
//                 }
//             }).catch((error) => {
//                 console.log(error, 'Error')
//                 toast.error(error.response.data.message);
//                 return error
//             })
//     }
// )

export const adminForgetPassword = createAsyncThunk(
    '/login/adminForgetPassword',
    async (forget, { rejectWithValue }) => {
        try {
            const myurl = `${PAY_URL}/admin/forgot-password`;
            const bodyFormData = new URLSearchParams();

            bodyFormData.append('email', forget || localStorage.getItem('A_FORGET_EMAIL'));

            const response = await axios({
                method: 'post',
                url: myurl,
                data: bodyFormData,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })

            const forgetData = response.data;

            if (forgetData.success === true) {
                toast.success(forgetData.message)
                // console.log(forgetData)
                return forgetData;
            } else {
                throw new Error(forgetData.message);
            }
        } catch (error) {
            console.log(error, 'Error');
            toast.error(error.response.data.message);
            return rejectWithValue(error.response.data.message);
        }
    }
);


const adminForgetPasswordSlice = createSlice({
    name: "adminForgetPassword",
    initialState: {
        user: null,
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(adminForgetPassword.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(adminForgetPassword.fulfilled, (state, action) => {
                state.user = action.payload;
                state.loading = false;
                state.error = null;
            })
            .addCase(adminForgetPassword.rejected, (state, action) => {
                state.user = null;
                state.loading = false;
                state.error = action.error.message;
            });
    }
})

export default adminForgetPasswordSlice.reducer;