import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetUserPassword } from '../../../Store/User/Login/ResetPassword';

const ResetPassword = () => {

    const [details, setDetails] = useState({ password: '', confirm_password: '', pwdShow: true, cpwdShow: true });
    const [error, setError] = useState({});
    const nav = useNavigate();
    const dispatch = useDispatch();
    const [disable, setDisable] = useState(false);

    const handleChange = (e) => {
        setDetails({ ...details, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validation()) {
            setDisable(true);

            dispatch(resetUserPassword({ password: details.password }))
                .then((response) => {
                    // console.log(response, "RES");
                    if (response?.payload?.success === true) {
                        nav('/user/login')
                        setDetails({ password: '', confirm_password: '' });
                        localStorage.removeItem('U_FORGET_EMAIL');
                    }
                    setDisable(false);
                }).catch((error) => {
                    console.log(error, "!!!")
                    setDisable(false);
                })
        }
    }


    const validation = () => {
        var isValid = true;
        let err = {};

        if (!details.password) {
            isValid = false;
            err['password_err'] = "Please enter password";
        } else {
            const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
            if (!passwordRegex.test(details.password)) {
                isValid = false;
                err['password_err'] = "Password must have at least 8 characters, including one capital letter, one number, and one special character (@$!%*?&)";
            }
        }

        if (!details.confirm_password) {
            isValid = false;
            err['cpassword_err'] = "Please enter confirm password"
        }

        if (details.password && details.confirm_password) {
            if (details.password !== details.confirm_password) {
                isValid = false;
                err['cpassword_err'] = "Password doesn't match";
            }
        }

        setError(err);
        return isValid;
    }

    return (
        <div className='account-page login-page'>
            <div className="frame-login"></div>
            <div className="container">
                <div className="row login-Row">
                    <div className="col-md-6 col-lg-5 col-sm-12">
                        <div className="card p-3 cardTransparent">
                            <div className="logoLogin d-flex align-center justify-content-center mb-3">
                                <img src="/assets/img/ColorLogo.svg" width="150px" height="auto" alt="Logo" />
                            </div>
                            <h3 className="account-title mt-4 mb-4 text-center">Reset your Password</h3>
                            <form className="row g-3" onSubmit={handleSubmit}>
                                <div className="col-md-12 position-relative">
                                    <label for="Newpassword" className="form-label">New Password</label>
                                    <input className="form-control position-relative" id="Newpassword"
                                        type={details.pwdShow ? "password" : "text"}
                                        onChange={handleChange} value={details.password} name='password' />
                                    {details.pwdShow ? <div className="icon-Show" onClick={() => { setDetails({ ...details, pwdShow: false }) }}><i className='fa-solid fa-eye' /></div> : <div className='icon-Show' onClick={() => setDetails({ ...details, pwdShow: true })}><i className='fa-solid fa-eye-slash' /> </div>}
                                </div>

                                <div className="col-md-12 position-relative">
                                    <label for="Conformpassword" className="form-label">Confirm Password</label>
                                    <input className="form-control position-relative" id="Conformpassword"
                                        type={details.cpwdShow ? "password" : "text"}
                                        onChange={handleChange} value={details.confirm_password} name='confirm_password'
                                    />
                                    {details.cpwdShow ? <div className="icon-Show" onClick={() => { setDetails({ ...details, cpwdShow: false }) }}><i className='fa-solid fa-eye' /></div> : <div className='icon-Show' onClick={() => setDetails({ ...details, cpwdShow: true })}><i className='fa-solid fa-eye-slash' /> </div>}
                                    <div className='error'>{error.cpassword_err}</div>
                                </div>

                                <div className="input-block mt-4 text-center">
                                    <button className="btn btn-success account-btn w-100" type="submit" disabled={disable}>{disable ? 'Processing...' : 'Submit'}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword