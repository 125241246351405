import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import { PAY_URL } from "../../../BaseURL";

export const addUserDesignation = createAsyncThunk(
    '/add/addUserDesignation',
    async (add) => {

        try {
            const myurl = `${PAY_URL}/user/manage-designation`;

            const response = await axios({
                method: 'post',
                url: myurl,
                data: add,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: "Bearer " + localStorage.getItem('PAY_USER_TOKEN')
                },
            });

            const addUserDesignation = response.data;
            // console.log(addUserDesignation, '@@@')

            if (addUserDesignation.success === true) {
                toast.success(addUserDesignation?.message);
                return addUserDesignation;
            } else {
                // console.log(addUserDesignation.message, "&&&&")
                throw new Error(addUserDesignation.message);
            }
        } catch (error) {
            console.log(error, "!!!!")
            toast.error(error.response.data.message);
            return error;
        }
    }
);


const addUserDesignationSlice = createSlice({
    name: "addUserDesignation",
    initialState: {
        user: null,
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addUserDesignation.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addUserDesignation.fulfilled, (state, action) => {
                state.user = action.payload;
                state.loading = false;
                state.error = null;
            })
            .addCase(addUserDesignation.rejected, (state, action) => {
                state.user = null;
                state.loading = false;
                state.error = action.error.message;
            });
    }
})

export default addUserDesignationSlice.reducer;