import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import { PAY_URL } from "../../../BaseURL";

export const addUserGroup = createAsyncThunk(
    '/add/addUserGroup',
    async (add) => {

        try {
            const myurl = `${PAY_URL}/user/manage-group`;

            const response = await axios({
                method: 'post',
                url: myurl,
                data: add,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: "Bearer " + localStorage.getItem('PAY_USER_TOKEN')
                },
            });

            const addUserGroup = response.data;
            // console.log(addUserGroup, '@@@')

            if (addUserGroup.success === true) {
                toast.success(addUserGroup?.message);
                return addUserGroup;
            } else {
                // console.log(addUserGroup.message, "&&&&")
                throw new Error(addUserGroup.message);
            }
        } catch (error) {
            console.log(error, "!!!!")
            toast.error(error.response.data.message);
            return error;
        }
    }
);


const addUserGroupSlice = createSlice({
    name: "addUserGroup",
    initialState: {
        user: null,
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addUserGroup.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addUserGroup.fulfilled, (state, action) => {
                state.user = action.payload;
                state.loading = false;
                state.error = null;
            })
            .addCase(addUserGroup.rejected, (state, action) => {
                state.user = null;
                state.loading = false;
                state.error = action.error.message;
            });
    }
})

export default addUserGroupSlice.reducer;