import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Header from '../Pages/Header';
import Sidebar from '../Pages/Sidebar';
import City from '../../../city.json';
import axios from 'axios';
import { PAY_URL } from '../../../BaseURL';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDesignation } from '../../../Store/User/Designation/getUserDesignation';
import { getUserShift } from '../../../Store/User/Shift/getUserShift';
import moment from 'moment';
import { getUserSkill } from '../../../Store/User/Skill/getSkill';
import { getUserEmployeeType } from '../../../Store/User/EmployeeType/getEmployeeType';
import CopyRight from '../Pages/CopyRight';

const ManageEmployee = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [disable1, setDisable1] = useState(true);
  const [disable2, setDisable2] = useState(true);
  const [disable3, setDisable3] = useState(true);
  const [disable4, setDisable4] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('PAY_USER_TOKEN') === null) {
      navigate("/user/login");
    }
  }, [navigate]);

  const [error, setError] = useState({});
  const [disable, setDisable] = useState(false);
  const [disableMore, setDisableMore] = useState(false);
  const [selectValue, setSelectValue] = useState('');
  const [black_list, setBlack_list] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isCollapsedContact, setIsCollapsedContact] = useState(false);
  const [disableMulti, setDisableMulti] = useState(false)
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedPrevState, setSelectedPrevState] = useState('');
  const [selectedPrevCity, setSelectedPrevCity] = useState('');
  const [multiImg, setMultiImg] = useState([]);

  const fullNameRef = useRef(null);
  const joinDateRef = useRef(null);
  const designationRef = useRef(null);
  const emailRef = useRef(null);
  const genderRef = useRef(null);
  const dobRef = useRef(null);
  const mobileRef = useRef(null);
  const adharNoRef = useRef(null);
  const inTimeRef = useRef(null);
  const outTimeRef = useRef(null);
  const inTimeTwoRef = useRef(null);
  const outTimeTwoRef = useRef(null);
  const holidayRef = useRef(null);
  const addressRef = useRef(null);
  const stateRef = useRef(null);
  const cityRef = useRef(null);
  const pincodeRef = useRef(null);

  const data = location.state;
  // console.log(data, '@@')

  useEffect(() => {
    if (location.state) {
      setEmployee({
        first_name: location.state?.first_name,
        middle_name: location.state?.middle_name,
        last_name: location.state?.last_name,
        full_name: location.state?.full_name,

        card_no: location.state?.card_no,
        gender: location.state?.gender,
        image: location.state?.image,
        mobile_number: location.state?.mobile_number,
        dob: moment(location.state?.dob).format('YYYY-MM-DD'),
        email: location.state?.email,
        adhar_no: location.state?.adhar_no,
        pancard_no: location.state?.pancard_no,
        designation: location.state?.designation?._id,
        address: location.state?.address,
        address_two: location.state?.address_two,
        address_three: location.state?.address_three,
        pincode: location.state?.pincode,
        shift: location.state?.shift?._id,
        in_time: location.state?.in_time,
        out_time: location.state?.out_time,
        shift_two: location.state?.shift_two?._id,
        in_time_two: location.state?.in_time_two,
        out_time_two: location.state?.out_time_two,
        joining_date: moment(location.state?.joining_date).format('YYYY-MM-DD'),
        leaving_date: location.state?.leaving_date,
        leaving_reason: location.state?.leaving_reason,
        uan_no: location.state?.uan_no,
        holiday: location.state?.holiday,
        black_list_reason: location.state?.black_list_reason,
        emergency_contact_number: location.state?.emergency_contact_number,
        emergency_contact_person: location.state?.emergency_contact_person,
        emergency_person_relation: location.state?.emergency_person_relation,
        emergency_person_dob: location.state.emergency_person_dob,
        emergency_person_aadhar_number: location.state.emergency_person_aadhar_number,
        emergency_person_aadhar_photo: location.state.emergency_person_aadhar_photo,
        pre_address: location.state?.pre_address,
        pre_address_three: location.state?.pre_address_two,
        pre_address_two: location.state?.pre_address_two,
        pre_pincode: location.state?.pre_pincode,
        aadhar_card_image: location.state?.aadhar_card_image,
        pan_card_image: location.state?.pan_card_image,
        height: location.state.height,
        weight: location.state.weight,
        skill: location.state.skills?._id,
        type: location.state.emp_type?._id,
        esi_number: location.state.esi_number,
        passport: location.state.passport,
        education: location.state.education,
        nationality: location.state.nationality,
        caste: location.state.caste,
        blood_group: location.state.blood_group,
        esic_ip: location.state?.esic_ip,
      });

      setSelectedState(location.state?.state);
      setSelectedCity(location.state?.city);
      setSelectedPrevState(location.state?.pre_state);
      setSelectedPrevCity(location.state?.pre_city);
      setIsCollapsed(location.state?.same_address);
      setIsCollapsedContact(location.state?.is_emergency);

      setBlack_list(location.state?.black_list);
      setSelectValue(location.state?.status);
      setMultiImg(location.state.other_document_imgs);
    }

  }, [location.state]);

  useEffect(() => {
    const fetchData = async (fetchFunction, disableFlag, setDisable) => {
      if (disableFlag) {
        try {
          await dispatch(fetchFunction());
        } catch (error) {
          console.log(error, '!!');
        } finally {
          setDisable(false);
        }
      }
    };

    fetchData(getUserDesignation, disable1, setDisable1);
    fetchData(getUserShift, disable2, setDisable2);
    fetchData(getUserSkill, disable3, setDisable3);
    fetchData(getUserEmployeeType, disable4, setDisable4);

  }, [dispatch, disable1, disable2, disable3, disable4]);

  const designationData = useSelector((state) => state?.getUserDesignation?.user?.data);
  const shiftData = useSelector((state) => state?.getUserShift?.user?.data);
  const skillData = useSelector((state) => state?.getUserSkill?.user?.data);
  const employeeType = useSelector((state) => state?.getUserEmployeeType?.user?.data);

  const [age, setAge] = useState('');
  const [employee, setEmployee] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    full_name: "",
    card_no: "0",
    gender: "",
    image: "",
    mobile_number: "",
    dob: "",
    email: "",
    adhar_no: "",
    pancard_no: "",
    designation: "",
    address: "",
    address_two: "",
    address_three: "",
    pincode: "",
    in_time: "",
    out_time: "",
    in_time_two: "",
    out_time_two: "",
    joining_date: "",
    leaving_date: "",
    leaving_reason: "",
    uan_no: "",
    holiday: "",
    black_list_reason: "",

    emergency_contact_number: "",
    emergency_contact_person: "",
    emergency_person_relation: "",
    emergency_person_dob: "",
    emergency_person_aadhar_number: "",
    emergency_person_aadhar_photo: "",
    pre_address: "",
    pre_address_three: "",
    pre_address_two: "",
    pre_pincode: "",
    pan_card_image: "",
    aadhar_card_image: "",
    height: "",
    weight: "",
    skill: "",
    type: "",
    esi_number: "",
    passport: "",
    education: "",
    nationality: "",
    caste: "",
    blood_group: "",
    esic_ip: "",
  });

  const handleStateChange = (e) => {
    const selectedState = e.target.value;
    setSelectedState(selectedState);
    setSelectedCity('');
  };
  const handleCityChange = (e) => {
    const selectedCity = e.target.value;
    setSelectedCity(selectedCity);
  };

  const handlePrevStateChange = (e) => {
    const selectedState = e.target.value;
    setSelectedPrevState(selectedState);
    setSelectedPrevCity('');
  };

  const handlePrevCityChange = (e) => {
    const selectedCity = e.target.value;
    setSelectedPrevCity(selectedCity);
  };

  const defaultCountry = City.find((c) => c.iso2 === 'IN');

  const states = defaultCountry ? defaultCountry.states : [];
  const cities = states.find((s) => s.name === selectedState)?.cities || [];

  const prevState = defaultCountry ? defaultCountry.states : [];
  const prevCities = prevState.find((s) => s.name === selectedPrevState)?.cities || [];

  const handleCheckboxChange = () => {
    setIsCollapsed(!isCollapsed);
  };

  const emergencyChange = () => {
    setIsCollapsedContact(!isCollapsedContact);
  }

  const handleBlackList = (e) => {
    setBlack_list(e.target.checked);
  }

  const handleRadioChange = (event) => {
    setSelectValue(event.target.checked);
  };

  useEffect(() => {
    if (employee.dob) {
      const dob = new Date(employee.dob);
      const today = new Date();
      const currentYear = today.getFullYear();
      const dobYear = dob.getFullYear();
      const age = currentYear - dobYear;
      setAge(age);
    }
  }, [employee.dob]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedEmployee;

    if (name === 'full_name') {
      const names = value.split('');
      updatedEmployee = {
        ...employee,
        first_name: names[0] || '',
        last_name: names[names.length - 1] || '',
        full_name: value
      };
    } else {
      updatedEmployee = { ...employee, [name]: value };
    }

    if (name !== 'full_name') {
      const fullName = `${updatedEmployee.first_name} ${updatedEmployee.last_name}`.trim();
      updatedEmployee = { ...updatedEmployee, full_name: fullName };
    }

    setEmployee(updatedEmployee);
  }

  const handleAadharCardImage = (e) => {
    handleImage(e, 'aadhar_card_image');
  };

  const handlePanCardImage = (e) => {
    handleImage(e, 'pan_card_image');
  };

  const handleEmergencyAadhar = (e) => {
    handleImage(e, 'emergency_person_aadhar_photo')
  }

  const handleEmployeeImg = (e) => {
    handleImage(e, 'image');
  }

  const handleImage = (e, imageType) => {
    if (e?.target?.files[0]) {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg", "application/pdf"];
      const fileType = e.target.files[0].type;

      if (allowedTypes.includes(fileType)) {
        setDisable(true);
        setDisableMore(true);
        const myurl = `${PAY_URL}/upload-image`;
        var bodyFormData = new FormData();
        bodyFormData.append('image', e?.target?.files[0]);

        axios({
          method: "post",
          url: myurl,
          data: bodyFormData,
        }).then((result) => {
          console.log("=====", result);

          if (result?.data?.success === true) {
            setEmployee({ ...employee, [imageType]: result?.data?.data?.image });
            setDisable(false);
            setDisableMore(false);
          } else {
            console.log("Error:=====");
            setEmployee({ ...employee, [imageType]: "" });
          }
        }).catch((error) => {
          // console.log(error);
          setEmployee({ ...employee, [imageType]: "" });
          setDisable(false);
          setDisableMore(false);
          toast.error("Unable to upload image");
        });
      } else {
        setEmployee({ ...employee, [imageType]: "" });
        setDisable(false);
        toast.error("Invalid file type. Only JPEG, JPG, PNG and PDF are allowed.");
      }
    }
  };

  const handleRemoveImg = (imageURL) => {
    const updatedMultiImg = multiImg.filter(image => image.image !== imageURL);
    setMultiImg(updatedMultiImg);
  }

  const handleMultipleImg = (e) => {
    if (multiImg?.length > 0) {
      setDisableMulti(true)
      const files = Array.from(e.target.files);
      const myurl = `${PAY_URL}/upload-multiple-image`;
      const formData = new FormData();

      files.forEach((file, index) => {
        formData.append(`images`, file);
      });

      axios({
        method: "post",
        url: myurl,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        // console.log(response?.data, '==== IMg')
        // setMultiImg(response?.data?.data);
        setMultiImg(prevMultiImg => [...prevMultiImg, ...response?.data?.data]);
        setDisableMulti(false)

      }).catch((error) => {
        console.log(error);
        toast.error("Unable to upload image");
        setDisableMulti(false)
      });
    } else {
      toast.error('Please select image')
    }
  }

  const handleSubmit = (more) => {
    if (validation()) {
      setDisable(true);
      if (more === 'true') {
        setDisableMore(true);
      }
      const bodyFormData = new URLSearchParams();
      bodyFormData.append('first_name', employee?.first_name);
      bodyFormData.append('middle_name', employee?.middle_name);
      bodyFormData.append('last_name', employee?.last_name);
      bodyFormData.append('full_name', employee?.full_name);
      bodyFormData.append('card_no', employee?.card_no);
      bodyFormData.append('gender', employee?.gender);
      bodyFormData.append('image', employee?.image);
      bodyFormData.append('mobile_number', employee?.mobile_number);
      bodyFormData.append('dob', employee?.dob);
      bodyFormData.append('email', employee?.email);
      bodyFormData.append('adhar_no', employee?.adhar_no);
      bodyFormData.append('pancard_no', employee?.pancard_no);

      bodyFormData.append('designation', employee?.designation);

      bodyFormData.append('shift', shiftData[0]?._id);
      bodyFormData.append('in_time', employee?.in_time);
      bodyFormData.append('out_time', employee?.out_time);

      bodyFormData.append('shift_two', shiftData[1]?._id);
      bodyFormData.append('in_time_two', employee?.in_time_two);
      bodyFormData.append('out_time_two', employee?.out_time_two);

      bodyFormData.append('address', employee?.address);
      bodyFormData.append('address_two', employee?.address_two);
      bodyFormData.append('address_three', employee?.address_three);
      bodyFormData.append('state', selectedState);
      bodyFormData.append('city', selectedCity);
      bodyFormData.append('pincode', employee?.pincode);

      bodyFormData.append('joining_date', employee?.joining_date);
      bodyFormData.append('leaving_date', employee?.leaving_date);
      bodyFormData.append('leaving_reason', employee?.leaving_reason);

      bodyFormData.append('uan_no', employee?.uan_no);

      bodyFormData.append('holiday', employee?.holiday);

      bodyFormData.append('black_list', black_list);
      bodyFormData.append('black_list_reason', employee?.black_list_reason);

      bodyFormData.append('firm_id', localStorage.getItem('PAY_USER_FIRM_ID'));
      // bodyFormData.append('year_id', localStorage.getItem('PAY_USER_YEAR_ID'));

      bodyFormData.append('is_emergency', isCollapsedContact);

      bodyFormData.append('pan_card_image', employee?.pan_card_image);
      bodyFormData.append('aadhar_card_image', employee?.aadhar_card_image);

      bodyFormData.append('emergency_contact_number', employee?.emergency_contact_number);
      bodyFormData.append('emergency_contact_person', employee?.emergency_contact_person);
      bodyFormData.append('emergency_person_relation', employee?.emergency_person_relation);
      bodyFormData.append('emergency_person_dob', employee?.emergency_person_dob)
      bodyFormData.append('emergency_person_aadhar_number', employee?.emergency_person_aadhar_number)
      bodyFormData.append('emergency_person_aadhar_photo', employee?.emergency_person_aadhar_photo)

      bodyFormData.append('height', employee.height)
      bodyFormData.append('weight', employee.weight)
      bodyFormData.append('skills', employee.skill)
      bodyFormData.append('emp_type', employee.type)
      bodyFormData.append('esi_number', employee.esi_number)
      bodyFormData.append('passport_number', employee.passport)
      bodyFormData.append('nationality', employee.nationality)
      bodyFormData.append('caste', employee.caste)
      bodyFormData.append('blood_group', employee.blood_group)
      bodyFormData.append('education', employee.education)

      bodyFormData.append('same_address', isCollapsed);

      bodyFormData.append('other_document_imgs', JSON.stringify(multiImg));
      bodyFormData.append('esic_ip', employee.esic_ip);

      // console.log(multiImg, 'BODY')

      if (isCollapsed === true) {
        bodyFormData.append('pre_address', employee.address);
        bodyFormData.append('pre_address_two', employee?.address_two);
        bodyFormData.append('pre_address_three', employee?.address_three);
        bodyFormData.append('pre_state', selectedState);
        bodyFormData.append('pre_city', selectedCity);
        bodyFormData.append('pre_pincode', employee?.pincode);
      } else {
        bodyFormData.append('pre_address', employee?.pre_address);
        bodyFormData.append('pre_address_two', employee?.pre_address_two);
        bodyFormData.append('pre_address_three', employee?.pre_address_three);
        bodyFormData.append('pre_state', selectedPrevState);
        bodyFormData.append('pre_city', selectedPrevCity);
        bodyFormData.append('pre_pincode', employee?.pre_pincode);
      }

      if (data?._id) {
        bodyFormData.append('id', data?._id);
        bodyFormData?.append('status', selectValue);
      }

      axios({
        method: 'post',
        url: `${PAY_URL}/user/manage-employee`,
        data: bodyFormData,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: "Bearer " + localStorage.getItem('PAY_USER_TOKEN') }
      }).then((response) => {
        // console.log(response?.data);
        if (response.data.success === true) {
          if (more !== 'true') {
            navigate('/user/employee-management');
          }
          toast.success(response.data.message)
          handleReset();
          setAge('');
        } else {
          toast.error(response?.data?.message)
        }
        setDisable(false);
        if (more === 'true') {
          setDisableMore(false);
        }
      }).catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.message);
        setDisable(false);
        if (more === 'true') {
          setDisableMore(false);
        }
      })
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
    }
  }

  const validation = () => {
    let isValid = true;
    let err = {};
    //   if (!employee.aadhar_card_image) {
    //     isValid = false;
    //     err['aadhar_card_image_err'] = "Please select aadhaar card image"
    //   }
    if (!employee.full_name || !employee?.full_name?.trim()) {
      isValid = false;
      err['full_name_err'] = "Please enter full name";
      fullNameRef?.current?.scrollIntoView({ behavior: 'smooth' });
      fullNameRef?.current?.focus();
    }
    if (!employee?.joining_date) {
      isValid = false;
      err['join_date_err'] = 'Please enter joining date';
      joinDateRef?.current?.scrollIntoView({ behavior: 'smooth' });
      joinDateRef?.current?.focus();
    }
    if (!employee?.designation) {
      isValid = false;
      err['designation_err'] = 'Please select designation'
      designationRef?.current?.scrollIntoView({ behavior: 'smooth' });
      designationRef?.current?.focus();
    }
    if (!employee.gender) {
      isValid = false;
      err['gender_err'] = "Please select gender"
      genderRef?.current?.scrollIntoView({ behavior: 'smooth' });
      genderRef?.current?.focus();
    }
    if (!employee.dob) {
      isValid = false;
      err['dob_err'] = "Please enter dob";
      dobRef?.current?.scrollIntoView({ behavior: 'smooth' });
      dobRef?.current?.focus();
    }

    if (employee?.email) {
      emailRef?.current?.scrollIntoView({ behavior: 'smooth' });
      emailRef?.current?.focus();
      if (typeof employee.email !== "undefined") {
        let lastAtPos = employee.email.lastIndexOf('@');
        let lastDotPos = employee.email.lastIndexOf('.');
        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && employee.email.indexOf('@@') === -1 && lastDotPos > 2 && (employee.email.length - lastDotPos) > 2)) {
          isValid = false;
          err["email_err"] = "Email is not valid";
        }
      }
    }


    if (!employee.mobile_number) {
      isValid = false;
      err["mobile_err"] = "Please enter mobile";
      mobileRef?.current?.scrollIntoView({ behavior: 'smooth' });
      mobileRef?.current?.focus();
    } else if (!/^[6,7,8,9]{1}\d{9}$/.test(employee.mobile_number)) {
      isValid = false;
      err['mobile_err'] = 'Please enter a valid mobile';
      mobileRef?.current?.scrollIntoView({ behavior: 'smooth' });
      mobileRef?.current?.focus();
    }
    if (!employee.adhar_no) {
      isValid = false;
      err['adhar_no_err'] = "Please enter aadhaar card number"
      adharNoRef?.current?.scrollIntoView({ behavior: 'smooth' });
      adharNoRef?.current?.focus();
    } else if (!/^\d{12}$/.test(employee.adhar_no)) {
      isValid = false;
      err['adhar_no_err'] = "Please enter valid aadhar card number."
      adharNoRef?.current?.scrollIntoView({ behavior: 'smooth' });
      adharNoRef?.current?.focus();
    }

    if (!employee?.in_time) {
      isValid = false;
      err['in_time_err'] = 'Please enter in time';
      inTimeRef?.current?.scrollIntoView({ behavior: 'smooth' });
      inTimeRef?.current?.focus();
    }
    if (!employee?.out_time) {
      isValid = false;
      err['out_time_err'] = 'Please enter out time';
      outTimeRef?.current?.scrollIntoView({ behavior: 'smooth' });
      outTimeRef?.current?.focus();
    }
    if (!employee?.in_time_two) {
      isValid = false;
      err['in_time_two_err'] = 'Please enter in time';
      inTimeTwoRef?.current?.scrollIntoView({ behavior: 'smooth' });
      inTimeTwoRef?.current?.focus();
    }
    if (!employee?.out_time_two) {
      isValid = false;
      err['out_time_two_err'] = 'Please enter out time';
      outTimeTwoRef?.current?.scrollIntoView({ behavior: 'smooth' });
      outTimeTwoRef?.current?.focus();
    }
    if (!employee?.holiday) {
      isValid = false;
      err['holiday_err'] = 'Please select week off';
      holidayRef?.current?.scrollIntoView({ behavior: 'smooth' });
      holidayRef?.current?.focus();
    }

    if (!employee?.address) {
      isValid = false;
      err['address_err'] = 'Please enter address';
      addressRef?.current?.scrollIntoView({ behavior: 'smooth' });
      addressRef?.current?.focus();
    }
    if (!selectedState) {
      isValid = false;
      err['state_err'] = 'Please select state';
      stateRef?.current?.scrollIntoView({ behavior: 'smooth' });
      stateRef?.current?.focus();
    }
    if (!selectedCity) {
      isValid = false;
      err['city_err'] = 'Please select city';
      cityRef?.current?.scrollIntoView({ behavior: 'smooth' });
      cityRef?.current?.focus();
    }
    if (!employee?.pincode) {
      isValid = false;
      err['pincode_err'] = 'Please enter pincode';
      pincodeRef?.current?.scrollIntoView({ behavior: 'smooth' });
      pincodeRef?.current?.focus();
    }
    if (isCollapsed === false) {
      if (!employee?.pre_address) {
        isValid = false;
        err['pre_address_err'] = 'Please enter address';
      }
      if (!selectedPrevState) {
        isValid = false;
        err['pre_state_err'] = 'Please select state';
      }
      if (!selectedPrevCity) {
        isValid = false;
        err['pre_city_err'] = 'Please select city';
      }
      if (!employee?.pre_pincode) {
        isValid = false;
        err['pre_pincode_err'] = 'Please enter pincode';
      }
    }

    if (isCollapsedContact === true) {
      if (!employee?.emergency_contact_number) {
        isValid = false;
        err['emergency_mobile_err'] = 'Please enter mobile';
      } else if (!/^[6,7,8,9]{1}\d{9}$/.test(employee.emergency_contact_number)) {
        isValid = false;
        err['emergency_mobile_err'] = 'Please enter a valid mobile';
      }
      if (!employee?.emergency_contact_person) {
        isValid = false;
        err['emergency_person_err'] = 'Please enter name';
      }

      if (!employee?.emergency_person_relation) {
        isValid = false;
        err['emergency_relation_err'] = 'Please enter relation';
      }

      if (!employee?.emergency_person_dob) {
        isValid = false;
        err['emergency_person_dob_err'] = 'Please enter dob';
      }

      if (!employee.emergency_person_aadhar_number) {
        isValid = false;
        err['emergency_person_aadhar_number_err'] = "Please enter aadhaar card number"
      } else if (!/^\d{12}$/.test(employee.emergency_person_aadhar_number)) {
        isValid = false;
        err['emergency_person_aadhar_number_err'] = "Please enter valid aadhar card number."
      }

      if (!employee.emergency_person_aadhar_photo) {
        isValid = false;
        err['emergency_person_aadhar_photo_err'] = "Please select aadhar card image"
      }

    } else if (isCollapsedContact === false) {
      if (employee?.emergency_contact_number || employee.emergency_contact_person || employee.emergency_person_relation || employee.emergency_person_dob || employee.emergency_person_aadhar_number || employee.emergency_person_aadhar_photo) {
        isValid = false;
        toast.error('Please remove emergency data before unchecking emergency contact');
      }
    }

    setError(err);
    return isValid
  }

  const handleReset = () => {
    setEmployee({
      first_name: "",
      middle_name: "",
      last_name: "",
      full_name: "",
      card_no: "",
      gender: "",
      image: "",
      mobile_number: "",
      dob: "",
      email: "",
      adhar_no: "",
      pancard_no: "",
      designation: "",
      address: "",
      address_two: "",
      address_three: "",
      pincode: "",
      shift: "",
      joining_date: "",
      leaving_date: "",
      leaving_reason: "",
      in_time: "",
      out_time: "",
      uan_no: "",
      holiday: "",
      black_list_reason: "",
      emergency_contact_number: "",
      emergency_contact_person: "",
      emergency_person_relation: "",
      pre_address: "",
      pre_address_three: "",
      pre_address_two: "",
      pre_pincode: "",
      esic_ip: "",
    });
    setSelectedState('');
    setSelectedCity('');
    setSelectedPrevCity('');
    setSelectedPrevState('');
    setIsCollapsed(false);
  }

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const handleOpen = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }

  return (
    <div className={`main-wrapper ${isSidebarOpen ? 'slide-nav' : ''}`}>
      <Header handleOpen={handleOpen} />
      <Sidebar />

      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title user-title">{data?._id ? 'Edit' : 'Add'} Employee {data?._id ? <>( {data?.employee_id} )</> : null}</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/user/dashboard">Dashboard</Link></li>
                  <li className="breadcrumb-item"><Link to='/user/employee-management'>Employee Management</Link></li>
                  <li className="breadcrumb-item active">{data?._id ? 'Edit' : 'Add'} Employee</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="card p-3 basicform">
            <div className=" mb-3 titleForm border-bottom">
              <h4 className="">Basic Information</h4>
            </div>

            <div className="row card_id">
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="firstName" className="form-label">First Name</label>
                    <input type="text" className="form-control" id="firstName"
                      name='first_name' value={employee?.first_name} onChange={handleChange}
                    />
                    <div className='error'>{error?.first_name_err}</div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="lastName" className="form-label">Last Name</label>
                    <input type="text" className="form-control" id="lastName"
                      onChange={handleChange} name='last_name' value={employee?.last_name}
                    />
                    <div className='error'>{error?.last_name_err}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row card_id">
              <div className="col-md-8 col-lg-8 col-xxl-8 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">Full Name <span className='text-danger'> *</span> <small>(First, Middle, Last)</small></label>
                    <input type="text" className="form-control"
                      onChange={handleChange} name='full_name' value={employee?.full_name}
                      ref={fullNameRef}
                    />
                    <div className='error'>{error?.full_name_err}</div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="middleName" className="form-label">Father's Name</label>
                    <input type="text" className="form-control" id="father_name"
                      onChange={handleChange} name='middle_name' value={employee?.middle_name}
                    />
                    <div className='error'>{error?.middle_name_err}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row card_id'>

              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="joiningDate" className="form-label">Joining Date <span className='text-danger'> *</span></label>
                    <input type="date" className="form-control" id="joiningDate"
                      onChange={handleChange} name='joining_date' value={employee?.joining_date}
                      ref={joinDateRef} />
                    <div className='error'>{error?.join_date_err}</div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="designation" className="form-label">Designation <span className='text-danger'> *</span></label>
                    <select className="form-select form-control" aria-label="Default select example"
                      onChange={handleChange} name='designation' value={employee?.designation}
                      ref={designationRef}>
                      <option value=''>Select Designation</option>
                      {designationData?.map((e) =>
                        <option value={e?._id} key={e?._id}>{e?.name}</option>
                      )}
                    </select>
                    <div className='error'>{error?.designation_err}</div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="leavingDate" className="form-label">ESIC IP</label>
                    <input type="text" className="form-control"
                      onChange={handleChange} name='esic_ip' value={employee?.esic_ip}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row card_id">
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="cardNum" className="form-label">Gate Pass No.</label>
                    <input type="text" className="form-control" id="cardNum"
                      value={employee?.card_no} name='card_no'
                      onChange={handleChange}
                    />
                    <div className='error'>{error?.card_no_err}</div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="uanno" className="form-label">Uan No.</label>
                    <input type="text" className="form-control" id="uanno"
                      name='uan_no' value={employee?.uan_no} onChange={handleChange} />
                    <div className='error'>{error?.uan_no_err}</div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email Id </label>
                    <input type="text" className="form-control" id="email"
                      onChange={handleChange} name='email' value={employee?.email}
                      ref={emailRef} />
                    <div className='error'>{error?.email_err}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row card_id">
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="gender" className="form-label">Gender <span className='text-danger'> *</span></label>
                    <select className="form-select form-control" aria-label="Default select example"
                      onChange={handleChange} name='gender' value={employee?.gender}
                      ref={genderRef} >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                    <div className='error'>{error?.gender_err}</div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="selectDate" className="form-label">DOB <span className='text-danger'> *</span></label>
                    <input type="date" className="form-control" id="selectDate"
                      onChange={handleChange} name='dob' value={employee.dob}
                      ref={dobRef} />
                    <div className='error'>{error?.dob_err}</div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label className="form-label">Age</label>
                    <input className="form-control" value={age} disabled />
                  </div>
                </div>
              </div>

            </div>

            <div className="row card_id">
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="phoneNumber" className="form-label">Mobile <span className='text-danger'> *</span></label>
                    <input type="number" className="form-control" id="phoneNumber"
                      onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown}
                      name='mobile_number' value={employee?.mobile_number}
                      onChange={handleChange} ref={mobileRef}
                    />
                    <div className='error'>{error?.mobile_err}</div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="fileUpload" className="form-label">Employee Image</label>
                    <input type="file" className="form-control" id="fileUpload" onChange={(e) => handleEmployeeImg(e)} />
                    {employee?.image ? <img src={employee?.image} alt='employee-img' style={{ height: '130px', objectFit: 'cover', borderRadius: '10px', marginTop: "10px" }} /> : null}
                    <div className='error'>{error?.image_err}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='card p-3 personalform'>
            <div className=" mb-3 titleForm border-bottom">
              <h4 className="">Documents</h4>
            </div>

            <div className="row card_id">
              <div className="col-md-6 col-lg-6 col-xxl-6 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <div className="cardNum">
                      <div className="mb-3">
                        <label className="form-label">Aadhaar Card No. <span className='text-danger'> *</span></label>
                        <input type="number" className="form-control"
                          onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown}
                          onChange={handleChange} name='adhar_no' value={employee?.adhar_no}
                          ref={adharNoRef}
                        />
                        <div className='error'>{error?.adhar_no_err}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-6 col-xxl-6 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="panCard" className="form-label">Pan Card No.</label>
                    <input type="text" className="form-control" id="panCard"
                      onChange={handleChange} name='pancard_no' value={employee?.pancard_no}
                    />
                    <div className='error'>{error?.pan_no_err}</div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-6 col-xxl-6 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="fileUploadAadhar" className="form-label">Aadhaar Card Image</label>
                    <input type="file" className="form-control" id="fileUploadAadhar" onChange={(e) => handleAadharCardImage(e)} />
                    {employee?.aadhar_card_image ? <img src={employee?.aadhar_card_image} alt='Aadhaar card' style={{ height: '130px', objectFit: 'cover', borderRadius: '10px', marginTop: "10px" }} /> : null}
                    <div className='error'>{error?.aadhar_card_image_err}</div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-6 col-xxl-6 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="fileUploadPan" className="form-label">Pancard Image</label>
                    <input type="file" className="form-control" id="fileUploadPan" onChange={(e) => handlePanCardImage(e)} />
                    {employee?.pan_card_image ? <img src={employee?.pan_card_image} alt='PAN card' style={{ height: '130px', objectFit: 'cover', borderRadius: '10px', marginTop: "10px" }} /> : null}
                    <div className='error'>{error?.pan_card_image_err}</div>
                  </div>
                </div>
              </div>

            </div>

          </div>

          <div className="card p-3 personalform ">
            <div className=" mb-3 titleForm border-bottom">
              <h4 className="">Other Documents</h4>
            </div>

            <div className="row card_id">
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="fileUpload" className="form-label">Other Documents Images</label>
                    <input type="file" className="form-control" id="fileUpload" onChange={handleMultipleImg} multiple />
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              {multiImg?.map((image, index) =>
                <div key={index} className='col-md-3 imgContainer mb-2'>
                  <img src={image?.image} alt='other doc imgs' style={{ borderRadius: "10px", width: "100%", height: "150px", objectFit: "cover" }} />
                  <div className='imageDeletIcon'>
                    <div className='iconImg' onClick={() => handleRemoveImg(image.image)}><i className="fa-solid fa-xmark" style={{ fontSize: "15px", color: "#fff", }}></i></div>
                  </div>
                </div>
              )}
            </div>

          </div>


          <div className="card p-3 personalform ">
            <div className=" mb-3 titleForm border-bottom">
              <h4 className="">Other Information</h4>
            </div>

            <div className="row card_id">
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="shift" className="form-label">Shift <span className='text-danger'> *</span></label>
                    <select className="form-select form-control" aria-label="Default select example"
                      name='shift' onChange={handleChange} value={shiftData?.length > 0 ? shiftData[0]._id : ''} disabled>
                      <option value=''>Select Shift</option>
                      {shiftData?.map((e) =>
                        <option value={e?._id} key={e?._id}>{e?.name}</option>
                      )}
                    </select>
                    <div className='error'>{error?.shift_err}</div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="intime" className="form-label">In Time <span className='text-danger'> *</span></label>
                    <input type="time" className="form-control" id="intime"
                      onChange={handleChange} name='in_time' value={employee?.in_time}
                      ref={inTimeRef} />
                    <div className='error'>{error?.in_time_err}</div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <div className="cardNum">
                      <div className="mb-3">
                        <label htmlFor="outTime" className="form-label">Out Time <span className='text-danger'> *</span></label>
                        <input type="time" className="form-control" id="outTime"
                          onChange={handleChange} name='out_time' value={employee?.out_time}
                          ref={outTimeRef} />
                        <div className='error'>{error?.out_time_err}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row card_id">
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="shift" className="form-label">Shift <span className='text-danger'> *</span></label>
                    <select className="form-select form-control" aria-label="Default select example"
                      name='shift_two' onChange={handleChange} value={shiftData?.length > 0 ? shiftData[1]._id : ''} disabled>
                      <option value=''>Select Shift</option>
                      {shiftData?.map((e) =>
                        <option value={e?._id} key={e?._id}>{e?.name}</option>
                      )}
                    </select>
                    <div className='error'>{error?.shift_err}</div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="intime" className="form-label">In Time<span className='text-danger'> *</span></label>
                    <input type="time" className="form-control" id="intime"
                      onChange={handleChange} name='in_time_two' value={employee?.in_time_two}
                      ref={inTimeTwoRef} />
                    <div className='error'>{error?.in_time_two_err}</div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <div className="cardNum">
                      <div className="mb-3">
                        <label htmlFor="outTime" className="form-label">Out Time <span className='text-danger'> *</span></label>
                        <input type="time" className="form-control" id="outTime"
                          onChange={handleChange} name='out_time_two' value={employee?.out_time_two}
                          ref={outTimeTwoRef} />
                        <div className='error'>{error?.out_time_two_err}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row card_id">
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <div className="cardNum">
                      <div className="mb-3">
                        <label htmlFor="leavingDate" className="form-label">Leaving Date</label>
                        <input type="date" className="form-control" id="leavingDate"
                          onChange={handleChange} name='leaving_date' value={employee?.leaving_date}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="week off" className="form-label">Week Off <span className='text-danger'> *</span></label>
                    <select className="form-select form-control" aria-label="Default select example"
                      onChange={handleChange} name='holiday' value={employee?.holiday} ref={holidayRef}>
                      <option value=''>Select Week Off</option>
                      <option value='Monday'>Monday</option>
                      <option value='Tuesday'>Tuesday</option>
                      <option value='Wednesday'>Wednesday</option>
                      <option value='Thursday'>Thursday</option>
                      <option value='Friday'>Friday</option>
                      <option value='Saturday'>Saturday</option>
                      <option value='Sunday'>Sunday</option>
                    </select>
                    <div className='error'>{error?.holiday_err}</div>
                  </div>
                </div>
              </div>

              <div className="row card_id">
                <div className="col-md-12 col-lg-12 col-xxl-12 col-sm-12">
                  <div className="cardNum">
                    <div className="mb-3">
                      <label htmlFor="leavingReason" className="form-label">Leaving Reason</label>
                      <textarea className="form-control" placeholder="Please provide a brief explanation for leaving..."
                        onChange={handleChange} name='leaving_reason' value={employee?.leaving_reason}
                        id="leavingReason"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="card p-3 ">
            <div className=" mb-3 titleForm border-bottom">
              <h4 className="">Permanent & Present Address</h4>
            </div>
            <div className="p-2 card  Addressform">
              <div className="row card_id">
                <h4 className="bg-body-secondary p-2" style={{ borderRadius: "3px" }}>Permanent Address</h4>

                <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                  <div className="cardNum">
                    <div className="mb-3">
                      <label htmlFor="address1" className="form-label">Address<span className='text-danger'> *</span></label>
                      <input className="form-control" id="address1" rows="3"
                        name='address' onChange={handleChange} value={employee?.address}
                        ref={addressRef} />
                      <div className='error'>{error?.address_err}</div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                  <div className="cardNum">
                    <div className="mb-3">
                      <label htmlFor="address2" className="form-label">Address 2</label>
                      <input className="form-control" id="address2" rows="3"
                        onChange={handleChange}
                        name='address_two'
                        value={employee?.address_two} />
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                  <div className="cardNum">
                    <div className="mb-3">
                      <label htmlFor="address3" className="form-label">Address 3</label>
                      <input className="form-control" id="address3" rows="3"
                        name='address_three' value={employee?.address_three} onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row card_id">
                <div className="col-md-4 col-lg-4 col-xxl-4">
                  <div className="cardNum">
                    <div className="mb-3">
                      <label htmlFor="State" className="form-label">State<span className='text-danger'> *</span></label>
                      <select className="form-select form-control"
                        value={selectedState}
                        onChange={handleStateChange} name='state'
                        ref={stateRef}
                        aria-label="Default select example">
                        <option value="">Select State</option>
                        {states?.map((e) =>
                          <option value={e?.name} key={e?.name}>{e?.name}</option>
                        )}
                      </select>
                      <div className='error'>{error?.state_err}</div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-lg-4 col-xxl-4">
                  <div className="cardNum">
                    <div className="mb-3">
                      <label htmlFor="city" className="form-label">City<span className='text-danger'> *</span></label>
                      <select className="form-select form-control"
                        value={selectedCity}
                        disabled={!selectedState}
                        onChange={handleCityChange} name='city'
                        ref={cityRef}
                        aria-label="Default select example">
                        <option value="">Select City</option>
                        {cities.map((e) =>
                          <option value={e?.name} key={e?.name}>{e?.name}</option>
                        )}
                      </select>
                      <div className='error'>{error?.city_err}</div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-lg-4 col-xxl-4">
                  <div className="cardNum">
                    <div className="mb-3">
                      <label htmlFor="pinCode" className="form-label">Pincode <span className='text-danger'> *</span></label>
                      <input type="number" className="form-control" id="pinCode"
                        onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown}
                        onChange={handleChange}
                        ref={pincodeRef}
                        name='pincode' value={employee?.pincode} />
                      <div className='error'>{error?.pincode_err}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" card m-0 checkCard">
              <div className="card_id ">
                <div className="d-flex align-items-center gap-2 bg-body-secondary ">
                  <h4 className="p-2 m-0" style={{ borderRadius: "3px" }}>Present Address</h4>
                  <div className="d-flex gap-2 align-center">
                    <input type="checkbox" className="d-flex form-check-input m-0"
                      checked={isCollapsed}
                      id="addressCheckbox"
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor=""> Present Address Same As Permanent Address</label>
                  </div>
                </div>
                <div className={`collapse mt-3 ${!isCollapsed ? 'show ' : ''}`} id="collapseExample">
                  <div className=" card-body">
                    <div className="row">
                      <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                        <div className="cardNum">
                          <div className="mb-3">
                            <label htmlFor="address1" className="form-label">Address <span className='text-danger'> *</span></label>
                            <input className="form-control" type='text'
                              name='pre_address' value={employee?.pre_address} onChange={handleChange}
                            />
                            <div className='error'>{error?.pre_address_err}</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                        <div className="cardNum">
                          <div className="mb-3">
                            <label htmlFor="address2" className="form-label">Address 2</label>
                            <input type='text' className="form-control" id="address2"
                              value={employee?.pre_address_two} onChange={handleChange} name='pre_address_two'
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                        <div className="cardNum">
                          <div className="mb-3">
                            <label htmlFor="address3" className="form-label">Address 3</label>
                            <input type='text' className="form-control" onChange={handleChange} name='pre_address_three' value={employee?.pre_address_three} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row card_id">
                      <div className="col-md-4 col-lg-4 col-xxl-4">
                        <div className="cardNum">
                          <div className="mb-3">
                            <label htmlFor="State" className="form-label">State <span className='text-danger'> *</span></label>
                            <select className="form-select form-control"
                              value={selectedPrevState} name='selectedPrevState'
                              onChange={handlePrevStateChange}
                              aria-label="Default select example">
                              <option value=''>Select State</option>
                              {prevState.map((e) =>
                                <option value={e?.name} key={e?.name} >{e?.name}</option>
                              )}
                            </select>
                            <div className='error'>{error?.pre_state_err}</div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 col-lg-4 col-xxl-4">
                        <div className="cardNum">
                          <div className="mb-3">
                            <label htmlFor="city" className="form-label">City <span className='text-danger'> *</span></label>
                            <select className="form-select form-control"
                              value={selectedPrevCity}
                              disabled={!selectedPrevState}
                              onChange={handlePrevCityChange}
                              aria-label="Default select example">
                              <option value=''>Select City</option>
                              {prevCities?.map((e) =>
                                <option value={e?.name} key={e?.name}>{e?.name}</option>
                              )}
                            </select>
                            <div className='error'>{error?.pre_city_err}</div>
                          </div>
                        </div>

                      </div>

                      <div className="col-md-4 col-lg-4 col-xxl-4">
                        <div className="cardNum">
                          <div className="mb-3">
                            <label htmlFor="pinCode" className="form-label">Pincode <span className='text-danger'> *</span></label>
                            <input type="number" className="form-control" id="pinCode"
                              onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown}
                              onChange={handleChange} name='pre_pincode' value={employee.pre_pincode}
                            />
                            <div className='error'>{error?.pre_pincode_err}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card p-3 Blacklistform ">
            <div className=" mb-3 titleForm border-bottom">
              <h4 className="">Black List Information</h4>
            </div>
            <div className="row card_id">
              <div className="col-md-6 col-lg-6 col-xxl-6 col-sm-12">

                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="blackList" className="form-label">Black List</label>
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" role="switch" onChange={handleBlackList} checked={black_list} />
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-md-6 col-lg-6 col-xxl-6 col-sm-12">

                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="blackreason" className="form-label">Black List Reason</label>
                    <input type="text" className="form-control" id="blackreason"
                      onChange={handleChange} name='black_list_reason' value={employee?.black_list_reason}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card p-3 Blacklistform ">
            <div className=" mb-3 titleForm border-bottom">
              <h4 className="">Emergency Contact</h4>
            </div>

            <div className=" card m-0 checkCard">
              <div className="card_id ">
                <div className="d-flex align-items-center gap-2 bg-body-secondary ">
                  <h4 className="p-2 m-0" style={{ borderRadius: "3px" }}>Emergency Contact</h4>
                  <div className="d-flex gap-2 align-center">
                    <input type="checkbox" className="d-flex form-check-input m-0"
                      checked={isCollapsedContact}
                      id="addressCheckbox"
                      onChange={emergencyChange}
                    />
                    <label htmlFor=""> </label>
                  </div>
                </div>
                <div className={`collapse mt-3 ${!isCollapsedContact ? ' ' : 'show'}`} id="collapseExample">
                  <div className=" card-body">

                    <div className="row card_id">
                      <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                        <div className="cardNum">
                          <div className="mb-3">
                            <label htmlFor="Emname" className="form-label">Name <span className='text-danger'> *</span></label>
                            <input type="text" className="form-control"
                              name='emergency_contact_person' onChange={handleChange} value={employee?.emergency_contact_person}
                            />
                            <div className='error'>{error?.emergency_person_err}</div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                        <div className="cardNum">
                          <div className="mb-3">
                            <label htmlFor="relationship" className="form-label">Relationship <span className='text-danger'> *</span></label>
                            <input type="text" className="form-control" id="relationship"
                              name='emergency_person_relation' onChange={handleChange} value={employee?.emergency_person_relation}
                            />
                            <div className='error'>{error?.emergency_relation_err}</div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                        <div className="cardNum">
                          <div className="mb-3">
                            <label htmlFor="emPhone" className="form-label">Mobile <span className='text-danger'> *</span></label>
                            <input type="number" className="form-control" id="emPhone"
                              onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown}
                              name='emergency_contact_number' value={employee?.emergency_contact_number} onChange={handleChange}
                            />
                            <div className='error'>{error?.emergency_mobile_err}</div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                        <div className="cardNum">
                          <div className="mb-3">
                            <label className="form-label">DOB <span className='text-danger'> *</span></label>
                            <input type="date" className="form-control"
                              name='emergency_person_dob' onChange={handleChange} value={employee?.emergency_person_dob}
                            />
                            <div className='error'>{error?.emergency_person_dob_err}</div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                        <div className="cardNum">
                          <div className="mb-3">
                            <label className="form-label">Aadhaar Card No. <span className='text-danger'> *</span></label>
                            <input type="number" className="form-control"
                              onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown}
                              name='emergency_person_aadhar_number' value={employee?.emergency_person_aadhar_number} onChange={handleChange}
                            />
                            <div className='error'>{error?.emergency_person_aadhar_number_err}</div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                        <div className="cardNum">
                          <div className="mb-3">
                            <label className="form-label">Aadhaar Card Image<span className='text-danger'> *</span></label>
                            <input type="file" className="form-control"
                              onChange={handleEmergencyAadhar}
                            />
                            <div className='error'>{error?.emergency_person_aadhar_photo_err}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="card p-3 basicform">
            <div className=" mb-3 titleForm border-bottom">
              <h4 className="">Additional Information</h4>
            </div>

            <div className="row card_id">

              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="emPhone" className="form-label">Height</label>
                    <input type="number" className="form-control" id="emPhone"
                      onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown}
                      onChange={handleChange} name='height' value={employee?.height}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="emPhone" className="form-label">Weight</label>
                    <input type="number" className="form-control" id="emPhone"
                      onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown}
                      onChange={handleChange} name='weight' value={employee?.weight}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="city" className="form-label">Skill</label>
                    <select className="form-select form-control" onChange={handleChange} name='skill' value={employee?.skill}
                      aria-label="Default select example">
                      <option value=''>Select Skill</option>
                      {skillData?.map((e) =>
                        <option value={e?._id} key={e?._id}>{e?.name}</option>
                      )}
                    </select>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="city" className="form-label">Type</label>
                    <select className="form-select form-control"
                      onChange={handleChange} name='type' value={employee?.type}
                      aria-label="Default select example">
                      <option value=''>Select Type</option>
                      {employeeType?.map((e) =>
                        <option value={e?._id} key={e?._id}>{e?.name}</option>
                      )}
                    </select>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="emPhone" className="form-label">ESI No.</label>
                    <input type="number" className="form-control" id="emPhone"
                      onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown}
                      onChange={handleChange} name='esi_number' value={employee?.esi_number}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label className="form-label">Passport No.</label>
                    <input type="number" className="form-control"
                      onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown}
                      onChange={handleChange} name='passport' value={employee?.passport}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label className="form-label">Nationality</label>
                    <input type="text" className="form-control"
                      onChange={handleChange} name='nationality' value={employee?.nationality}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label className="form-label">Caste</label>
                    <input type="text" className="form-control"
                      onChange={handleChange} name='caste' value={employee?.caste}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label className="form-label">Blood Group</label>
                    <input type="text" className="form-control"
                      onChange={handleChange} name='blood_group' value={employee?.blood_group}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-lg-12 col-xxl-12 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label className="form-label">Education</label>
                    <textarea className="form-control" placeholder="Please provide education details here..."
                      onChange={handleChange} name='education' value={employee?.education}
                      id="leavingReason"></textarea>
                  </div>
                </div>
              </div>

              {data?._id ? (
                <div className='col-md-4 col-lg-4 col-xxl-4 col-sm-12'>
                  <div className="cardNum">
                    <div className="mb-3">
                      <label htmlFor="fileUpload" className="form-label">Status</label>
                      <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" onChange={handleRadioChange} checked={selectValue} />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="row card_id mt-4 sm-mt-3">
              <div className=" col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3 d-flex gap-2">
                    <button className="btn btn-success w-50" type='button' onClick={handleSubmit} disabled={disable || disableMulti}>{disable || disableMulti ? "Processing..." : (data?._id ? 'Update' : 'Submit')}</button>
                    <button className="btn btn-outline-secondary w-50" type='button' onClick={handleReset} >Reset</button>
                    {!data?._id ? <button type="button" className="btn btn-outline-success w-50" onClick={() => handleSubmit('true')} disabled={disableMore || disableMulti}>{disableMore || disableMulti ? "Processing..." : 'Add More'}</button> : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CopyRight />
      </div>
    </div >
  )
}

export default ManageEmployee