import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import { PAY_URL } from "../../../../BaseURL";

export const getAdminStore = createAsyncThunk('/group/getAdminStore',
    async () => {
        try {
            const myurl = `${PAY_URL}/admin/get-admin-store`;

            const response = await axios({
                method: 'get',
                url: myurl,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem('PAY_ADMIN_TOKEN')
                },
            });

            const data = response.data;
            // console.log(data, "getAdminStore response");

            if (data.success === true) {
                return data;
            } else {
                throw new Error(data.message);
            }
        } catch (error) {
            console.log(error, "error");
            toast.error(error.response.data.message);
            return error
        }
    })

const getAdminStoreSlice = createSlice({
    name: "getAdminStore",
    initialState: {
        user: null,
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAdminStore.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAdminStore.fulfilled, (state, action) => {
                state.user = action.payload;
                state.loading = false;
                state.error = null;
            })
            .addCase(getAdminStore.rejected, (state, action) => {
                state.user = null;
                state.loading = false;
                state.error = action.error.message;
            });
    }
})

export default getAdminStoreSlice.reducer;