import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { PAY_URL } from '../../../BaseURL';
import toast from 'react-hot-toast';
import moment from 'moment';
import cryptojs from 'crypto-js'

const Login = () => {

    const [login, setLogin] = useState({ email: "", password: "", pwdShow: true, remember: false });
    const [newData, setNewData] = useState({ year: "", firm: "", email: "" });
    const [yearData, setYearData] = useState([]);
    const [firmData, setFirmData] = useState([]);
    const [disable, setDisable] = useState(false);
    const [error, setError] = useState({});
    const [errorTwo, setErrorTwo] = useState({});
    const navigate = useNavigate();
    const secretKey = 'Vishal Enterprise';

    useEffect(() => {
        if (localStorage.getItem('PAY_USER_REMEMBER_ME') && localStorage.getItem('PAY_USER_REMEMBER_ME') === 'true') {
            const password = cryptojs.AES.decrypt(localStorage.getItem('PAY_USER_PASSWORD'), secretKey);
            const decryptedPassword = password.toString(cryptojs.enc.Utf8);
            setLogin({
                email: localStorage.getItem('PAY_USER_EMAIL'),
                password: decryptedPassword,
                remember: true,
                pwdShow: true
            })
        }
    }, []);

    const handleChange = (e) => {
        setLogin({ ...login, [e.target.name]: e.target.value });
    };

    const handleChangeTwo = (e) => {
        setNewData({ ...newData, [e.target.name]: e.target.value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validation()) {
            setDisable(true);

            const myurl = `${PAY_URL}/user/login`;
            const bodyFormData = new URLSearchParams();

            bodyFormData.append('email', login.email);
            bodyFormData.append('password', login.password);

            axios({
                method: "post",
                url: myurl,
                data: bodyFormData,
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
            }).then((response) => {
                // console.log(response?.data, '@@@');

                const data = response.data.data

                if (response.data.success === true) {
                    setFirmData(data?.firm);
                    setYearData(data?.year);
                    setNewData({
                        email: data?.email
                    });


                    localStorage.setItem('PAY_USER_TOKEN', data?.token);
                    localStorage.setItem('PAY_USER_NAME', data?.name);
                    localStorage.setItem('PAY_USER_ID', data?.id);
                    localStorage.setItem('PAY_USER_EMAIL', data?.email);

                    localStorage.setItem('PAY_SUB_USER', cryptojs.AES.encrypt(data?.pay_subUser?.toString(), secretKey).toString())

                    toast.success(response?.data?.message);
                }

                if (login?.remember) {
                    localStorage.setItem('PAY_USER_EMAIL', login?.email);
                    localStorage.setItem('PAY_USER_PASSWORD', cryptojs.AES.encrypt(login?.password, secretKey).toString());
                    localStorage.setItem('PAY_USER_REMEMBER_ME', login?.remember);
                } else {
                    localStorage.setItem('PAY_USER_EMAIL', login?.email);
                    localStorage.setItem('PAY_USER_PASSWORD', cryptojs.AES.encrypt(login?.password, secretKey).toString());
                    localStorage.setItem('PAY_USER_REMEMBER_ME', login?.remember);
                }

                setDisable(false);
                // setLogin({ email: "", password: "", pwdShow: true });    
            }).catch((error) => {
                console.log(error, "error");
                toast.error(error?.response?.data?.message)
                setDisable(false);
            })
        }
    }

    const handleSignIn = (e) => {
        e.preventDefault();
        if (validationTwo()) {
            setDisable(true)
            if (firmData.length > 0 || yearData?.length > 0) {
                toast.success("Data Selected Successfully");
                localStorage.setItem('PAY_USER_YEAR_ID', newData?.year);
                localStorage.setItem('PAY_USER_FIRM_ID', newData?.firm);
                navigate('/user/dashboard');
                setDisable(false);
            } else {
                toast.error("Some error occurred.")
                setDisable(false);
            }
        }
    }

    const validationTwo = () => {
        var isValid = true;
        let err = {};

        if (!newData.firm) {
            isValid = false;
            err['firm_err'] = "Please select firm"
        }

        if (!newData.year) {
            isValid = false;
            err['year_err'] = "Please select year"
        }

        setErrorTwo(err);
        return isValid;
    }

    const validation = () => {
        var isValid = true;
        let err = {};

        if (!login.email) {
            isValid = false;
            err['email_err'] = "Please enter email"
        }

        if (!login.password) {
            isValid = false;
            err['password_err'] = "Please enter password"
        }

        setError(err);
        return isValid;
    }

    return (
        <div>
            <div className="account-page login-page">
                <div className="frame-login"></div>
                <div className="container">
                    <div className="row login-Row">
                        <div className="col-md-6 col-lg-5 col-sm-12">
                            <div className="card p-3 cardTransparent mb-0">
                                <div className="logoLogin d-flex align-center justify-content-center mb-2">
                                    <img src="/assets/img/Full-Blue.svg" alt="Logo" />
                                </div>

                                {(firmData?.length === 0 || yearData?.length === 0) ? (
                                    <>
                                        <h3 className="account-title mt-4 mb-4 text-center">User Login</h3>
                                        <form className="row g-3" onSubmit={handleSubmit}>
                                            <div className="col-md-12 mt-4">
                                                <label htmlFor="inputEmail4" className="form-label">Email Address</label>
                                                <input className="form-control loginInpu"
                                                    onChange={handleChange} name='email'
                                                    type="text" value={login.email} />
                                                <div className='error'>{error.email_err}</div>
                                            </div>

                                            <div className="col-md-12 position-relative mt-4">
                                                <label htmlFor="password" className="form-label">Password</label>
                                                <input className="form-control position-relative loginInpu" type={login.pwdShow ? "password" : "text"}
                                                    value={login.password} onChange={handleChange} name='password' id='password' />
                                                {login.pwdShow ? <div className="icon-Show" onClick={() => { setLogin({ ...login, pwdShow: false }) }}><i className='fa-solid fa-eye' /></div> : <div className='icon-Show' onClick={() => setLogin({ ...login, pwdShow: true })}><i className='fa-solid fa-eye-slash' /> </div>}
                                                <div className='error'>{error.password_err}</div>
                                            </div>

                                            <div className="forgotPass justify-content-end d-flex mt-2">
                                                <span className='user-account'> <Link to='/user/forget-password'>Forgot Password?</Link></span>
                                            </div>

                                            <div className='col-md-12'>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={login.remember} onChange={() => setLogin({ ...login, remember: !login.remember })} />
                                                    <label className="form-check-label" htmlFor="flexCheckDefault">Remember me</label>
                                                </div>
                                            </div>

                                            <div className="input-block mt-2 text-center mt-4">
                                                <button className="btn btn-success account-btn w-100" type="submit" disabled={disable}>{disable ? "Processing..." : "Continue"}</button>
                                            </div>

                                            {/* <div className="account-footer mt-0">
                                                <p className='text-center'><span className='user-account'> <Link to="/">Back</Link></span></p>
                                            </div> */}

                                        </form>
                                    </>
                                ) : null}

                                {(firmData?.length > 0 || yearData?.length > 0) ? (
                                    <>
                                        <h3 className="account-title mt-4 mb-4 text-center">{newData?.email}</h3>

                                        <form className="row g-3" onSubmit={handleSignIn}>

                                            <div className="col-md-12 position-relative mt-2">
                                                <label htmlFor="password" className="form-label">Firm</label>
                                                <select className="form-select form-control"
                                                    aria-label="Default select example"
                                                    onChange={handleChangeTwo} name='firm' value={newData?.firm}
                                                >
                                                    <option value="">Select Firm</option>
                                                    {firmData?.map((e) =>
                                                        <option value={e?._id} key={e?._id}>{e?.name}</option>
                                                    )}
                                                </select>
                                                <div className='error'>{errorTwo?.firm_err}</div>
                                            </div>

                                            <div className="col-md-12 position-relative mt-2">
                                                <label htmlFor="password" className="form-label">Year</label>
                                                <select className="form-select form-control"
                                                    aria-label="Default select example"
                                                    onChange={handleChangeTwo} value={newData?.year} name='year'
                                                >
                                                    <option value="">Select Year</option>
                                                    {yearData?.map((e) =>
                                                        <option value={e?._id} key={e?._id}>{moment(e?.start_year).format('YYYY')}-{moment(e?.end_year).format('YYYY')}</option>
                                                    )}
                                                </select>
                                                <div className='error'>{errorTwo?.year_err}</div>
                                            </div>

                                            <div className="input-block mt-2 text-center mt-4">
                                                <button className="btn btn-success account-btn w-100" type="submit" disabled={disable}>{disable ? "Processing..." : "Sign in"}</button>
                                            </div>
                                        </form>
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default Login