
import { useEffect } from 'react';
import Home from './Routes/Home';
import User from './Routes/User';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

function App() {

  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem('PAY_SUB_USER') && localStorage.getItem('PAY_USER_TOKEN')) {
      localStorage.clear();
      navigate('/user/login');
      toast.error('You have been logged out. Please log in again.');
    }
  }, [navigate]);

  return (
    <>
      <User />
      <Home />
    </>
  );
}

export default App;
