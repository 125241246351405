import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Header from '../Pages/Header';
import Sidebar from '../Pages/Sidebar';
import Clock from 'react-live-clock';
import { PAY_URL } from '../../../BaseURL';
import axios from 'axios';
import toast from 'react-hot-toast';
import moment from 'moment';
import CopyRight from '../Pages/CopyRight';
import CountUp from 'react-countup';

const Dashboard = () => {

  const navigate = useNavigate();
  const [holiday, setHoliday] = useState([]);
  const [disable, setDisable] = useState(true);

  // const [showHoliday, setShowHoliday] = useState({
  //   day: "",
  //   name: "",
  //   date: "",
  //   month: "",
  // });

  const [showHolidays, setShowHolidays] = useState([]);

  const [dashboard, setDashboard] = useState({
    employee: 0,
    earning: 0,
    deduction: 0,
    loan: 0,
    t_present: 0,
    t_absent: 0
  })

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;


  useEffect(() => {
    if (localStorage.getItem('PAY_USER_TOKEN') === null) {
      navigate("/user/login");
    }

    if (disable === true) {
      setHoliday([]);
      getHoliday();
      getDashboard();
    }
  }, [disable, navigate]);

  const getHoliday = () => {
    const myurl = `${PAY_URL}/user/get-admin-holiday`;
    axios({
      method: "get",
      url: myurl,
      headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('PAY_USER_TOKEN') },
    }).then((response) => {
      // console?.log("@@", response?.data);
      if (response?.data?.success) {
        const data = response?.data?.data

        const filteredData = data?.filter(e =>
          e.firm_id?._id === localStorage.getItem('PAY_USER_FIRM_ID')
          // && e.year_id?._id === localStorage.getItem('PAY_USER_YEAR_ID')
        );

        setHoliday(filteredData);
        setDisable(false);
      } else {
        toast.error("Something went wrong");
      }
    }).catch((error) => {
      toast.error("Something went wrong");
      console?.log("Errors", error);
    });
  }

  const getDashboard = () => {
    const myurl = `${PAY_URL}/user/dashboard`;

    const bodyFormData = new URLSearchParams();
    bodyFormData.append('firm_id', localStorage.getItem('PAY_USER_FIRM_ID'));
    bodyFormData.append('year_id', localStorage.getItem('PAY_USER_YEAR_ID'));

    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('PAY_USER_TOKEN') },
    }).then((response) => {
      // console?.log("@@ DASH", response?.data);
      if (response?.data?.success) {
        const data = response?.data?.data

        setDashboard({
          employee: data?.total_employee,
          earning: data?.total_earning,
          deduction: data?.total_other_deduction,
          loan: data?.total_loan_deduction,
          t_present: data?.today_present,
          t_absent: data?.today_absent
        })

      } else {
        toast.error("Something went wrong");
      }
    }).catch((error) => {
      toast.error("Something went wrong");
      console?.log("Errors", error);
    });
  }

  useEffect(() => {
    const upcomingHolidays = holiday.filter(h => moment(h.date).isSameOrAfter(formattedDate));

    if (upcomingHolidays?.length === 0) {
      setShowHolidays([{ name: "No holidays today or upcoming", date: formattedDate }]);
    } else {
      upcomingHolidays.sort((a, b) => moment(a.date).diff(b.date));
      const filteredHolidays = upcomingHolidays.filter((holiday, index) => {
        return index === upcomingHolidays.findIndex(h => h.date === holiday.date);
      });
      setShowHolidays(filteredHolidays);
    }
    // eslint-disable-next-line
  }, [holiday]);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const handleOpen = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }


  return (
    <div className={`main-wrapper ${isSidebarOpen ? 'slide-nav' : ''}`}>
      <Header handleOpen={handleOpen} />
      <Sidebar />
      <div className="page-wrapper user-account">

        <div className="content container-fluid">

          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title ">Welcome {localStorage.getItem('PAY_USER_NAME')}!</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">Dashboard</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 col-sm-12 col-lg-4">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className="dash-widget-icon"><i className="fa-solid fa-user-check"></i></span>
                  <div className="dash-widget-info">
                    <h3><CountUp style={{ fontSize: "30px" }} end={dashboard.t_present} /></h3>
                    <span>Today Present</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-12 col-lg-4">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className="dash-widget-icon"><i className="fa-solid fa-user-minus"></i></span>
                  <div className="dash-widget-info">
                    <h3><CountUp style={{ fontSize: "30px" }} end={dashboard.t_absent} /></h3>
                    <span>Today Absent</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-12 col-lg-4">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className="dash-widget-icon"><i className="fa-solid fa-users"></i></span>
                  <div className="dash-widget-info">
                    <h3><CountUp style={{ fontSize: "30px" }} end={dashboard?.employee} /></h3>
                    <span>Employees</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-4 col-lg-4 col-sm-12'>
              <div className="card dash-widget">
                <div className="card-body">
                  <span className="dash-widget-icon"><i class="fa-solid fa-money-bill-1"></i></span>
                  <div className="dash-widget-info">
                    <h3><CountUp style={{ fontSize: "30px" }} end={dashboard?.deduction} /></h3>
                    <span>Deduction</span>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-md-4 col-lg-4 col-sm-12'>
              <div className="card dash-widget">
                <div className="card-body">
                  <span className="dash-widget-icon"><i className="fa-solid fa-piggy-bank"></i></span>
                  <div className="dash-widget-info">
                    <h3><CountUp style={{ fontSize: "30px" }} end={dashboard.loan} /></h3>
                    <span>Loan</span>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-md-4 col-lg-4 col-sm-12'>
              <div className="card dash-widget">
                <div className="card-body">
                  <span className="dash-widget-icon"><i className="fa-solid fa-dollar-sign"></i></span>
                  <div className="dash-widget-info">
                    <h3><CountUp style={{ fontSize: "30px" }} end={dashboard?.earning} /></h3>
                    <span>Earning</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row'>

            <div className="col-md-6 col-lg-6 col-sm-12">
              <div className="card dashboard-time">
                <div className="card-body">
                  <div className='row'>
                    <div className='col-md-6'>
                      <h3 className="card-title">Time</h3>
                    </div>

                    <div className='col-md-6 d-flex justify-content-end'>
                      <h3 className='card-title'><i className="fa-solid fa-clock"></i></h3>
                    </div>
                  </div>
                  <h4 className='mt-2'>
                    <Clock format={'dddd, MMMM DD, YYYY, h:mm:ss A'} interval={1000} ticking={true} />
                  </h4>
                </div>
              </div>
            </div>

            <div className='col-md-6 col-lg-6 col-sm-12'>
              <div className="card info-card flex-fill">
                <div className="card-body">
                  {showHolidays.map((holiday, index) => (
                    <div key={index}>
                      <h4>
                        {holiday.name === "No holidays today or upcoming"
                          ? holiday.name
                          : moment(holiday.date).isSame(moment(formattedDate), 'day')
                            ? "Today's Holiday: " + holiday.name
                            : "Upcoming Holiday (" + moment(holiday.date).fromNow() + "): " + holiday.name}
                      </h4>
                      <div className="holiday-details">
                        <div className="holiday-calendar">
                          <div className="holiday-calendar-icon">
                            <i className="fa-solid fa-calendar-days"></i>
                          </div>
                          <div className="holiday-calendar-content">
                            <h6>{holiday.name}</h6>
                            <p>{moment(holiday.date).format('ddd DD MMM YYYY')}</p>
                          </div>

                        </div>
                        <div class="holiday-btn">
                          <Link to="/user/holiday-management" class="btn">View All</Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <CopyRight />
        </div>

      </div>
    </div>
  )
}

export default Dashboard