import React from 'react'
import { Link } from 'react-router-dom'

const Login = () => {
    return (

        <div className='account-page login-page'>

            <div className="bgImg-Page"></div>
            <div className="container">
                <div className="row login-Row">
                    <div className="col-md-6 col-lg-5 col-sm-12">
                        <div className="card p-3 cardTransparent">
                            <div className="logoLogin d-flex align-center justify-content-center mb-3">
                                <img src="/assets/img/ColorLogo.svg" width="150px" height="auto" alt="Logo" />
                            </div>
                            <h3 className="account-title mt-4 mb-4">Payroll Login</h3>
                            <form className="row g-3">
                                <div className="col-md-12">
                                    <h4 className="text-secondary mb-3">For Admin User</h4>
                                    <div className="col-md-12">
                                        <Link to="/admin/login" className="btn btn-primary w-100">Admin Login</Link>
                                    </div>
                                </div>

                                <div className="col-md-12 mb-3">
                                    <h4 className="text-secondary mb-3">For Users</h4>
                                    <div className="col-md-12">
                                        <Link to="/user/login" className="btn btn-success w-100">User Login</Link>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login