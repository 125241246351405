import cryptojs from 'crypto-js';

const DecryptCode = () => {
    const secretKey = 'Vishal Enterprise';
    const encryptedSubUser = localStorage.getItem('PAY_SUB_USER');

    if (encryptedSubUser) {
        const subUser = cryptojs.AES.decrypt(encryptedSubUser, secretKey);
        const descryptVal = subUser.toString(cryptojs.enc.Utf8);
        return descryptVal === 'true';
    }

    return false;
}

export default DecryptCode;
