import React, { useState } from 'react'
import Header from '../../Pages/Header';
import Sidebar from '../../Pages/Sidebar';

const DailyReport = () => {

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const handleOpen = () => {
        setIsSidebarOpen(!isSidebarOpen)
    }

    return (
        <div className={`main-wrapper ${isSidebarOpen ? 'slide-nav' : ''}`}>
            <Header handleOpen={handleOpen} />
            <Sidebar />

        </div>
    )
}

export default DailyReport