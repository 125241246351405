import React, { useEffect } from 'react'
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { PAY_URL } from '../../../BaseURL';
import axios from 'axios';
import moment from 'moment';

const Header = ({ handleOpen }) => {

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('PAY_USER_TOKEN');
    localStorage.removeItem('PAY_USER_NAME');
    localStorage.removeItem('PAY_USER_IMG');
    localStorage.removeItem('U_FORGET_EMAIL');
    localStorage.removeItem('PAY_USER_FIRM_NAME');

    localStorage.removeItem('PAY_USER_START_YEAR');
    localStorage.removeItem('PAY_USER_END_YEAR');

    if (localStorage.getItem('PAY_USER_REMEMBER_ME') === 'false') {
      localStorage.removeItem('PAY_USER_EMAIL');
      localStorage.removeItem('PAY_USER_PASSWORD');
      localStorage.removeItem('PAY_USER_REMEMBER_ME');
    }
    localStorage.removeItem('PAY_USER_FIRM_ID');
    localStorage.removeItem('PAY_USER_YEAR_ID');
    localStorage.removeItem('PAY_USER_ID');

    localStorage.removeItem('PAY_SUB_USER');

    toast.success('Logged Out Successfully')
    navigate('/user/login');
  }

  useEffect(() => {
    if (localStorage.getItem('PAY_USER_TOKEN') === null) {
      navigate("/user/login");
    }
  }, [navigate]);


  useEffect(() => {
    if (!localStorage.getItem('PAY_USER_FIRM_NAME')
      && !localStorage.getItem('PAY_USER_START_YEAR')
      && !localStorage.getItem('PAY_USER_END_YEAR')) {
      getProfile();
    }
  }, []);

  const getProfile = () => {
    const myurl = `${PAY_URL}/user/get-profile`;
    axios({
      method: "get",
      url: myurl,
      headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('PAY_USER_TOKEN') },
    }).then(async (response) => {
      // console?.log("@@", response?.data);

      const data = response?.data?.data;

      const filterFirm = data?.firm?.find(fi => fi._id === localStorage.getItem('PAY_USER_FIRM_ID'));
      const filterYear = data?.year?.find(yr => yr._id === localStorage.getItem('PAY_USER_YEAR_ID'));

      localStorage.setItem('PAY_USER_FIRM_NAME', filterFirm.name)
      localStorage.setItem('PAY_USER_START_YEAR', filterYear?.start_year)
      localStorage.setItem('PAY_USER_END_YEAR', filterYear?.end_year)

    }).catch((error) => {
      toast.error("Something went wrong");
      console?.log("Errors", error);
    });
  }

  return (
    <div className="header userAccount">
      <div className="header-left">
        <Link to="/user/dashboard" className="logo">
          {/* <img src="/assets/img/colorLS.svg" width="50px" height="40" alt="Logo" /> */}
          <img src="/assets/img/Logo-blue.svg" style={{ height: '40px' }} alt="Logo" />
        </Link>
        <Link to="/user/dashboard" className="logo2">
          <img src="/assets/img/Logo-blue.svg" style={{ height: '40px' }} alt="Logo" />
        </Link>
      </div>

      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <a id="toggle_btn">
        <span className="bar-icon">
          <span></span>
          <span></span>
          <span></span>
        </span>
      </a>

      <div className="page-title-box">
        <h3>{localStorage.getItem('PAY_USER_NAME')} ({(localStorage.getItem('PAY_USER_FIRM_NAME') || '')?.slice(0, 35)}{localStorage.getItem('PAY_USER_FIRM_NAME')?.length > 35 && '...'}
          / {moment(localStorage.getItem('PAY_USER_START_YEAR')).format('YYYY')}-{moment(localStorage.getItem('PAY_USER_END_YEAR')).format('YYYY')})</h3>
      </div>

      <a onClick={handleOpen} className="mobile_btn"><i className="fa-solid fa-bars"></i></a>

      <ul className="nav user-menu">
        <li className="nav-item dropdown has-arrow main-drop">
          <a href="#" className="dropdown-toggle nav-link" data-bs-toggle="dropdown">
            <span className="userImg">
              <img src="/assets/img/profile-pic.jpg" alt="User-img" style={{ borderRadius: "50%", height: "30px", width: "30px", objectFit: "cover" }} />
              <span className="status online"></span></span>
            <span>{localStorage.getItem('PAY_USER_NAME')}</span>
          </a>
          <div className="dropdown-menu">
            <Link className="dropdown-item" to='/user/edit-profile'>My Profile</Link>
            <a className="dropdown-item" onClick={handleLogout}>Logout</a>
          </div>
        </li>
      </ul>

      <div className="dropdown mobile-user-menu">
        <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i
          className="fa-solid fa-ellipsis-vertical"></i></a>
        <div className="dropdown-menu dropdown-menu-right">
          <Link className="dropdown-item" to='/user/edit-profile'>My Profile</Link>
          <a className="dropdown-item" onClick={handleLogout}>Logout</a>
        </div>
      </div>
    </div>
  )
}

export default Header