import React, { useEffect, useMemo, useState } from 'react';
import Sidebar from '../Pages/Sidebar';
import Header from '../Pages/Header';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { PAY_URL } from '../../../BaseURL';
import toast from 'react-hot-toast';
import axios from 'axios';
import Loader from '../Pages/Loader';
import { Pagination } from '../Table';
import { Dropdown, Modal } from 'react-bootstrap';
import CopyRight from '../Pages/CopyRight';

const Employee = () => {

    const navigate = useNavigate();
    const [entity, setEntity] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [limit, setlimit] = useState(10);
    const [disable, setDisable] = useState(true);
    const [disable1, setDisable1] = useState(false);
    const [disable2, setDisable2] = useState(false);
    const [show, setShow] = useState(false);
    const [modalData, setModalData] = useState('')
    const [showDownload, setShowDownload] = useState(false);
    const [downloadVal, setDonwloadVal] = useState('')

    useEffect(() => {
        if (localStorage.getItem('PAY_USER_TOKEN') === null) {
            navigate("/user/login");
        }
        if (disable === true) {
            setEntity([]);
            getEmployee();
        }
        // eslint-disable-next-line
    }, [navigate, limit, currentPage, disable, search]);

    const commentsData = useMemo(() => {
        let computedComments = entity;
        return computedComments;
    }, [entity]);

    const getEmployee = () => {
        const myurl = `${PAY_URL}/user/get-admin-employee?page=${currentPage}&limit=${limit}&firm=${localStorage.getItem('PAY_USER_FIRM_ID')}&search=${search}`;
        axios({
            method: "get",
            url: myurl,
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('PAY_USER_TOKEN') },
        }).then(async (response) => {
            // console?.log("@@", response?.data);
            if (response?.data?.success) {
                const data = response.data.data;
                setEntity(data.employees);
                setTotalItems(data.totalEmployees);
                setCurrentPage(data.currentPage);
            }
            setDisable(false);
        }).catch((error) => {
            toast.error(error?.response?.data?.message || "Something went wrong");
            console?.log("Errors", error);
            setDisable(false);
        });
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setDisable(true);
    };

    const handleLimitChange = (value) => {
        setlimit(value);
        setCurrentPage(1);
        setDisable(true);
    };

    const handleDelete = (id, title) => {
        Swal.fire({
            title: `Are you sure want to delete ${title}?`,
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                const myurl = `${PAY_URL}/user/delete-employee`;
                var bodyFormData = new URLSearchParams();
                bodyFormData.append("id", id);
                axios({
                    method: "delete",
                    url: myurl,
                    data: bodyFormData,
                    headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('PAY_USER_TOKEN') },
                }).then((response) => {
                    // console.log(response.data, 'DEL')
                    if (response.data.success === true) {
                        toast.success(response?.data?.message);
                        setDisable(true);
                    } else {
                        toast.error(response?.data?.message);
                    }
                }).catch((error) => {
                    toast.error(error?.response?.data?.message || "Something went wrong");
                    console?.log("Errors", error);
                });
            }
        });
    }

    const handleRefresh = () => {
        setDisable(true);
    }

    const handleDownload = (id) => {
        if (id) {
            setDisable1(true);
            const myurl = `${PAY_URL}/user/download-form-11`;
            var bodyFormData = new URLSearchParams();
            bodyFormData.append("id", id);
            axios({
                method: "post",
                url: myurl,
                data: bodyFormData,
                headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('PAY_USER_TOKEN') },
            }).then((response) => {
                // console.log(response.data)
                if (response.data.success === true) {
                    const pdfUrl = response.data.data.file
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = pdfUrl;
                    a.download = 'formm11.pdf';
                    a.target = '_blank';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    // toast.success(response.data.message);
                }
                setDisable1(false);
            }).catch((error) => {
                console.log(error, '!!')
                setDisable1(false)
                toast.error(error.response.data.message);
            })
        } else {
            toast.error('Missing Id')
        }
    }

    const handleDownloadPolice = (id) => {
        setDisable2(true);
        const myurl = `${PAY_URL}/user/download-police-station`;
        var bodyFormData = new URLSearchParams();
        bodyFormData.append("id", id);

        axios({
            method: "post",
            url: myurl,
            data: bodyFormData,
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('PAY_USER_TOKEN') },
        }).then((response) => {
            // console.log(response.data)
            if (response.data.success === true) {
                const pdfUrl = response.data.data.file
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = pdfUrl;
                a.download = 'formA.pdf';
                a.target = '_blank';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                // toast.success(response.data.message);
            }
            setDisable2(false);
        }).catch((error) => {
            console.log(error, '!!')
            setDisable2(false)
        })
    }

    const handleGatePass = (gateId) => {
        const myurl = `${PAY_URL}/user/download-gatepass`;
        var bodyFormData = new URLSearchParams();
        bodyFormData.append("id", gateId);
        axios({
            method: "post",
            url: myurl,
            data: bodyFormData,
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('PAY_USER_TOKEN') },
        }).then((response) => {
            // console.log(response.data)
            if (response.data.success === true) {
                const pdfUrl = response.data.data.file
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = pdfUrl;
                a.download = 'gatepass.pdf';
                a.target = '_blank';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        }).catch((error) => {
            console.log(error, '!!')
        })
    }

    const openDownload = (id) => {
        setShowDownload(true);
        setDonwloadVal(id)
    }

    const closeDownload = () => {
        setShowDownload(false)
    }

    const handleClose = () => setShow(false);
    const handleShow = (img) => {
        setShow(true);
        setModalData(img)
    }

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const handleOpen = () => {
        setIsSidebarOpen(!isSidebarOpen)
    }

    const handleSearchChange = (value) => {
        setSearch(value);
        setCurrentPage(1);
        setDisable(true);
    };

    return (
        <div className={`main-wrapper ${isSidebarOpen ? 'slide-nav' : ''}`}>
            <Header handleOpen={handleOpen} />
            <Sidebar />

            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <h3 className="page-title user-title">Employee Management</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/user/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Employee Management</li>
                                </ul>
                            </div>
                            <div className="col-auto float-end ms-auto">
                                <Link to='/user/manage-employee' className="btn btn-success"><i className="fa-solid fa-plus"></i> Add Employee</Link>
                            </div>
                        </div>
                    </div>

                    <div className="row filter-row justify-content-end">
                        {/* <div className='col-sm-12 col-md-4 d-flex gap-2'>
                            <ImportFile />
                            <SampleFile />
                        </div> */}
                        <div className="col-sm-12 col-md-8">
                            <div className="row justify-content-end">
                                <div className="col-md-12 mb-2">
                                    <div className="justify-content-end d-flex align-center flex-wrap gap-2">
                                        <div className="seletePage d-flex gap-2 align-items-center ">
                                            <p className="mb-0">Rows per page:</p>
                                            <div className="d-flex align-items-center justify-content-center gap-2">
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        variant="none"
                                                        id="dropdown-basic"
                                                        style={{
                                                            cursor: "auto",
                                                            backgroundColor: "white",
                                                            borderColor: "#d5dbe0",
                                                            paddingBottom: "3px",
                                                            paddingTop: "3px",
                                                        }}
                                                        className='form-control selectWidth'
                                                    >
                                                        {limit}&nbsp;
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {limit !== 10 ? (
                                                            <>
                                                                <Dropdown.Item onClick={() => handleLimitChange(10)}>
                                                                    10
                                                                </Dropdown.Item>
                                                            </>
                                                        ) : null}

                                                        {limit !== 20 ? (
                                                            <>
                                                                <Dropdown.Item onClick={() => handleLimitChange(20)}>
                                                                    20
                                                                </Dropdown.Item>
                                                            </>
                                                        ) : null}

                                                        {limit !== 30 ? (
                                                            <>
                                                                <Dropdown.Item onClick={() => handleLimitChange(30)}>
                                                                    30
                                                                </Dropdown.Item>
                                                            </>
                                                        ) : null}

                                                        {limit !== 50 ? (
                                                            <>
                                                                <Dropdown.Item onClick={() => handleLimitChange(50)}>
                                                                    50
                                                                </Dropdown.Item>
                                                            </>
                                                        ) : null}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <div className="refreshPage">
                                                    <button className="btn btn-refresh" type='butotn' onClick={handleRefresh}>
                                                        <i className="fa-solid fa-arrows-rotate"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tableSearch">
                                            <form action="">
                                                <input
                                                    type="text"
                                                    placeholder="Search here"
                                                    onChange={(e) => handleSearchChange(e.target.value)}
                                                    className="form-control"
                                                />
                                                <span className="search">
                                                    <i className="fa-solid fa-magnifying-glass"></i>
                                                </span>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {disable === false ? (
                        <div className="card p-3 ">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="table-responsive">
                                        <table className="table table-striped custom-table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Sr.</th>
                                                    <th>Name</th>
                                                    <th>Id</th>
                                                    <th>Card Id</th>
                                                    <th>Aadhar Card</th>
                                                    <th>Designation</th>
                                                    <th>Week Off</th>
                                                    <th>Address</th>
                                                    <th>Mobile</th>
                                                    <th>Status</th>
                                                    <th>Download Form</th>
                                                    <th className="text-end">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {commentsData?.map((elem, i) =>
                                                    <tr key={elem?._id}>
                                                        <td>{(currentPage - 1) * limit + i + 1}</td>
                                                        {/* eslint-disable jsx-a11y/anchor-is-valid */}
                                                        <td><a onClick={() => handleShow(elem?.image)}>{elem?.full_name}</a></td>
                                                        <td>{elem?.employee_id}</td>
                                                        <td>{elem?.card_no ? elem?.card_no : '-'}</td>
                                                        <td>{elem?.adhar_no}</td>
                                                        <td>{elem?.designation?.name}</td>
                                                        <td>{elem?.holiday}</td>
                                                        <td>{elem?.city}-{elem?.pincode},{elem?.state}</td>
                                                        <td>{elem?.mobile_number}</td>
                                                        <td>
                                                            {elem.status === true ? (
                                                                <span className="badge text-bg-user">True</span>
                                                            ) : (
                                                                <span className="badge text-bg-danger">False</span>
                                                            )}
                                                        </td>
                                                        <td><span className='badge text-bg-user' style={{ fontSize: "15px", cursor: "pointer" }} onClick={() => openDownload(elem?._id)}><i className="fa-solid fa-file-arrow-down"></i></span></td>
                                                        <td className="text-end">
                                                            <div className="dropdown dropdown-action">
                                                                <a href="#" className="action-icon dropdown-toggle"
                                                                    data-bs-toggle="dropdown" aria-expanded="false"><i
                                                                        className="material-icons">more_vert</i></a>
                                                                <div className="dropdown-menu dropdown-menu-right">
                                                                    <button type='button' onClick={() => navigate('/user/manage-employee', { state: elem })} className="dropdown-item"><i
                                                                        className="fa-solid fa-pencil m-r-5"></i> Edit</button>
                                                                    <button type='button' className="dropdown-item" onClick={() => handleDelete(elem?._id, elem.full_name)}><i
                                                                        className="fa-regular fa-trash-can m-r-5"></i> Delete</button>
                                                                    <button type='button' className="dropdown-item" onClick={() => handleGatePass(elem?._id)}>
                                                                        <i className="fa-solid fa-download m-r-5"></i> Gatepass</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}

                                                {commentsData?.length === 0 ? (
                                                    <tr>
                                                        <td colspan="999">
                                                            <div className="no-table-data">
                                                                No Data Found!
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ) : null}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row align-center mt-3 user-account">
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-6">
                                            <div className="dataTables_info" id="DataTables_Table_0_info" role="status"
                                                aria-live="polite">Showing {Math.min(limit, totalItems)} from {totalItems} data</div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-6 ">
                                            <div className="dataTables_paginate paging_simple_numbers"
                                                id="DataTables_Table_0_paginate">
                                                <Pagination
                                                    total={totalItems}
                                                    itemsPerPage={limit}
                                                    currentPage={currentPage}
                                                    // onPageChange={(page) => setCurrentPage(page)}
                                                    onPageChange={handlePageChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : <Loader />}
                    <div className='card p-3'>
                        <h6><b>Note:</b> If you want to see the employee image, click on the employee name.</h6>
                    </div>

                    <CopyRight />
                </div>
            </div>


            <Modal show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div className='d-flex align-items-center justify-content-center'>
                        {modalData ? <img src={modalData} alt='employee-img' style={{ height: "300px", overflow: "hidden", borderRadius: "9px", objectFit: "cover" }} /> : <h3 className='p-4'>No Image Uploaded</h3>}
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={showDownload} onHide={closeDownload} centered>
                <Modal.Header className='d-flex justify-content-center'>
                    <h3 className=''>Available Downloads <img src='/assets/img/pdf.png' alt='pdf-icon' style={{ width: "auto", height: "28px" }} /></h3>
                </Modal.Header>
                <Modal.Body className="download-modal-body">
                    <div className="container">
                        <ul className="list-group mb-0">
                            <li className="list-group-item d-flex justify-content-between align-items-center mb-4">
                                <h4 className="m-0">Form A</h4>
                                <button className="btn btn-success" type='button' onClick={() => handleDownload(downloadVal)} disabled={disable1}>{disable1 ? 'Processing...' : 'Download'}</button>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                <h4 className="m-0">Police Station Form</h4>
                                <button className="btn btn-success" type='button' onClick={() => handleDownloadPolice(downloadVal)} disabled={disable2}>{disable2 ? 'Processing...' : 'Download'}</button>
                            </li>
                        </ul>
                    </div>
                </Modal.Body>
            </Modal>

        </div >
    )
}

export default Employee