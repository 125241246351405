import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const TooltipWrapper = ({ tooltipText, placement = 'top', children }) => {
    return (
        <OverlayTrigger
            placement={placement}
            overlay={<Tooltip id={`tooltip-${tooltipText}`}>{tooltipText}</Tooltip>}
        >
            {children}
        </OverlayTrigger>
    );
};

export default TooltipWrapper;
