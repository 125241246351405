import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Header from '../Pages/Header';
import Sidebar from '../Pages/Sidebar';
import { PAY_URL } from '../../../BaseURL';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Pagination, Search } from '../Table';
import { Collapse, Dropdown } from 'react-bootstrap';
import CopyRight from '../Pages/CopyRight';
import Loader from '../Pages/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDepartment } from '../../../Store/User/Department/UserDepartment';
import { getUserDesignation } from '../../../Store/User/Designation/getUserDesignation';
import { getUserBank } from '../../../Store/User/Bank/UserBank';
import { MultiSelect } from 'react-multi-select-component';
import { generateMonthName } from '../Pages/MonthData';
import ProcessLoader from '../Components/ProcessLoader';

const ReportList = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [entity, setEntity] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [limit, setlimit] = useState(10);
    const [disable, setDisable] = useState(true);
    const [loader, setLoader] = useState(false);
    const [month, setMonth] = useState('');

    const [selectedDepartment, setSelectedDepartment] = useState([]);
    const [selectedDesignation, setSelectedDesignation] = useState([]);
    const [selectedBank, setSelectedBank] = useState([]);
    const [minSalary, setMinSalary] = useState('');
    const [maxSalary, setMaxSalary] = useState('');
    const [openFilter, setOpenFilter] = useState(false);
    const [columnVisibility, setColumnVisibility] = useState({
        showExS: false,
        showDiff: false
    });


    useEffect(() => {
        if (localStorage.getItem('PAY_USER_TOKEN') === null) {
            navigate("/user/login");
        }
        if (disable === true) {
            getReport();
            setEntity([]);
        }
    }, [disable, navigate]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([
                    dispatch(getUserDepartment()),
                    dispatch(getUserDesignation()),
                    dispatch(getUserBank())
                ]);
            } catch (error) {
                console.log(error, 'Error fetching data');
            }
        }
        fetchData();
    }, [dispatch]);


    const deapartmentData = useSelector(state => state?.getUserDepartment?.user?.data);
    const designationData = useSelector(state => state?.getUserDesignation?.user?.data);
    const bankData = useSelector(state => state?.getUserBank?.user?.data);

    const commentsData = useMemo(() => {
        let computedComments = entity;

        if (search) {
            computedComments = computedComments.filter(
                (report) =>
                    report?.name?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    // report?.bank_account_no?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    report?.employee_id?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    report?.card_no?.toLowerCase()?.includes(search?.toLowerCase())
                // report?.adhar_no?.toString()?.toLowerCase()?.includes(search?.toLowerCase())
            );
        }

        if (month !== '') {
            computedComments = computedComments.filter((re) => parseInt(re.month) === parseInt(month));
        }

        if (selectedDesignation?.length > 0) {
            computedComments = computedComments.filter((report) => {
                return selectedDesignation.some(desig => desig.value === report.designation?._id);
            });
        }

        if (selectedDepartment?.length > 0) {
            console.log(selectedDepartment, 'SELECTED')
            computedComments = computedComments.filter((report) => {
                return selectedDepartment.some(depart => depart.value === report.department?._id);
            });
        }

        if (selectedBank?.length > 0) {
            computedComments = computedComments.filter((report) => {
                return selectedBank.some(ba => ba.value === report.bank_name?._id);
            });
        }

        if (minSalary !== '' && maxSalary !== '') {
            computedComments = computedComments.filter((re) => {
                const netSalary = re.net_salary;
                return netSalary >= minSalary && netSalary <= maxSalary;
            });
        }

        setTotalItems(computedComments.length);

        //Current Page slice
        return computedComments?.slice(
            (currentPage - 1) * limit,
            (currentPage - 1) * limit + limit
        );
    }, [currentPage, search, limit, entity, selectedDesignation, selectedDepartment, selectedBank, month, minSalary, maxSalary]);

    const getReport = () => {
        const myurl = `${PAY_URL}/user/get-employee-report`;
        axios({
            method: "get",
            url: myurl,
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('PAY_USER_TOKEN') },
        }).then(async (response) => {
            if (response?.data?.success === true) {
                const data = response?.data?.data
                // console.log(data, '#$#$')
                const filterData = data.filter((e) => e.firm_id?._id === localStorage.getItem('PAY_USER_FIRM_ID'));
                setEntity(filterData);
                setDisable(false);
            } else {
                toast.error(response?.data?.message || "Something went wrong");
                setDisable(false);
            }
        }).catch((error) => {
            toast.error(error.response?.data?.message || "Something went wrong");
            // console?.log("Errors", error);
            setDisable(false);
        });
    }

    const handleMonthChange = (event) => {
        const selectedMonth = event.target.value;
        setMonth(selectedMonth);
    };

    const downloadReport = () => {
        if (month) {
            setLoader(true);
            const myurl = `${PAY_URL}/user/get-salary-report`;
            const bodyFormData = new URLSearchParams();
            bodyFormData.append('month', month)
            axios({
                method: "post",
                url: myurl,
                data: bodyFormData,
                headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('PAY_USER_TOKEN') },
            }).then((response) => {
                if (response.data.success === true) {
                    const fileUrl = response.data.data;
                    const link = document.createElement('a');
                    link.href = fileUrl;
                    link.setAttribute('download', `Salary-Report.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    toast.success(response?.data.message);
                }
                setLoader(false);
            }).catch((error) => {
                console.log(error, '!!');
                toast.error(error?.response?.data.message);
                setLoader(false);
            });
        } else {
            toast.error('Please select the month');
        }
    }

    const handleDepartment = (e) => {
        setSelectedDepartment(e);
    }
    const handleDesignation = (e) => {
        setSelectedDesignation(e)
    }
    const handleBank = (e) => {
        setSelectedBank(e)
    }

    const handleRefresh = () => {
        setMaxSalary('');
        setMinSalary('');
        setSelectedDepartment([]);
        setSelectedDesignation([]);
        setSelectedBank([]);
        setDisable(true);
    }

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const handleOpen = () => {
        setIsSidebarOpen(!isSidebarOpen)
    }

    return (
        <div className={`main-wrapper ${isSidebarOpen ? 'slide-nav' : ''}`}>
            <Header handleOpen={handleOpen} />
            <Sidebar />

            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <h3 className="page-title user-title">Report Management</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/user/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Report Management</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row filter-row justify-content-end">
                        <div className='col-md-4 col-lg-4 col-sm-12'>
                            <div className="row">
                                <div className="col-md-12 mb-2">

                                    <select className="form-select form-control" value={month} onChange={handleMonthChange}
                                        aria-label="Default select example">
                                        <option value=''>Select Month</option>
                                        <option value='1'>January</option>
                                        <option value='2'>February</option>
                                        <option value='3'>March</option>
                                        <option value='4'>April</option>
                                        <option value='5'>May</option>
                                        <option value='6'>June</option>
                                        <option value='7'>July</option>
                                        <option value='8'>August</option>
                                        <option value='9'>September</option>
                                        <option value='10'>October</option>
                                        <option value='11'>November</option>
                                        <option value='12'>December</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-8">
                            <div className="row justify-content-end">
                                <div className="col-md-12 mb-2">
                                    <div className="justify-content-end d-flex align-center flex-wrap gap-2">
                                        <div className="seletePage d-flex gap-2 align-items-center ">
                                            <p className="mb-0">Rows per page:</p>
                                            <div className="d-flex align-items-center justify-content-center gap-2">
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        variant="none"
                                                        id="dropdown-basic"
                                                        style={{
                                                            cursor: "auto",
                                                            backgroundColor: "white",
                                                            borderColor: "#d5dbe0",
                                                            paddingBottom: "3px",
                                                            paddingTop: "3px",
                                                        }}
                                                        className='form-control selectWidth'
                                                    >
                                                        {limit}&nbsp;
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {limit !== 10 ? (
                                                            <>
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setlimit(10);
                                                                    }}
                                                                >
                                                                    10
                                                                </Dropdown.Item>
                                                            </>
                                                        ) : null}

                                                        {limit !== 20 ? (
                                                            <>
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setlimit(20);
                                                                    }}
                                                                >
                                                                    20
                                                                </Dropdown.Item>
                                                            </>
                                                        ) : null}

                                                        {limit !== 30 ? (
                                                            <>
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setlimit(30);
                                                                    }}
                                                                >
                                                                    30
                                                                </Dropdown.Item>
                                                            </>
                                                        ) : null}

                                                        {limit !== 50 ? (
                                                            <>
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setlimit(50);
                                                                    }}
                                                                >
                                                                    50
                                                                </Dropdown.Item>
                                                            </>
                                                        ) : null}

                                                        {limit !== 100 ? (
                                                            <>
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setlimit(100);
                                                                    }}
                                                                >
                                                                    100
                                                                </Dropdown.Item>
                                                            </>
                                                        ) : null}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <div className="refreshPage">
                                                    <button className="btn btn-refresh" type='butotn' onClick={handleRefresh}>
                                                        <i className="fa-solid fa-arrows-rotate"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="seletePage d-flex gap-2 align-items-center ">
                                                <div className="d-flex align-items-center justify-content-center gap-2">
                                                    <div className="refreshPage">
                                                        <button className="btn btn-refresh" type='button' onClick={() => setOpenFilter(!openFilter)}
                                                            aria-controls="example-collapse-text"
                                                            aria-expanded={openFilter}>
                                                            <i className="fa-solid fa-filter"></i>
                                                        </button>
                                                    </div>
                                                    <div className="refreshPage">
                                                        <button className="btn btn-refresh" type='button' onClick={downloadReport}>
                                                            {loader ? <ProcessLoader /> : <i className="fa-solid fa-download"></i>}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tableSearch">
                                            <form action="">
                                                <Search
                                                    onSearch={(value) => {
                                                        setSearch(value);
                                                        setCurrentPage(1);
                                                    }} />
                                                <span className="search">
                                                    <i className="fa-solid fa-magnifying-glass"></i>
                                                </span>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Collapse in={openFilter} >
                        <div className='row filter-row mt-2'>
                            <div className='col-md-3 col-lg-3 col-sm-12'>
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        {deapartmentData && deapartmentData.length > 0 ? (
                                            <>
                                                <label htmlFor="othour" className="form-label">Department</label>

                                                <MultiSelect
                                                    options={deapartmentData.map(e => ({ label: e.name, value: e._id }))}
                                                    value={selectedDepartment}
                                                    onChange={handleDepartment}
                                                    labelledBy="Select Department"
                                                />
                                            </>
                                        ) : (
                                            <p>Loading departments...</p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-3 col-lg-3 col-sm-12'>
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        {designationData && designationData?.length > 0 ? (
                                            <>
                                                <label htmlFor="othour" className="form-label">Designation</label>
                                                <MultiSelect
                                                    options={designationData?.map(e => ({ label: e.name, value: e._id }))}
                                                    value={selectedDesignation}
                                                    onChange={handleDesignation}
                                                    labelledBy="Select Designation"
                                                />
                                            </>
                                        ) : (
                                            <p>Loading designation...</p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-3 col-lg-3 col-sm-12'>
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        {bankData && bankData?.length > 0 ? (
                                            <>
                                                <label htmlFor="othour" className="form-label">Bank</label>
                                                <MultiSelect
                                                    options={bankData?.map(e => ({ label: e.name, value: e._id }))}
                                                    value={selectedBank}
                                                    onChange={handleBank}
                                                    labelledBy="Select Bank"
                                                />
                                            </>
                                        ) : (
                                            <p>Loading bank...</p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className='col-sm-12 col-md-3 col-lg-3'>
                                <div className="row justify-content-start">
                                    <div className="col-md-6 mb-2">
                                        <label htmlFor="othour" className="form-label">Min</label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            id="minSalary"
                                            value={minSalary}
                                            onChange={(e) => setMinSalary(e.target.value)}
                                            placeholder='Min'
                                        />
                                    </div>

                                    <div className='col-md-6 mb-2'>
                                        <label htmlFor="othour" className="form-label">Max</label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            id="maxSalary"
                                            value={maxSalary}
                                            onChange={(e) => setMaxSalary(e.target.value)}
                                            placeholder='Max'
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='col-12'>
                                <div class="card mb-3">
                                    <div class="card-body">
                                        <h5 class="card-title" style={{ fontSize: "16px" }}>Column On/Off</h5>
                                        <div className='row'>
                                            <div className='col-sm-12 col-md-2 col-lg-2'>
                                                <label>
                                                    <input className='form-check-input mx-2'
                                                        type="checkbox"
                                                        checked={columnVisibility.showExS}
                                                        onChange={() => setColumnVisibility(prevState => ({ ...prevState, showExS: !prevState.showExS }))}
                                                    />
                                                    Show Ex. S.
                                                </label>
                                            </div>
                                            <div className='col-sm-12 col-md-2 col-lg-2'>
                                                <label>
                                                    <input className='form-check-input mx-2'
                                                        type="checkbox"
                                                        checked={columnVisibility.showDiff}
                                                        onChange={() => setColumnVisibility(prevState => ({ ...prevState, showDiff: !prevState.showDiff }))}
                                                    />
                                                    Show Diff.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Collapse>

                    {disable === false ? (
                        <div className="card p-3 ">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="table-responsive">
                                        <table className="table table-striped custom-table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Sr.</th>
                                                    <th>Month</th>
                                                    <th>Id</th>
                                                    <th>Aadhar Card</th>
                                                    <th>Card No.</th>
                                                    <th>Employee</th>
                                                    {/* <th>J.Date</th> */}
                                                    <th>Department</th>
                                                    <th>Designation</th>
                                                    {/* <th>Shift</th> */}
                                                    <th>Bank</th>
                                                    <th>A/C No.</th>
                                                    <th>IFSC</th>
                                                    <th>W.D</th>
                                                    <th>W.Hrs</th>
                                                    <th>Pr.D.</th>
                                                    <th>OTD.</th>
                                                    <th>T.OTH.</th>
                                                    <th>TD</th>
                                                    <th>TS</th>
                                                    <th>PerDayS</th>
                                                    <th>PerH.OTS</th>
                                                    <th>Pre.DS</th>
                                                    <th>OTS</th>
                                                    <th>TExtraE</th>
                                                    <th>GS</th>
                                                    <th>LoanD</th>
                                                    <th>OtherD</th>
                                                    <th>PF</th>
                                                    <th>ESI</th>
                                                    <th>PT</th>
                                                    <th>TDS</th>
                                                    <th>LWF</th>
                                                    <th>TD</th>
                                                    <th>NetS.</th>
                                                    {/* <th>Ex. S.</th>
                                                    <th>Diff.</th> */}
                                                    {columnVisibility.showExS && <th>Ex. S.</th>}
                                                    {columnVisibility.showDiff && <th>Diff.</th>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {commentsData?.map((elem, i) =>
                                                    <tr key={elem?._id}>
                                                        <td>{(currentPage - 1) * limit + i + 1}</td>
                                                        <td>{generateMonthName(elem?.month)}</td>
                                                        <td>{elem?.employee_id}</td>
                                                        <td>{elem?.adhar_no}</td>
                                                        <td>{elem?.card_no || '-'}</td>
                                                        <td>{elem?.name}</td>
                                                        {/* <td>{moment(elem?.joining_date).format('YYYY-MM-DD')}</td> */}
                                                        <td>{elem?.department?.name}</td>
                                                        <td>{elem?.designation?.name}</td>
                                                        {/* <td>{elem?.shift?.name}</td> */}
                                                        <td>{elem?.bank_name?.name}</td>
                                                        <td>{elem?.bank_account_no}</td>
                                                        <td>{elem?.bank_account_ifsc}</td>
                                                        <td>{elem?.working_day}</td>
                                                        <td>{elem?.working_hour}</td>
                                                        <td>{elem?.total_present_Day}</td>
                                                        <td>{elem?.total_ot_Day}</td>
                                                        <td>{elem?.total_ot_hour}</td>
                                                        <td>{elem?.total_days}</td>
                                                        <td>{elem?.total_salary}</td>
                                                        <td>{elem?.perday_salary}</td>
                                                        <td>{elem?.per_hour_ot_salary}</td>
                                                        <td>{elem?.present_day_salary}</td>
                                                        <td>{elem?.ot_salary}</td>
                                                        <td>{elem?.total_extra_earning}</td>
                                                        <td>{elem?.gross_salary}</td>
                                                        <td>{elem?.loan_deduction}</td>
                                                        <td>{elem?.total_other_deduction}</td>
                                                        <td>{elem?.pf}</td>
                                                        <td>{elem?.esi}</td>
                                                        <td>{elem?.pt}</td>
                                                        <td>{elem?.tds}</td>
                                                        <td>{elem?.lwf}</td>
                                                        <td>{elem?.total_deduction}</td>
                                                        <td>{elem?.net_salary}</td>
                                                        {/* <td>{(elem?.excel_net_salary)?.toFixed(2)}</td>
                                                        <td>{(elem?.net_difference)?.toFixed(2)}</td> */}
                                                        {columnVisibility.showExS && <td>{(elem?.excel_net_salary)?.toFixed(2)}</td>}
                                                        {columnVisibility.showDiff && <td>{(elem?.net_difference)?.toFixed(2)}</td>}

                                                    </tr>
                                                )}

                                                <tr>
                                                    <td colSpan={20}></td>
                                                    <td>
                                                        {commentsData?.reduce((total, elem) => total + (elem?.present_day_salary || 0), 0)}
                                                    </td>
                                                    <td>
                                                        {commentsData?.reduce((total, elem) => total + (elem?.ot_salary || 0), 0)}
                                                    </td>
                                                    <td>
                                                        {commentsData?.reduce((total, elem) => total + (elem?.total_extra_earning || 0), 0)}
                                                    </td>
                                                    <td>
                                                        {commentsData?.reduce((total, elem) => total + (elem?.gross_salary || 0), 0)}
                                                    </td>
                                                    <td>
                                                        {commentsData?.reduce((total, elem) => total + (elem?.loan_deduction || 0), 0)}
                                                    </td>
                                                    <td>
                                                        {commentsData?.reduce((total, elem) => total + (elem?.total_other_deduction || 0), 0)}
                                                    </td>
                                                    <td>
                                                        {commentsData?.reduce((total, elem) => total + (elem?.pf || 0), 0)}
                                                    </td>
                                                    <td>
                                                        {commentsData?.reduce((total, elem) => total + (elem?.esi || 0), 0)}
                                                    </td>
                                                    <td>
                                                        {commentsData?.reduce((total, elem) => total + (elem?.pt || 0), 0)}
                                                    </td>
                                                    <td>
                                                        {commentsData?.reduce((total, elem) => total + (elem?.tds || 0), 0)}
                                                    </td>
                                                    <td>
                                                        {commentsData?.reduce((total, elem) => total + (elem?.lwf || 0), 0)}
                                                    </td>
                                                    <td>
                                                        {commentsData?.reduce((total, elem) => total + (elem?.total_deduction || 0), 0)}
                                                    </td>
                                                    <td>
                                                        {commentsData?.reduce((total, elem) => total + (elem?.net_salary || 0), 0)}
                                                    </td>
                                                </tr>

                                                {commentsData?.length === 0 ? (
                                                    <tr>
                                                        <td colspan="999">
                                                            <div className="no-table-data">
                                                                No Data Found!
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ) : null}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row align-center mt-3 user-account">
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-6">
                                            <div className="dataTables_info" id="DataTables_Table_0_info" role="status"
                                                aria-live="polite">Showing {Math.min(limit, totalItems)} from {totalItems} data</div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-6 ">
                                            <div className="dataTables_paginate paging_simple_numbers"
                                                id="DataTables_Table_0_paginate">
                                                <Pagination
                                                    total={totalItems}
                                                    itemsPerPage={limit}
                                                    currentPage={currentPage}
                                                    onPageChange={(page) => setCurrentPage(page)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : <Loader />}

                    <CopyRight />

                </div>
            </div>
        </div>
    )
}

export default ReportList