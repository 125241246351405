import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PAY_URL } from '../../../../BaseURL';
import axios from 'axios';
import toast from 'react-hot-toast';
import Header from '../../Pages/Header';
import Sidebar from '../../Pages/Sidebar';
import CopyRight from '../../Pages/CopyRight';
import { MultiSelect } from 'react-multi-select-component';

const ManageMonthly = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState({});
  const [disable, setDisable] = useState(false);
  const [selectValue, setSelectValue] = useState('');

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;

  const [month, setMonth] = useState({
    month: currentMonth,
    use_leave: 0,
    week_off_present: 0,
    ot_day: 0,
    ot_hour: 0,
    remark: "",
    cl_day: "",
    ph_day: "",
    actual_present_day: "",
    present_day: "",
    sunday_present: "",
    full_night_present: "",
    bonus_percent: "",
  });

  const [employeeData, setEmployeeData] = useState([]);
  const [allData, setAllData] = useState({
    designation: "",
    department: "",
    card_no: "",
    employee_id: ""
  });
  const [disableMore, setDisableMore] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState([]);

  const data = location.state;

  useEffect(() => {
    if (localStorage.getItem('PAY_USER_TOKEN') === null) {
      navigate("/user/login");
    }
    getEmployee();
  }, [navigate, selectedEmployee]);

  useEffect(() => {
    if (location.state) {
      setMonth({
        month: location.state?.month,
        use_leave: location.state.use_leave,
        week_off_present: location.state.week_off_present,
        ot_day: location.state.ot_day,
        ot_hour: location.state.ot_hour,
        remark: location.state.remark,
        actual_present_day: location.state.actual_present_day,
        cl_day: location.state.cl_day,
        ph_day: location.state.ph_day,
        present_day: location.state.present_day,
        sunday_present: location.state.sunday_present,
        full_night_present: location.state.full_night_present,
        bonus_percent: location.state.bonus_percent,
      });
      setSelectValue(location.state.status);
      setSelectedEmployee([{
        label: `${location.state.employee.full_name} - ${location.state.employee.employee_id} - 
        ${location.state.employee?.adhar_no}`, value: location.state.employee._id
      }]);
    }

  }, [location.state]);

  const getEmployee = () => {
    const myurl = `${PAY_URL}/user/get-all-employee`;
    axios({
      method: "get",
      url: myurl,
      headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('PAY_USER_TOKEN') },
    }).then(async (response) => {

      if (response?.data?.success) {
        const resData = response?.data?.data
        const filteredData = resData?.filter(e => e.firm_id === localStorage.getItem('PAY_USER_FIRM_ID'));
        setEmployeeData(filteredData);
      } else {
        toast.error("Something went wrong");
      }
    }).catch((error) => {
      toast.error("Something went wrong");
      console?.log("Errors", error);
    });
  }

  useEffect(() => {
    const selectEmployee = employeeData?.find(emp => emp?._id === selectedEmployee[0]?.value);

    setAllData({
      designation: selectEmployee?.designation?.name,
      department: selectEmployee?.salary_id?.department?.name,
      card_no: selectEmployee?.card_no,
      employee_id: selectEmployee?.employee_id
    });
  }, [employeeData, selectedEmployee]);

  const handleRadioChange = (event) => {
    setSelectValue(event.target.checked);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedMonth = { ...month, [name]: value };

    let actualPresentDay = parseFloat(updatedMonth.actual_present_day) || 0;
    let clDay = parseFloat(updatedMonth.cl_day) || 0;
    let phDay = parseFloat(updatedMonth.ph_day) || 0;

    const totalPresentDays = actualPresentDay + clDay + phDay;
    updatedMonth.present_day = parseFloat(totalPresentDays);
    setMonth(updatedMonth);
  };

  const selectedEmpChange = (e) => {
    if (e.length > 1) {
      e = e.slice(-1);
    }
    setSelectedEmployee(e);
  }

  const handleSubmit = async (more) => {
    if (validation()) {

      try {
        const form = new URLSearchParams();
        form.append('firm_id', localStorage.getItem('PAY_USER_FIRM_ID'));
        // form.append('employee', otherEmpData?.employee);
        form.append('employee', selectedEmployee[0].value);
        form.append('month', month?.month);
        form.append('year_id', localStorage.getItem('PAY_USER_YEAR_ID'));

        const resSalary = await axios({
          method: 'post',
          url: `${PAY_URL}/user/employee-salary`,
          data: form,
          headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: "Bearer " + localStorage.getItem('PAY_USER_TOKEN') }
        })

        if (resSalary.data.success) {
          setDisable(true);
          if (more === 'true') {
            setDisableMore(true);
          }
          const bodyFormData = new URLSearchParams();
          bodyFormData.append('firm_id', localStorage.getItem('PAY_USER_FIRM_ID'));
          bodyFormData.append('year_id', localStorage.getItem('PAY_USER_YEAR_ID'));
          // bodyFormData.append('employee', otherEmpData?.employee);
          bodyFormData.append('employee', selectedEmployee[0].value);
          bodyFormData.append('month', month?.month);
          bodyFormData.append('present_day', month?.present_day);
          bodyFormData.append('cl_day', month.cl_day ? month.cl_day : 0)
          bodyFormData.append('ph_day', month.ph_day ? month.ph_day : 0)
          bodyFormData.append('actual_present_day', month.actual_present_day)
          bodyFormData.append('use_leave', month?.use_leave === null ? 0 : month?.use_leave);
          bodyFormData.append('week_off_present', month?.week_off_present === null ? 0 : month?.week_off_present);
          bodyFormData.append('ot_day', month?.ot_day === null || month?.ot_day === undefined ? 0 : month?.ot_day);
          bodyFormData.append('ot_hour', month?.ot_hour === null || month?.ot_hour === undefined ? 0 : month?.ot_hour);
          bodyFormData.append('remark', month?.remark);
          bodyFormData.append('sunday_present', month.sunday_present ? month.sunday_present : 0);
          bodyFormData.append('full_night_present', month.full_night_present ? month.full_night_present : 0);
          bodyFormData.append('bonus_percent', month.bonus_percent ? month.bonus_percent : 0);
          if (data?._id) {
            bodyFormData.append('id', data?._id);
            bodyFormData?.append('status', selectValue);
          }

          axios({
            method: 'post',
            url: `${PAY_URL}/user/manage-monthly-attendance`,
            data: bodyFormData,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: "Bearer " + localStorage.getItem('PAY_USER_TOKEN') }
          }).then((response) => {
            // console.log(response?.data);
            if (response.data.success === true) {
              if (more !== 'true') {
                navigate('/user/monthly-attendance-management');
              }
              toast.success(response.data.message)
              handleReset();
            }
            setDisable(false);
            if (more === 'true') {
              setDisableMore(false);
            }
          }).catch((error) => {
            console.log(error);
            toast.error(error.response.data.message);
            setDisable(false);
            if (more === 'true') {
              setDisableMore(false);
            }
          })
        } else {
          toast.error(resSalary.data.message);
        }
      } catch (error) {
        console.log(error, 'ERROR')
        toast.error(error?.response?.data?.message || 'Something went wrong');
      }
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
    }
  }

  const validation = () => {
    let isValid = true;
    let err = {};

    if (!month?.month) {
      isValid = false;
      err['month_err'] = "Please select month"
    }
    if (!selectedEmployee?.length > 0) {
      isValid = false;
      toast.error("Please select employee");
    }
    // if (!month?.present_day) {
    //   isValid = false;
    //   err['present_day_err'] = "Please enter present days"
    // }

    if (!month.actual_present_day) {
      isValid = false;
      err['actual_present_day_err'] = "Please enter present days";
    } else if (month.actual_present_day < 0) {
      isValid = false;
      err['actual_present_day_err'] = "Present days cannot be negative";
    }

    // if (!month.cl_day) {
    //   isValid = false;
    //   err['cl_day_err'] = "Please enter cl days";
    // } else if (month.cl_day < 0) {
    //   isValid = false;
    //   err['cl_day_err'] = "CL days cannot be negative";
    // }

    // if (!month.ph_day) {
    //   isValid = false;
    //   err['ph_day_err'] = "Please enter ph days";
    // } else if (month.ph_day < 0) {
    //   isValid = false;
    //   err['ph_day_err'] = "PH days cannot be negative";
    // }

    if (month.cl_day) {
      if (month.cl_day < 0) {
        isValid = false;
        err['cl_day_err'] = "CL days cannot be negative";
      }
    }

    if (month.ph_day) {
      if (month.ph_day < 0) {
        isValid = false;
        err['ph_day_err'] = "PH days cannot be negative";
      }
    }

    setError(err);
    return isValid
  }

  const handleReset = () => {
    setMonth({
      month: currentMonth,
      use_leave: 0,
      week_off_present: 0,
      ot_day: 0,
      ot_hour: 0,
      remark: "",
      cl_day: "",
      ph_day: "",
      actual_present_day: "",
      present_day: "",
    });
  }

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const handleOpen = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }

  return (
    <div className={`main-wrapper ${isSidebarOpen ? 'slide-nav' : ''}`}>
      <Header handleOpen={handleOpen} />
      <Sidebar />

      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title user-title">{data?._id ? 'Edit' : 'Add'} Monthly Attendance</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/user/dashboard">Dashboard</Link></li>
                  <li className="breadcrumb-item"><Link to='/user/monthly-attendance-management'>Monthly Attendance Management</Link></li>
                  <li className="breadcrumb-item active">{data?._id ? 'Edit' : 'Add'} Monthly Attendance</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="card p-3 basicform">
            <div className=" mb-3 titleForm border-bottom">
              <h4 className="">Monthly Attendance</h4>
            </div>

            <div className="row card_id">

              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="month" className="form-label">Month <span className='text-danger'> *</span></label>
                    <select className="form-select form-control" aria-label="Default select example"
                      onChange={handleChange} name='month' value={month?.month} disabled={data?._id ? 'disabled' : ''}>
                      <option selected>Select Month</option>
                      <option value="1">January</option>
                      <option value="2">February</option>
                      <option value="3">March</option>
                      <option value="4">April</option>
                      <option value="5">May</option>
                      <option value="6">June</option>
                      <option value="7">July</option>
                      <option value="8">August</option>
                      <option value="9">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </select>
                    <div className='error'>{error?.month_err}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row card_id'>

              <div className="col-md-8 col-lg-8 col-xxl-8 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="workDepartment" className="form-label">Employee - Id - Card Id - Aadhar card <span className='text-danger'> *</span></label>
                    {/* <MultiSelect
                      options={employeeData?.map(e => ({ label: `${e.full_name} - ${e?.employee_id} - ${e?.card_no ? e?.card_no : '-'} - ${e?.adhar_no}`, value: e._id }))}
                      onChange={selectedEmpChange}
                      // isLoading={true}
                      hasSelectAll={false}
                      value={selectedEmployee}
                      labelledBy="Select Employee"
                    /> */}
                    <MultiSelect
                      options={employeeData?.map(e => ({
                        label: `${e.full_name} - ${e.employee_id} - ${e.card_no ? e.card_no : '-'} - ${e.adhar_no}`,
                        value: e._id,
                        searchableLabel: `${e.full_name} ${e.employee_id} ${e.card_no || ''}`
                      }))}
                      onChange={selectedEmpChange}
                      hasSelectAll={false}
                      value={selectedEmployee}
                      labelledBy="Select Employee"
                      filterOptions={(options, filter) =>
                        options.filter(({ searchableLabel }) =>
                          searchableLabel.toLowerCase().includes(filter.toLowerCase())
                        )
                      }
                    />
                    <div className='error'>{error?.employee_err}</div>
                  </div>
                </div>
              </div>

            </div>

            <div className='row card_id'>
              {selectedEmployee?.length > 0 ? (
                <>
                  <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                    <div className="cardNum">
                      <div className="mb-3">
                        <label className="form-label">Designation</label>
                        <input className="form-control" value={allData?.designation} disabled />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                    <div className="cardNum">
                      <div className="mb-3">
                        <label className="form-label">Department</label>
                        <input className="form-control" value={allData?.department} disabled />
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>

            <div className='row'>
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label className="form-label">Present Days<span className='text-danger'> *</span></label>
                    <input type="number" className="form-control"
                      onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown}
                      onChange={handleChange} name='actual_present_day'
                      value={month?.actual_present_day}
                    />
                    <div className='error'>{error?.actual_present_day_err}</div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label className="form-label">CL Days</label>
                    <input type="number" className="form-control"
                      onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown}
                      onChange={handleChange} name='cl_day'
                      value={month?.cl_day}
                    />
                    <div className='error'>{error?.cl_day_err}</div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label className="form-label">PH Days</label>
                    <input type="number" className="form-control"
                      onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown}
                      onChange={handleChange} name='ph_day'
                      value={month?.ph_day}
                    />
                    <div className='error'>{error?.ph_day_err}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className='row card_id'>
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label className="form-label">Total Present Days <span className='text-danger'> *</span></label>
                    <input type="number" className="form-control"
                      onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown}
                      onChange={handleChange} name='present_day'
                      value={month?.present_day} disabled />
                    <div className='error'>{error?.present_day_err}</div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="weekOffPresent" className="form-label">Week Off Present </label>
                    <input type="number" className="form-control" id="weekOffPresent"
                      onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown}
                      name='week_off_present' value={month?.week_off_present} onChange={handleChange} />
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="useLeave" className="form-label">Use Leave </label>
                    <input type="number" className="form-control" id="useLeave"
                      onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown}
                      onChange={handleChange} name='use_leave' value={month?.use_leave} />
                  </div>
                </div>
              </div>
            </div>

            <div className='row card_id'>
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="weekOffPresent" className="form-label">Sunday Present</label>
                    <input type="number" className="form-control" id="sunday_present"
                      onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown}
                      name='sunday_present' value={month?.sunday_present} onChange={handleChange} />
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="weekOffPresent" className="form-label">Full Night Present</label>
                    <input type="number" className="form-control" id="full_night_present"
                      onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown}
                      name='full_night_present' value={month?.full_night_present} onChange={handleChange} />
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="weekOffPresent" className="form-label">Bonus(%)</label>
                    <input type="number" className="form-control" id="bonus_percent"
                      onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown}
                      name='bonus_percent' value={month?.bonus_percent} onChange={handleChange} />
                  </div>
                </div>
              </div>
            </div>

            <div className="row card_id">

              {/* <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="otDay" className="form-label">Ot Day </label>
                    <input type="number" className="form-control" id="otDay"
                      onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown}
                      onChange={handleChange} name='ot_day' value={month?.ot_day} />
                  </div>
                </div>
              </div> */}

              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="OtHour" className="form-label">Ot Hour</label>
                    <input type="number" className="form-control" id="OtHour"
                      onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown}
                      onChange={handleChange} name='ot_hour' value={month?.ot_hour} />

                  </div>
                </div>
              </div>

              <div className="col-md-8 col-lg-8 col-xxl-8 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="remark" className="form-label">Remarks</label>
                    <textarea className="form-control" placeholder="Comment here...." id="remark" rows={1}
                      onChange={handleChange} name='remark' value={month?.remark}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div className="row card_id">

              {data?._id ? (
                <div className='col-md-4 col-lg-4 col-xxl-4 col-sm-12'>
                  <div className="cardNum">
                    <div className="mb-3">
                      <label htmlFor="fileUpload" className="form-label">Status</label>
                      <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" onChange={handleRadioChange} checked={selectValue} />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="row card_id mt-4 sm-mt-3">
              <div className=" col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3 d-flex gap-2">
                    <button className="btn btn-success w-50" type='button' onClick={handleSubmit} disabled={disable}>{disable ? "Processing..." : (data?._id ? 'Update' : 'Submit')}</button>
                    <button className="btn btn-outline-secondary w-50" type='button' onClick={handleReset}>Reset</button>
                    {!data?._id ? <button type="button" className="btn btn-outline-success w-50" onClick={() => handleSubmit('true')} disabled={disableMore}>{disableMore ? "Processing..." : 'Add More'}</button> : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CopyRight />
      </div>
    </div>
  )
}

export default ManageMonthly