import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { userForgetPassword } from '../../../Store/User/Login/ForgetPassword';

const ForgetPassword = () => {

    const [email, setEmail] = useState('');
    const [err, setErr] = useState('');
    const [disable, setDisable] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSubmit = (e) => {
        e.preventDefault();
        setDisable(true);
        if (email) {
            dispatch(userForgetPassword(email))
                .then((response) => {
                    // console.log(response, "RES");
                    if (response.payload.success === true) {
                        navigate('/user/otp-verification')
                        localStorage.setItem('U_FORGET_EMAIL', email);
                        setEmail('');
                    }
                    setDisable(false);
                    setErr('');
                })

        } else {
            setErr('Please enter email');
            localStorage.removeItem('U_FORGET_EMAIL');
            setDisable(false);
        }
    }

    return (
        <div className="account-page login-page">
            <div className="frame-login"></div>
            <div className="container">
                <div className="row login-Row">
                    <div className="col-md-6 col-lg-5 col-sm-12">
                        <div className="card p-3 cardTransparent">
                            <div className="logoLogin d-flex align-center justify-content-center mb-3">
                                <img src="/assets/img/ColorLogo.svg" width="150px" height="auto" alt="Logo" />
                            </div>
                            <h3 className="account-title mt-4 mb-4 text-center">Forgot Password</h3>

                            <form className="row g-3" onSubmit={handleSubmit}>
                                <div className="col-md-12">
                                    <label for="inputEmail4" className="form-label">Email Address</label>
                                    <input type="email" className="form-control" id="inputEmail4"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <div className='error'>{err}</div>
                                </div>

                                <div className="input-block mt-4 text-center">
                                    <button className="btn btn-success account-btn w-100" type="submit" disabled={disable}>{disable ? 'Processing...' : 'Send reset otp'}</button>
                                </div>

                                <div className="account-footer mt-0">
                                    <p className='text-center'><span className='user-account'> <Link to="/user/login">Back to login</Link></span></p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgetPassword