import { configureStore } from '@reduxjs/toolkit';

import adminLoginSlice from '../Store/Admin/Login/LoginSlice';
import adminForgetPasswordSlice from '../Store/Admin/Login/ForgetPasswordSlice';
import adminOtpSlice from '../Store/Admin/Login/OtpSlice';

import getBankSlice from '../Store/Admin/Bank/BankList';
import getAdminBankSlice from '../Store/Admin/Bank/AdminBankList';
import addBankSlice from '../Store/Admin/Bank/AddBank';
import editBankSlice from '../Store/Admin/Bank/EditBank';
import deleteBankSlice from '../Store/Admin/Bank/DeleteBank';

import getGroupSlice from '../Store/Admin/Group/Group';
import getAdminGroupSlice from '../Store/Admin/Group/AdminGroupList';
import addGroupSlice from '../Store/Admin/Group/AddGroup';

import getAdminDepartmentSlice from '../Store/Admin/Department/AdminDepartmentList';
import getDepartmentSlice from '../Store/Admin/Department/getDepartment';
import addDepartmentSlice from '../Store/Admin/Department/AddDepartment';

import getDesignationSlice from '../Store/Admin/Designation/getDesignation';
import getAdminDesignationSlice from '../Store/Admin/Designation/getAdminDesignation';
import addDesignationSlice from '../Store/Admin/Designation/AddDesignation';

import getShiftSlice from '../Store/Admin/Shift/getShift';
import addShiftSlice from '../Store/Admin/Shift/AddShift';
import getAdminShiftSlice from '../Store/Admin/Shift/getAdminShift';

import getAuthPersonSlice from '../Store/Admin/AuthPerson/getAuthPerson';
import getAdminAuthSlice from './Admin/AuthPerson/getAdminAuth';
import addAuthPersonSlice from '../Store/Admin/AuthPerson/AddAuthPerson';

import getYearSlice from '../Store/Admin/Year/getYear';
import getAdminYearSlice from '../Store/Admin/Year/getAdminYear';
import addYearSlice from '../Store/Admin/Year/AddYear';


import getAdminStoreSlice from './Admin/Store/Store/GetAdminStore';
import getStoreSlice from '../Store/Admin/Store/Store/GetStore';

import getSkillSlice from '../Store/Admin/Skill/getSkill';
import getAdminSkillSlice from '../Store/Admin/Skill/getAdminSkill';
import addSkillSlice from '../Store/Admin/Skill/addSkill';

import addEmployeeTypeSlice from '../Store/Admin/EmployeeType/addEmployeeType';
import getEmployeeTypeSlice from '../Store/Admin/EmployeeType/getEmployeeType';
import getAdminEmployeeTypeSlice from '../Store/Admin/EmployeeType/getAdminEmployeeType';

// ========================User ======================================================

import userLoginSlice from '../Store/User/Login/Login';
import userForgetPasswordSlice from '../Store/User/Login/ForgetPassword';
import userOtpSlice from '../Store/User/Login/VerifyOtp';
import resetUserPasswordSlice from '../Store/User/Login/ResetPassword';

import getUserDesignationSlice from '../Store/User/Designation/getUserDesignation';
import getUserAdminDesignationSlice from '../Store/User/Designation/getUserAdminDesignation';
import addUserDesignationSlice from '../Store/User/Designation/AddUserDesignation';

import getUserAdminShiftSlice from '../Store/User/Shift/UserAdminShift';
import addUserShiftSlice from '../Store/User/Shift/AddUserShift';
import getUserShiftSlice from '../Store/User/Shift/getUserShift';

import getUserFirmSlice from '../Store/User/Firm/UserFirm';

import getUserAdminDepartmentSlice from '../Store/User/Department/UserAdminDepartment';
import getUserDepartmentSlice from '../Store/User/Department/UserDepartment';
import addUserDepartmentSlice from '../Store/User/Department/ManageDepartment';

import getUserWorkSlice from '../Store/User/WorkDay/getUserWork';

import getUserSkillSlice from '../Store/User/Skill/getSkill';
import getUserAdminSkillSlice from '../Store/User/Skill/getAdminSkill';
import addUserSkillSlice from '../Store/User/Skill/addSkill';

import getUserEmployeeTypeSlice from '../Store/User/EmployeeType/getEmployeeType';
import addUserEmployeeTypeSlice from '../Store/User/EmployeeType/addEmployeeType';
import getUserAdminEmployeeTypeSlice from '../Store/User/EmployeeType/getAdminEmployeeType';

import getUserBankSlice from '../Store/User/Bank/UserBank';
import getUserAdminBankSlice from '../Store/User/Bank/UserAdminBank';
import addUserBankSlice from '../Store/User/Bank/AddUserBank';

import getUserGroupSlice from '../Store/User/Group/Group';
import getUserAdminGroupSlice from '../Store/User/Group/AdminGroupList';
import addUserGroupSlice from '../Store/User/Group/AddGroup';

import getUserAuthSlice from '../Store/User/AuthPerson/getAuthPerson';
import getUserAdminAuthSlice from '../Store/User/AuthPerson/getAdminAuth';
import addUserAuthPersonSlice from '../Store/User/AuthPerson/AddAuthPerson';


export default configureStore({
    reducer: {

        login: adminLoginSlice,
        forgetPassword: adminForgetPasswordSlice,
        adminOtp: adminOtpSlice,

        // Bank
        getBank: getBankSlice,
        getAdminBank: getAdminBankSlice,
        addBank: addBankSlice,
        editBank: editBankSlice,
        deleteBank: deleteBankSlice,

        // group 
        getGroup: getGroupSlice,
        getAdminGroup: getAdminGroupSlice,
        addGroup: addGroupSlice,

        // department
        getAdminDepartment: getAdminDepartmentSlice,
        getDepartment: getDepartmentSlice,
        addDepartment: addDepartmentSlice,

        // Designation
        getDesignation: getDesignationSlice,
        getAdminDesignation: getAdminDesignationSlice,
        addDesignation: addDesignationSlice,

        // shift
        getShift: getShiftSlice,
        addShift: addShiftSlice,
        getAdminShift: getAdminShiftSlice,

        // auth person
        addAuthPerson: addAuthPersonSlice,
        getAdminAuth: getAdminAuthSlice,
        getAuthPerson: getAuthPersonSlice,

        // year 
        getYear: getYearSlice,
        getAdminYear: getAdminYearSlice,
        addYear: addYearSlice,

        // skill
        getSkill: getSkillSlice,
        getAdminSkill: getAdminSkillSlice,
        addSkill: addSkillSlice,

        // Employee Type
        getEmployeeType: getEmployeeTypeSlice,
        getAdminEmployeeType: getAdminEmployeeTypeSlice,
        addEmployeeType: addEmployeeTypeSlice,

        getStore: getStoreSlice,
        getAdminStore: getAdminStoreSlice,

        // user side =============================================

        userLogin: userLoginSlice,
        userForgetPassword: userForgetPasswordSlice,
        userOtp: userOtpSlice,
        resetUserPassword: resetUserPasswordSlice,

        getUserDesignation: getUserDesignationSlice,
        getUserAdminDesignation: getUserAdminDesignationSlice,
        addUserDesignation: addUserDesignationSlice,

        addUserShift: addUserShiftSlice,
        getUserAdminShift: getUserAdminShiftSlice,
        getUserShift: getUserShiftSlice,

        getUserFirm: getUserFirmSlice,

        getUserDepartment: getUserDepartmentSlice,
        getUserAdminDepartment: getUserAdminDepartmentSlice,
        addUserDepartment: addUserDepartmentSlice,


        getUserWork: getUserWorkSlice,

        getUserSkill: getUserSkillSlice,
        getUserAdminSkill: getUserAdminSkillSlice,
        addUserSkill: addUserSkillSlice,

        getUserEmployeeType: getUserEmployeeTypeSlice,
        getUserAdminEmployeeType: getUserAdminEmployeeTypeSlice,
        addUserEmployeeType: addUserEmployeeTypeSlice,

        getUserBank: getUserBankSlice,
        getUserAdminBank: getUserAdminBankSlice,
        addUserBank: addUserBankSlice,

        getUserGroup: getUserGroupSlice,
        getUserAdminGroup: getUserAdminGroupSlice,
        addUserGroup: addUserGroupSlice,

        getUserAuth: getUserAuthSlice,
        getUserAdminAuth: getUserAdminAuthSlice,
        addUserAuthPerson: addUserAuthPersonSlice,

    },
});
