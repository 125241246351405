import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { PAY_URL } from '../../../BaseURL';
import toast from 'react-hot-toast';
import Header from '../Pages/Header';
import Sidebar from '../Pages/Sidebar';
import moment from 'moment';
import CopyRight from '../Pages/CopyRight';
import { MultiSelect } from 'react-multi-select-component';

const ManageLoan = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [error, setError] = useState({});
    const [disable, setDisable] = useState(false);
    const [selectValue, setSelectValue] = useState('');

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const formattedDate = `${year}-${month}-${day}`;
    const dayOfWeek = daysOfWeek[currentDate.getDay()];

    const [loan, setLoan] = useState({
        date: formattedDate,
        dayOfWeek: dayOfWeek,
        voucher_no: "",
        amount: "",
        installment_amount: "",
        auth_person: "",
        remark: "",
        loan_terms: "",
        interest_rate: "",
    });

    useEffect(() => {
        if (localStorage.getItem('PAY_USER_TOKEN') === null) {
            navigate("/user/login");
        }
    }, [navigate]);

    const [selectedEmployee, setSelectedEmployee] = useState([]);
    const [employeeData, setEmployeeData] = useState([]);
    const [authPerson, setAuthPerson] = useState([]);
    const [allData, setAllData] = useState({
        designation: "",
        department: "",
        card_no: "",
        employee_id: ""
    });

    const data = location.state;
    // console.log(data, '@@@');

    useEffect(() => {
        if (location.state) {
            setLoan({
                date: moment(location.state.date).format('YYYY-MM-DD'),
                voucher_no: location.state.voucher_no,
                // employee: location.state.employee._id,
                amount: location.state.loan_amount,
                installment_amount: location.state.installment_amount,
                auth_person: location.state.auth_person?._id,
                remark: location.state.remark,

                loan_terms: location.state.loan_terms,
                interest_rate: location.state.interest_rate,
            });
            setSelectValue(location.state.status);
            setSelectedEmployee([{
                label: `${location.state.employee.full_name} - ${location.state.employee.employee_id} - 
                ${location.state.employee?.adhar_no}`, value: location.state.employee._id
            }]);
        }
    }, [location.state]);

    useEffect(() => {
        getEmployee();
        getAuthPerson();
    }, []);


    useEffect(() => {
        const selectedDate = new Date(loan.date);
        const selectedDayOfWeek = daysOfWeek[selectedDate.getDay()];
        setLoan(prevState => ({ ...prevState, dayOfWeek: selectedDayOfWeek }));
        // eslint-disable-next-line
    }, [loan?.date]);

    const getEmployee = () => {
        const myurl = `${PAY_URL}/user/get-all-employee`;
        axios({
            method: "get",
            url: myurl,
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('PAY_USER_TOKEN') },
        }).then(async (response) => {
            // console.log(response.data, '$$$')
            if (response?.data?.success) {
                const data = response?.data?.data
                const filterData = data.filter((e) => e.firm_id === localStorage.getItem('PAY_USER_FIRM_ID'));
                setEmployeeData(filterData);
            } else {
                toast.error(response?.data?.message);
            }
        }).catch((error) => {
            toast.error(error?.response?.data?.message || "Something went wrong");
            console?.log("Errors", error);
        });
    }

    const getAuthPerson = () => {
        const myurl = `${PAY_URL}/user/get-auth-person`;
        axios({
            method: "get",
            url: myurl,
            headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('PAY_USER_TOKEN') },
        }).then(async (response) => {
            if (response?.data?.success) {
                const data = response?.data?.data
                setAuthPerson(data);
            } else {
                toast.error(response?.data?.message);
            }
        }).catch((error) => {
            toast.error(error?.response?.data?.message || "Something went wrong");
            console?.log("Errors", error);
        });
    }

    useEffect(() => {
        const selectEmployee = employeeData?.find(emp => emp?._id === selectedEmployee[0]?.value);
        setAllData({
            designation: selectEmployee?.designation?.name,
            department: selectEmployee?.salary_id?.department?.name,
            card_no: selectEmployee?.card_no,
            employee_id: selectEmployee?.employee_id
        })
    }, [selectedEmployee, employeeData]);

    const handleChange = (e) => {
        setLoan({ ...loan, [e.target.name]: e.target.value })
    }

    const selectedEmpChange = (e) => {
        if (e.length > 1) {
            e = e.slice(-1);
        }
        setSelectedEmployee(e);
    }

    const handleRadioChange = (event) => {
        setSelectValue(event.target.checked);
    };

    const handleSubmit = async (more) => {
        if (validation()) {
            try {
                const form = new URLSearchParams();
                form.append('firm_id', localStorage.getItem('PAY_USER_FIRM_ID'));
                form.append('employee', selectedEmployee[0]?.value);
                form.append('date', loan?.date);
                form.append('year_id', localStorage.getItem('PAY_USER_YEAR_ID'));

                const resSalary = await axios({
                    method: 'post',
                    url: `${PAY_URL}/user/employee-salary`,
                    data: form,
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: "Bearer " + localStorage.getItem('PAY_USER_TOKEN') }
                })

                if (resSalary.data.success) {

                    setDisable(true)

                    const bodyFormData = new URLSearchParams();
                    bodyFormData.append('firm_id', localStorage.getItem('PAY_USER_FIRM_ID'));
                    bodyFormData.append('year_id', localStorage.getItem('PAY_USER_YEAR_ID'));

                    bodyFormData.append('date', loan?.date)
                    bodyFormData.append('voucher_no', loan?.voucher_no);
                    bodyFormData.append('employee', selectedEmployee[0]?.value);
                    bodyFormData.append('loan_amount', loan?.amount)
                    bodyFormData.append('installment_amount', loan?.installment_amount)
                    bodyFormData.append('auth_person', loan?.auth_person)

                    bodyFormData.append('remark', loan?.remark)
                    bodyFormData.append('loan_terms', loan?.loan_terms)
                    bodyFormData.append('interest_rate', loan?.interest_rate)

                    if (data?._id) {
                        bodyFormData.append('id', data?._id);
                        bodyFormData?.append('status', selectValue);
                    }

                    axios({
                        method: 'post',
                        url: `${PAY_URL}/user/manage-loan`,
                        data: bodyFormData,
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: "Bearer " + localStorage.getItem('PAY_USER_TOKEN') }
                    }).then((response) => {
                        // console.log(response?.data);
                        if (response.data.success === true) {
                            toast.success(response.data.message)
                            navigate('/user/loan-management');
                        } else {
                            toast.error(response?.data?.message);
                        }
                        setDisable(false);
                    }).catch((error) => {
                        console.log(error);
                        toast.error(error?.response?.data?.message);
                        setDisable(false);
                    })
                } else {
                    toast.error(resSalary.data.message);
                }
            } catch (error) {
                console.log(error, 'ERROR')
                toast.error(error?.response?.data?.message || 'Something went wrong');
            }
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            e.preventDefault();
        }
    }

    const validation = () => {
        let isValid = true;
        let err = {};

        if (!loan.date) {
            isValid = false;
            err['date_err'] = 'Please select date'
        }

        if (!selectedEmployee?.length > 0) {
            isValid = false;
            toast.error('Please select employee')
        }

        if (!loan.auth_person) {
            isValid = false;
            err['auth_person_err'] = 'Please select auth person'
        }

        if (!loan.amount) {
            isValid = false;
            err['amount_err'] = 'Please enter amount'
        }

        if (!loan.interest_rate) {
            isValid = false;
            err['interest_rate_err'] = 'Please enter interest rate'
        }

        if (!loan.loan_terms) {
            isValid = false;
            err['loan_terms_err'] = 'Please enter loan terms'
        }
        setError(err);
        return isValid
    }

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const handleOpen = () => {
        setIsSidebarOpen(!isSidebarOpen)
    }

    return (
        <div className={`main-wrapper ${isSidebarOpen ? 'slide-nav' : ''}`}>
            <Header handleOpen={handleOpen} />
            <Sidebar />

            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <h3 className="page-title user-title">{data?._id ? 'Edit' : 'Add'} Loan</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/user/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to='/user/loan-management'>Loan Management</Link></li>
                                    <li className="breadcrumb-item active">{data?._id ? 'Edit' : 'Add'} Loan</li>
                                </ul>
                            </div>
                        </div>
                    </div>


                    <div className="card p-3 basicform">
                        <div className=" mb-3 titleForm border-bottom">
                            <h4 className="">Loans</h4>
                        </div>

                        <div className="row card_id">
                            <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                <div className="cardNum">
                                    <div className="mb-3">
                                        <label for="date" className="form-label">Date <span className='text-danger'> *</span></label>
                                        <input type="date" className="form-control" id="date"
                                            value={loan?.date} onChange={handleChange} name='date' disabled={data?._id ? 'disabled' : ''}
                                        />
                                        <div className='error'>{error?.date_err}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                <div className="cardNum">
                                    <div className="mb-3">
                                        <label htmlFor="date" className="form-label">Day</label>
                                        <input value={loan?.dayOfWeek} className="form-control" disabled />
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='row card_id'>
                            <div className="col-md-8 col-lg-8 col-xxl-8 col-sm-12">
                                <div className="cardNum">
                                    <div className="mb-3">
                                        <label htmlFor="workDepartment" className="form-label">Employee - Id - Card Id - Aadhar card  <span className='text-danger'> *</span></label>
                                        {/* <MultiSelect
                                            options={employeeData?.map(e => ({ label: `${e.full_name} - ${e?.employee_id} - ${e?.adhar_no}`, value: e._id }))}
                                            onChange={selectedEmpChange}
                                            // isLoading={true}
                                            hasSelectAll={false}
                                            value={selectedEmployee}
                                            labelledBy="Select Employee"
                                        /> */}
                                        <MultiSelect
                                            options={employeeData?.map(e => ({
                                                label: `${e.full_name} - ${e.employee_id} - ${e.card_no ? e.card_no : '-'} - ${e.adhar_no}`,
                                                value: e._id,
                                                searchableLabel: `${e.full_name} ${e.employee_id} ${e.card_no || ''}`
                                            }))}
                                            onChange={selectedEmpChange}
                                            hasSelectAll={false}
                                            value={selectedEmployee}
                                            labelledBy="Select Employee"
                                            filterOptions={(options, filter) =>
                                                options.filter(({ searchableLabel }) =>
                                                    searchableLabel.toLowerCase().includes(filter.toLowerCase())
                                                )
                                            }
                                        />
                                        <div className='error'>{error?.employee_err}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {selectedEmployee.length > 0 ? (
                            <div className='row card_id'>
                                <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                    <div className="cardNum">
                                        <div className="mb-3">
                                            <label className="form-label">Desgination</label>
                                            <input type="text" className="form-control" value={allData?.designation} disabled />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                    <div className="cardNum">
                                        <div className="mb-3">
                                            <label className="form-label">Department</label>
                                            <input type="text" className="form-control" value={allData?.department || '-'} disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        <div className="row card_id">
                            <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                <div className="cardNum">
                                    <div className="mb-3">
                                        <label for="authPerson" className="form-label">Auth Person <span className='text-danger'> *</span></label>
                                        <select className="form-select form-control" aria-label="Default select example"
                                            onChange={handleChange} name='auth_person' value={loan?.auth_person}
                                        >
                                            <option value=''>Select Auth Person</option>
                                            {authPerson?.map((elem) =>
                                                <option value={elem?._id} key={elem?._id}>{elem?.name}</option>
                                            )}
                                        </select>
                                        <div className='error'>{error?.auth_person_err}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                <div className="cardNum">
                                    <div className="mb-3">
                                        <label for="voucherNumber" className="form-label">Loan Amount <span className='text-danger'> *</span></label>
                                        <input type="number" className="form-control" id="voucherNumber"
                                            onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown}
                                            onChange={handleChange} name='amount' value={loan?.amount}
                                        />
                                        <div className='error'>{error?.amount_err}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                <div className="cardNum">
                                    <div className="mb-3">
                                        <label className="form-label">Loan Terms <span className='text-danger'> *</span></label>
                                        <input type="number" className="form-control"
                                            onChange={handleChange} value={loan?.loan_terms} name='loan_terms'
                                        />
                                        <div className='error'>{error?.loan_terms_err}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                <div className="cardNum">
                                    <div className="mb-3">
                                        <label className="form-label">Interest Rate(%) <span className='text-danger'> *</span></label>
                                        <input type="text" className="form-control"
                                            onChange={handleChange} value={loan?.interest_rate} name='interest_rate'
                                        />
                                        <div className='error'>{error?.interest_rate_err}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                <div className="cardNum">
                                    <div className="mb-3">
                                        <label className="form-label">Installment Amount</label>
                                        <input type="number" className="form-control"
                                            onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown}
                                            onChange={handleChange} value={loan?.installment_amount} name='installment_amount'
                                        />
                                        <div className='error'>{error?.installment_amount_err}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row card_id">
                            <div className="col-md-12 col-lg-12 col-xxl-12 col-sm-12">
                                <div className="cardNum">
                                    <div className="mb-3">
                                        <label for="remark" className="form-label">Remark</label>
                                        <textarea className="form-control" placeholder="Comment here...."
                                            id="remark"
                                            name='remark' onChange={handleChange} value={loan?.remark}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>

                            {data?._id ? (
                                <div className='col-md-4 col-lg-4 col-xxl-4 col-sm-12'>
                                    <div className="cardNum">
                                        <div className="mb-3">
                                            <label htmlFor="fileUpload" className="form-label">Status</label>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" onChange={handleRadioChange} checked={selectValue} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>

                        <div className="row card_id mt-4 sm-mt-3">
                            <div className=" col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                <div className="cardNum">
                                    <div className="mb-3 d-flex gap-2">
                                        <button className="btn btn-success w-50" type='button' onClick={handleSubmit} disabled={disable}>{disable ? "Processing..." : (data?._id ? 'Update' : 'Submit')}</button>
                                        <button className="btn btn-outline-secondary w-50" type='button' onClick={() => setLoan({
                                            date: "",
                                            voucher_no: "",
                                            employee: "",
                                            amount: "",
                                            installment_amount: "",
                                            auth_person: "",
                                            remark: "",
                                            loan_terms: "",
                                            interest_rate: "",
                                        })} > Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CopyRight />
            </div>
        </div >
    )
}

export default ManageLoan