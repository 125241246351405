import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Login from '../components/Home/Login'


const Home = () => {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/user/login" />} />
            <Route path="/" element={<Login />} />
        </Routes>
    )
}

export default Home