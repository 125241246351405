import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { addUserDepartment } from '../../../../Store/User/Department/ManageDepartment'
import { getUserGroup } from '../../../../Store/User/Group/Group'
import Header from '../../Pages/Header'
import Sidebar from '../../Pages/Sidebar'
import CopyRight from '../../Pages/CopyRight'

const ManageDepartment = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = location.state;
  // console.log(data, '@@');

  useEffect(() => {
    if (location.state) {
      setDepartment({
        name: location.state?.name,
        group: location.state?.group?._id
      });
      setSelectValue(location.state?.status);
    }
  }, [location.state]);

  useEffect(() => {
    if (localStorage.getItem('PAY_USER_TOKEN') === null) {
      navigate("/user/login");
    }
  }, [navigate]);

  useEffect(() => {

    const fetchData = async () => {
      try {
        await dispatch(getUserGroup())
        setDisable(false);
      } catch (error) {
        console.log(error, '!!')
        setDisable(false);
      }
    }

    fetchData();
  }, [dispatch]);


  const [department, setDepartment] = useState({ name: "", group: "" });
  const [disable, setDisable] = useState(false);
  const [error, setError] = useState('');
  const [selectValue, setSelectValue] = useState('');

  const groupData = useSelector((state) => state?.getUserGroup?.user?.data);

  // console.log(groupData, 'GRPU{')

  const handleRadioChange = (event) => {
    setSelectValue(event.target.checked);
  };

  const handleChange = (e) => {
    setDepartment({ ...department, [e.target.name]: e.target.value })
  }

  const handleSubmit = () => {
    if (validation()) {
      setDisable(true)
      const formData = new URLSearchParams();

      formData.append('name', department?.name);
      formData.append('group', department?.group);
      if (data?._id) {
        formData.append('id', data?._id);
        formData.append('status', selectValue);
      }

      dispatch(addUserDepartment(formData))
        .then((res) => {
          // console.log(res, 'REs');
          if (res.payload.success === true) {
            navigate('/user/department-management');
            setDepartment({ name: "", group: "" });
          }
          setDisable(false)
        }).catch((error) => {
          console.log(error, 'Error')
          setDisable(false)
        })
    }
  }

  const validation = () => {
    var isValid = true;
    let err = {};

    if (!department.name || !department?.name?.trim()) {
      isValid = false;
      err['name_err'] = "Please enter name"
    }

    if (!department.group) {
      isValid = false;
      err['group_err'] = "Please select group"
    }

    setError(err);
    return isValid;
  }

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const handleOpen = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }

  return (
    <div className={`main-wrapper ${isSidebarOpen ? 'slide-nav' : ''}`}>
      <Header handleOpen={handleOpen} />
      <Sidebar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title user-title">{data?._id ? 'Edit' : 'Add'} Department</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/user/dashboard">Dashboard</Link></li>
                  <li className="breadcrumb-item"><Link to="/user/department-management">Department Management</Link></li>
                  <li className="breadcrumb-item active">{data?._id ? 'Edit' : 'Add'} Department</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="card p-3 basicform">
            <div className="row card_id d-flex justify-content-start align-center">
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="Name" className="form-label">Name</label>
                    <input type="text" className="form-control" id="Name"
                      name='name' onChange={handleChange} value={department.name} />
                    <div className='error'>{error.name_err}</div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                <div className="cardNum">
                  <div className="mb-3">
                    <label htmlFor="Name" className="form-label">Group</label>
                    <select className="form-select form-control"
                      onChange={handleChange} value={department.group} name='group'
                      aria-label="Default select example">
                      <option value="">Select Group</option>
                      {groupData?.map((e) =>
                        <option value={e?._id} key={e?._id}>{e?.name}</option>
                      )}
                    </select>
                    <div className='error'>{error.group_err}</div>
                  </div>
                </div>
              </div>

              {data?._id ? (
                <div className='col-md-4 col-lg-4 col-xxl-4 col-sm-12'>
                  <div className="cardNum">
                    <div className="mb-3">
                      <label htmlFor="fileUpload" className="form-label">Status</label>
                      <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" onChange={handleRadioChange} checked={selectValue} />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}


              <div className="row card_id mt-4 sm-mt-3">
                <div className=" col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                  <div className="cardNum">
                    <div className="mb-3 d-flex gap-2">
                      <button className="btn btn-success w-50" type='button' onClick={handleSubmit}>{disable ? "Processing..." : (data?._id ? 'Update' : 'Submit')}</button>
                      <button className="btn btn-outline-secondary w-50" type='button' onClick={() => setDepartment({
                        name: "", group: ""
                      })}>Reset</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CopyRight />
      </div>
    </div>
  )
}

export default ManageDepartment