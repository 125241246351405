import React, { useEffect, useState } from 'react'
import OTPInput from 'react-otp-input';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userForgetPassword } from '../../../Store/User/Login/ForgetPassword';
import { userOtp } from '../../../Store/User/Login/VerifyOtp';

const Otp = () => {

    const [otp, setOtp] = useState("");
    const [minutes, setMinutes] = useState(1);
    const [seconds, setSeconds] = useState(0);

    const [err, setErr] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [disable, setDisable] = useState(false);


    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(60);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds, minutes]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (otp) {
            setDisable(true);
            setErr('')
            dispatch(userOtp({ otp_data: otp }))
                .then((response) => {
                    // console.log(response, "RES");
                    if (response.payload.success === true) {
                        navigate('/user/reset-password');
                    }
                    setOtp('');
                    setDisable(false)
                }).catch((error) => {
                    console.log(error, '!!')
                    setDisable(false);
                })
        } else {
            setErr('Please enter otp');
            setDisable(false);
        }
    }

    const resendOTP = () => {
        setDisable(true)
        dispatch(userForgetPassword())
            .then(() => {
                setMinutes(1);
                setSeconds(0);
                setDisable(false);
            }).catch((error) => {
                console.log(error, "ERR");
                setDisable(false);
            })
    }
    return (
        <div className="account-page login-page">
            <div className="frame-login"></div>
            <div className="container">
                <div className="row login-Row">
                    <div className="col-md-6 col-lg-5 col-sm-12">
                        <div className="card p-3 cardTransparent">
                            <div className="logoLogin d-flex align-center justify-content-center mb-3">
                                <img src="/assets/img/ColorLogo.svg" width="150px" height="auto" alt="Logo" />
                            </div>
                            <h3 className="account-title  mt-4 mb-4 text-center">Validate One Time Passcode</h3>
                            <p className="account-subtitle">Enter 4 digit secure code, received by register email</p>


                            <form className="row g-3" onSubmit={handleSubmit}>
                                <div className="col-md-12 otp-group">
                                    <OTPInput
                                        value={otp}
                                        onChange={(val) => setOtp(val)}
                                        numInputs={4}
                                        className='form-control'
                                        renderSeparator={<span>-</span>}
                                        // renderInput={(props) => <input {...props} />}
                                        renderInput={(props, index) => (
                                            <input
                                                {...props}
                                                type="number"
                                            />
                                        )}
                                    />
                                    <span className='error'>{err}</span>
                                </div>

                                <div style={{ display: 'flex', alignItems: 'center' }}>

                                    {seconds > 0 || minutes > 0 ? (
                                        <p className='otp-time' style={{ paddingTop: '15px' }}>
                                            Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                                            {seconds < 10 ? `0${seconds}` : seconds}
                                        </p>
                                    ) : (
                                        <p className='otp-time' style={{ paddingTop: '12px' }}>Didn't recieve code?</p>
                                    )}
                                    <button className="otp-button"
                                        type='button'
                                        style={{ border: 'none', background: 'none', display: 'flex', alignItems: "center", justifyContent: 'flex-end', flex: 1, cursor: 'pointer', color: seconds > 0 || minutes > 0 ? "grey" : "#3e42be" }}
                                        onClick={resendOTP} disabled={seconds > 0 || minutes > 0}>Resend OTP</button>
                                </div>

                                <div className="input-block mb-4 text-center">
                                    <button className="btn btn-success account-btn w-100" type="submit" disabled={disable}>{disable ? "Processing..." : "Submit"}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Otp