import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Login from '../components/User/Login/Login'
import Dashboard from '../components/User/Dashboard/Dashboard'
import ForgetPassword from '../components/User/Login/ForgetPassword'
import Otp from '../components/User/Login/Otp'
import ResetPassword from '../components/User/Login/ResetPassword'
import EditProfile from '../components/User/Profile/EditProfile'
import Employee from '../components/User/Employee/Employee'
import ManageEmployee from '../components/User/Employee/ManageEmployee'
import WorkDayList from '../components/User/WorkDay/WorkDayList'
import ManageWorkDay from '../components/User/WorkDay/ManageWorkDay'
import SalaryList from '../components/User/Salary/SalaryList'
import ManageSalary from '../components/User/Salary/ManageSalary'
import Monthly from '../components/User/Attendance/Monthly/Monthly'
import ManageMonthly from '../components/User/Attendance/Monthly/ManageMonthly'

import ManageDaily from '../components/User/Attendance/Daily/ManageDaily'
import Holiday from '../components/User/Holiday/Holiday'
import ManageHoliday from '../components/User/Holiday/ManageHoliday'
import Earning from '../components/User/Earning/Earning'
import ManageEarning from '../components/User/Earning/ManageEarning'
import Deduction from '../components/User/Deduction/Deduction'
import ManageDeduction from '../components/User/Deduction/ManageDeduction'
import Loan from '../components/User/Loan/Loan'
import ManageLoan from '../components/User/Loan/ManageLoan'
import Report from '../components/User/Report/Report'
import ViewReport from '../components/User/Report/ViewReport'
import ReportList from '../components/User/Report/ReportList'
import DailyAllList from '../components/User/Attendance/Daily/DailyAllList'
import BankList from '../components/User/PayrollMaster/Bank/BankList'
import ManageBank from '../components/User/PayrollMaster/Bank/ManageBank'
import GroupList from '../components/User/PayrollMaster/Group/GroupList'
import ManageGroup from '../components/User/PayrollMaster/Group/ManageGroup'
import DepartmentList from '../components/User/PayrollMaster/Department/DepartmentList'
import ManageDepartment from '../components/User/PayrollMaster/Department/ManageDepartment'
import DesignationList from '../components/User/PayrollMaster/Designation/DesignationList'
import ManageDesignation from '../components/User/PayrollMaster/Designation/ManageDesignation'
import ShiftList from '../components/User/PayrollMaster/Shift/ShiftList'
import ManageShift from '../components/User/PayrollMaster/Shift/ManageShift'
import AuthPersonList from '../components/User/PayrollMaster/AuthPerson/AuthPersonList'
import ManageAuthPerson from '../components/User/PayrollMaster/AuthPerson/ManageAuthPerson'
import Skill from '../components/User/PayrollMaster/Skill/Skill'
import ManageSkill from '../components/User/PayrollMaster/Skill/ManageSkill'
import EmployeeType from '../components/User/PayrollMaster/EmployeeType/EmployeeType'
import ManageEmployeeType from '../components/User/PayrollMaster/EmployeeType/ManageEmployeeType'
import DailyReport from '../components/User/Attendance/Daily/DailyReport'
import DecryptCode from '../components/User/Components/DecryptCode';

const User = () => {

    const descryptVal = DecryptCode();

    return (
        <Routes>
            <Route path="/user" element={<Navigate to="/user/dashboard" />} />

            <Route path='/user/dashboard' element={<Dashboard />} />

            <Route path='/user/login' element={<Login />} />
            <Route path='/user/forget-password' element={<ForgetPassword />} />
            <Route path='/user/otp-verification' element={<Otp />} />
            <Route path='/user/reset-password' element={<ResetPassword />} />

            <Route path='/user/edit-profile' element={<EditProfile />} />

            {/* Employee */}
            <Route path='/user/employee-management' element={<Employee />} />
            <Route path='/user/manage-employee' element={<ManageEmployee />} />

            {/* Workday */}
            <Route path='/user/workingday-management' element={<WorkDayList />} />
            <Route path='/user/manage-workingday' element={<ManageWorkDay />} />

            {/* Salary */}
            {descryptVal === false ? (
                <>
                    <Route path='/user/salary-management' element={<SalaryList />} />
                    <Route path='/user/manage-salary' element={<ManageSalary />} />
                </>
            ) : null}

            {/* Monthly Attendance */}
            <Route path='/user/monthly-attendance-management' element={<Monthly />} />
            <Route path='/user/manage-monthly-attendance' element={<ManageMonthly />} />

            {/* Daily Attendance */}
            {/* <Route path='/user/daily-attendance-management' element={<Daily />} /> */}
            <Route path='/user/daily-attendance-management' element={<DailyAllList />} />
            <Route path='/user/manage-daily-attendance' element={<ManageDaily />} />
            <Route path='/user/daily-attendance-report' element={<DailyReport />} />

            {/* Holiday */}
            <Route path='/user/holiday-management' element={<Holiday />} />
            <Route path='/user/manage-holiday' element={<ManageHoliday />} />

            {/* Earning */}
            <Route path='/user/earning-management' element={<Earning />} />
            <Route path='/user/manage-earning' element={<ManageEarning />} />

            {/* Deduction */}
            <Route path='/user/deduction-management' element={<Deduction />} />
            <Route path='/user/manage-deduction' element={<ManageDeduction />} />

            {/* Loan */}
            <Route path='/user/loan-management' element={<Loan />} />
            <Route path='/user/manage-loan' element={<ManageLoan />} />

            {/* Report */}
            {descryptVal === false ? (
                <>
                    <Route path='/user/report-management' element={<Report />} />
                    <Route path='/user/view-report' element={<ViewReport />} />
                    <Route path='/user/report-list' element={<ReportList />} />
                </>
            ) : null}

            {/* Masters */}
            <Route path='/user/bank-management' element={<BankList />} />
            <Route path='/user/manage-bank' element={<ManageBank />} />

            <Route path='/user/group-management' element={<GroupList />} />
            <Route path='/user/manage-group' element={<ManageGroup />} />

            <Route path='/user/department-management' element={<DepartmentList />} />
            <Route path='/user/manage-department' element={<ManageDepartment />} />

            <Route path='/user/designation-management' element={<DesignationList />} />
            <Route path='/user/manage-designation' element={<ManageDesignation />} />

            <Route path='/user/shift-management' element={<ShiftList />} />
            <Route path='/user/manage-shift' element={<ManageShift />} />

            <Route path='/user/authorized-person-management' element={<AuthPersonList />} />
            <Route path='/user/manage-authorized-person' element={<ManageAuthPerson />} />

            <Route path='/user/skill-management' element={<Skill />} />
            <Route path='/user/manage-skill' element={<ManageSkill />} />

            <Route path='/user/employee-type-management' element={<EmployeeType />} />
            <Route path='/user/manage-employee-type' element={<ManageEmployeeType />} />

            <Route path="/user/*" element={<Navigate to="/user/dashboard" />} />
        </Routes>
    )
}

export default User