import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, Link } from 'react-router-dom';

import Header from '../Pages/Header';
import Sidebar from '../Pages/Sidebar';
import { PAY_URL } from '../../../BaseURL';
import toast from 'react-hot-toast';
import axios from 'axios';
import { getUserDepartment } from '../../../Store/User/Department/UserDepartment';
import moment from 'moment';
import CopyRight from '../Pages/CopyRight';


const ManageWorkDay = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [error, setError] = useState({});
    const [disable, setDisable] = useState(false);
    const [disableMore, setDisableMore] = useState(false);
    const [disable1, setDisable1] = useState(true);
    const [selectValue, setSelectValue] = useState('');

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;

    const year = currentDate.getFullYear();
    const monthTwo = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${monthTwo}-${day}`;

    const [work, setWork] = useState({
        month: currentMonth,
        department: "",
        working_day: "",
        ot_hours: "",
        ot_count: 1,
        pf: 8.33,
        fpf: 3.67,
        esi: 0.75,
        lwf: 0,
        firm_id: "",
    });

    const data = location.state;

    useEffect(() => {
        if (localStorage.getItem('PAY_USER_TOKEN') === null) {
            navigate("/user/login");
        }
    }, [navigate]);

    useEffect(() => {
        if (location.state) {
            setWork({
                month: location?.state?.month,
                department: location?.state?.department?._id,
                working_day: location?.state?.working_day,
                ot_hours: location?.state?.ot_hours,
                ot_count: location?.state?.ot_count,
                pf: location?.state?.pf,
                fpf: location?.state?.fpf,
                esi: location?.state?.esi,
                lwf: location?.state?.lwf,
            });
            setSelectValue(location?.state?.status);
        }

    }, [location.state])

    useEffect(() => {
        const fetchDepartment = async () => {
            if (disable1 === true) {
                try {
                    await dispatch(getUserDepartment())
                    setDisable1(false);
                } catch (error) {
                    console.log(error, '!!')
                    setDisable1(false);
                }
            }
        }

        fetchDepartment();

    }, [dispatch, disable1]);

    const departmentData = useSelector((state) => state?.getUserDepartment?.user?.data);

    const handleChange = (e) => {
        setWork({ ...work, [e.target.name]: e.target.value });
    }

    const handleRadioChange = (event) => {
        setSelectValue(event.target.checked);
    };

    const handleSubmit = (more) => {
        if (!data?._id) {
            if (formattedDate > moment(localStorage.getItem('PAY_USER_END_YEAR')).format('YYYY-MM-DD')) {
                toast.error('Current date has passed the financial year end date. Please contact the administrator to add the new financial year.')
                return
            }
        }

        if (validation()) {
            setDisable(true);
            if (more === 'true') {
                setDisableMore(true);
            }
            const bodyFormData = new URLSearchParams();
            bodyFormData.append('department', work?.department);
            bodyFormData.append('working_day', work?.working_day);
            bodyFormData.append('ot_hours', work?.ot_hours);
            bodyFormData.append('ot_count', work?.ot_count);
            bodyFormData.append('pf', work?.pf);
            bodyFormData.append('fpf', work?.fpf);
            bodyFormData.append('esi', work?.esi);
            bodyFormData.append('lwf', work?.lwf);
            bodyFormData.append('month', work?.month);

            bodyFormData.append('firm_id', localStorage.getItem('PAY_USER_FIRM_ID'));
            bodyFormData.append('year_id', localStorage.getItem('PAY_USER_YEAR_ID'));

            if (data?._id) {
                bodyFormData.append('id', data?._id);
                bodyFormData?.append('status', selectValue);
            }

            axios({
                method: 'post',
                url: `${PAY_URL}/user/manage-workday`,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: "Bearer " + localStorage.getItem('PAY_USER_TOKEN') }
            }).then((response) => {
                // console.log(response?.data);
                if (response.data.success === true) {
                    if (more !== 'true') {
                        navigate('/user/workingday-management');
                    }
                    toast.success(response?.data?.message)
                    setWork({
                        month: currentMonth,
                        department: "",
                        working_day: "",
                        ot_hours: "",
                        ot_count: 1,
                        pf: 8.33,
                        fpf: 3.67,
                        esi: 0.75,
                        lwf: 0,
                        firm_id: "",
                    })
                }
                setDisable(false);
                if (more === 'true') {
                    setDisableMore(false);
                }
            }).catch((error) => {
                console.log(error);
                toast.error(error?.response?.data?.message);
                setDisable(false);
                if (more === 'true') {
                    setDisableMore(false);
                }
            })
        }
    }

    const validation = () => {
        let isValid = true;
        let err = {};

        if (!work.department) {
            isValid = false;
            err['department_err'] = "Please select department"
        }

        if (!work?.month) {
            isValid = false;
            err['month_err'] = "Please select month"
        }

        if (!work?.working_day) {
            isValid = false;
            err['working_day_err'] = "Please enter working day"
        }

        if (!work?.ot_hours) {
            isValid = false;
            err['ot_hours_err'] = "Please enter ot hours"
        }

        if (!work?.ot_count) {
            isValid = false;
            err['ot_count_err'] = "Please enter ot count"
        }

        setError(err);
        return isValid
    }

    const handleReset = () => {
        setWork({
            month: currentMonth,
            department: "",
            working_day: "",
            ot_hours: "",
            ot_count: "",
            pf: "",
            fpf: "",
            esi: "",
            lwf: "",
            firm_id: "",
        })
    }

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const handleOpen = () => {
        setIsSidebarOpen(!isSidebarOpen)
    }

    return (
        <div className={`main-wrapper ${isSidebarOpen ? 'slide-nav' : ''}`}>
            <Header handleOpen={handleOpen} />
            <Sidebar />

            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <h3 className="page-title user-title">{data?._id ? 'Edit' : 'Add'} Working Day</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/user/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to='/user/workingday-management'>Working Day Management</Link></li>
                                    <li className="breadcrumb-item active">{data?._id ? 'Edit' : 'Add'} Working Day</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="card p-3 basicform">
                        <div className=" mb-3 titleForm border-bottom">
                            <h4 className="">Work Day</h4>
                        </div>

                        <div className='row card_id'>

                            <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                <div className="cardNum">
                                    <div className="mb-3">
                                        <label htmlFor="month" className="form-label">Month <span className='text-danger'> *</span></label>
                                        <select className="form-select form-control"
                                            onChange={handleChange} name='month' value={work?.month} disabled={data?._id ? 'disabled' : ''}
                                            aria-label="Default select example">
                                            <option value="">Select Month</option>
                                            <option value="1">January</option>
                                            <option value="2">February</option>
                                            <option value="3">March</option>
                                            <option value="4">April</option>
                                            <option value="5">May</option>
                                            <option value="6">June</option>
                                            <option value="7">July</option>
                                            <option value="8">August</option>
                                            <option value="9">September</option>
                                            <option value="10">October</option>
                                            <option value="11">November</option>
                                            <option value="12">December</option>
                                        </select>
                                        <div className='error'>{error?.month_err}</div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                <div className="cardNum">
                                    <div className="mb-3">
                                        <label htmlFor="Department" className="form-label">Department <span className='text-danger'> *</span></label>
                                        <select className="form-select form-control" aria-label="Default select example"
                                            onChange={handleChange} value={work?.department} name='department'
                                        >
                                            <option value=''>Select Department</option>
                                            {departmentData?.map((e) =>
                                                <option value={e?._id} key={e?._id}>{e?.name}</option>
                                            )}
                                        </select>
                                        <div className='error'>{error?.department_err}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row card_id">

                            <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                <div className="cardNum">
                                    <div className="mb-3">
                                        <label htmlFor="workingDay" className="form-label">Work Day <span className='text-danger'> *</span></label>
                                        <input type="number" className="form-control" id="workingDay"
                                            onChange={handleChange} name='working_day' value={work?.working_day} />
                                        <div className='error'>{error?.working_day_err}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                <div className="cardNum">
                                    <div className="mb-3">
                                        <label htmlFor="othour" className="form-label">Work Hours <span className='text-danger'> *</span></label>
                                        <input type="number" className="form-control" id="othour"
                                            onChange={handleChange} name='ot_hours' value={work?.ot_hours}
                                        />
                                        <div className='error'>{error?.ot_hours_err}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                <div className="cardNum">
                                    <div className="mb-3">
                                        <label htmlFor="OtCount" className="form-label">Ot Count <span className='text-danger'> *</span></label>
                                        <input type="number" className="form-control" id="OtCount"
                                            onChange={handleChange} name='ot_count' value={work?.ot_count}
                                        />
                                        <div className='error'>{error?.ot_hours_err}</div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row card_id">
                            <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                <div className="cardNum">
                                    <div className="mb-3">
                                        <label htmlFor="PF" className="form-label">PF(%)</label>
                                        <input type="number" className="form-control" id="PF"
                                            value={work?.pf}
                                            name='pf' onChange={handleChange} />
                                        <div className='error'>{error?.ot_count_err}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">

                                <div className="cardNum">
                                    <div className="mb-3">
                                        <label htmlFor="FPF" className="form-label">FPF(%)</label>
                                        <input type="number" className="form-control" id="FPF" onChange={handleChange} name='fpf' value={work?.fpf} />
                                        <div className='error'>{error?.fpf_err}</div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                <div className="cardNum">
                                    <div className="mb-3">
                                        <label htmlFor="ESI" className="form-label">ESI(%)</label>
                                        <input type="number" className="form-control" id="ESI"
                                            onChange={handleChange} name='esi' value={work?.esi}
                                        />
                                        <div className='error'>{error?.esi_err}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row card_id">
                            <div className="col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                <div className="cardNum">
                                    <div className="mb-3">
                                        <label htmlFor="LWF" className="form-label">LWF </label>
                                        <input type="number" className="form-control" id="LWF"
                                            name='lwf' onChange={handleChange} value={work?.lwf}
                                        />
                                        <div className='error'>{error?.lwf_err}</div>
                                    </div>
                                </div>
                            </div>

                            {data?._id ? (
                                <div className='col-md-4 col-lg-4 col-xxl-4 col-sm-12'>
                                    <div className="cardNum">
                                        <div className="mb-3">
                                            <label htmlFor="fileUpload" className="form-label">Status</label>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" onChange={handleRadioChange} checked={selectValue} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>

                        <div className="row card_id mt-4 sm-mt-3">
                            <div className=" col-md-4 col-lg-4 col-xxl-4 col-sm-12">
                                <div className="cardNum">
                                    <div className="mb-3 d-flex gap-2">
                                        <button className="btn btn-success w-50" type='button' onClick={handleSubmit} disabled={disable}>{disable ? "Processing..." : (data?._id ? 'Update' : 'Submit')}</button>
                                        <button className="btn btn-outline-secondary w-50" type='button' onClick={handleReset} >Reset</button>
                                        {!data?._id ? <button type="button" className="btn btn-outline-success w-50" onClick={() => handleSubmit('true')} disabled={disableMore}>{disableMore ? "Processing..." : 'Add More'}</button> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CopyRight />
            </div>
        </div>
    )
}

export default ManageWorkDay