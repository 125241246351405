import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import axios from 'axios'
import toast from 'react-hot-toast'
import { Dropdown } from 'react-bootstrap'
import Header from '../../Pages/Header'
import Sidebar from '../../Pages/Sidebar'
import { Pagination, Search } from '../../Table'
import Loader from '../../Pages/Loader'
import { PAY_URL } from '../../../../BaseURL'
import { getUserAdminDesignation } from '../../../../Store/User/Designation/getUserAdminDesignation'
import CopyRight from '../../Pages/CopyRight'


const DesignationList = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [limit, setlimit] = useState(10);
    const [disable, setDisable] = useState(true);

    useEffect(() => {
        if (localStorage.getItem('PAY_USER_TOKEN') === null) {
            navigate("/user/login");
        }
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
            if (disable === true) {
                try {
                    await dispatch(getUserAdminDesignation())
                    setDisable(false);
                } catch (error) {
                    console.log(error, '!!')
                    setDisable(false);
                }
            }
        }

        fetchData();

    }, [dispatch, disable]);

    const entity = useSelector((state) => state?.getUserAdminDesignation?.user?.data)
    // console.log(entity, '@@@@');

    const commentsData = useMemo(() => {
        let computedComments = entity;

        if (search) {
            computedComments = computedComments.filter(
                (designation) =>
                    designation.name?.toLowerCase()?.includes(search?.toLowerCase())

            );
        }
        setTotalItems(computedComments?.length);

        //Current Page slice
        return computedComments?.slice(
            (currentPage - 1) * limit,
            (currentPage - 1) * limit + limit
        );
    }, [currentPage, search, limit, entity]);

    const handleDelete = (id, title) => {
        Swal.fire({
            title: `Are you sure want to delete ${title}?`,
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {

                const myurl = `${PAY_URL}/user/delete-designation`;
                var bodyFormData = new URLSearchParams();
                bodyFormData.append("id", id);

                axios({
                    method: "delete",
                    url: myurl,
                    data: bodyFormData,
                    headers: { "Content-Type": "application/x-www-form-urlencoded", Authorization: "Barrer " + localStorage.getItem('PAY_USER_TOKEN') },
                }).then((response) => {
                    // console.log(response.data, 'DEL')
                    if (response.data.success === true) {
                        toast.success(response?.data?.message);
                        setDisable(true);
                    } else {
                        toast.error(response?.data?.message);
                    }
                }).catch((error) => {
                    toast.error(error?.response?.data?.message || "Something went wrong");
                    console?.log("Errors", error);
                });
            }
        });
    }

    const handleRefresh = () => {
        setDisable(true)
    }

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const handleOpen = () => {
        setIsSidebarOpen(!isSidebarOpen)
    }

    return (
        <div className={`main-wrapper ${isSidebarOpen ? 'slide-nav' : ''}`}>
            <Header handleOpen={handleOpen} />
            <Sidebar />

            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className="page-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <h3 className="page-title user-title">Designation Management</h3>
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/user/dashboard">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Designation Management</li>
                                </ul>
                            </div>
                            <div className="col-auto float-end ms-auto">
                                <Link to='/user/manage-designation' className="btn btn-success"><i className="fa-solid fa-plus"></i> Add Designation</Link>
                            </div>
                        </div>
                    </div>

                    <div className="row filter-row justify-content-end">
                        <div className="col-sm-12 col-md-8">
                            <div className="row justify-content-end">
                                <div className="col-md-12 mb-2">
                                    <div className="justify-content-end d-flex align-center flex-wrap gap-2">
                                        <div className="seletePage d-flex gap-2 align-items-center ">
                                            <p className="mb-0">Rows per page:</p>
                                            <div className="d-flex align-items-center justify-content-center gap-2">
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        variant="none"
                                                        id="dropdown-basic"
                                                        style={{
                                                            cursor: "auto",
                                                            backgroundColor: "white",
                                                            borderColor: "#d5dbe0",
                                                            paddingBottom: "3px",
                                                            paddingTop: "3px",
                                                        }}
                                                        className='form-control selectWidth'
                                                    >
                                                        {limit}&nbsp;
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {limit !== 10 ? (
                                                            <>
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setlimit(10);
                                                                    }}
                                                                >
                                                                    10
                                                                </Dropdown.Item>
                                                            </>
                                                        ) : null}

                                                        {limit !== 20 ? (
                                                            <>
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setlimit(20);
                                                                    }}
                                                                >
                                                                    20
                                                                </Dropdown.Item>
                                                            </>
                                                        ) : null}

                                                        {limit !== 30 ? (
                                                            <>
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setlimit(30);
                                                                    }}
                                                                >
                                                                    30
                                                                </Dropdown.Item>
                                                            </>
                                                        ) : null}

                                                        {limit !== 50 ? (
                                                            <>
                                                                <Dropdown.Item
                                                                    onClick={() => {
                                                                        setlimit(50);
                                                                    }}
                                                                >
                                                                    50
                                                                </Dropdown.Item>
                                                            </>
                                                        ) : null}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <div className="refreshPage">
                                                    <button className="btn btn-refresh" type='button' onClick={handleRefresh}>
                                                        <i className="fa-solid fa-arrows-rotate"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tableSearch">
                                            <form action="">
                                                <Search
                                                    onSearch={(value) => {
                                                        setSearch(value);
                                                        setCurrentPage(1);
                                                    }} />
                                                <span className="search">
                                                    <i className="fa-solid fa-magnifying-glass"></i>
                                                </span>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {disable === false ? (
                        <div className="card p-3 ">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="table-responsive">
                                        <table className="table table-striped custom-table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Sr.</th>
                                                    <th>Name</th>
                                                    <th>Status</th>
                                                    <th className="text-end">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {commentsData?.map((elem, i) =>
                                                    <tr key={elem?._id}>
                                                        <td>{(currentPage - 1) * limit + i + 1}</td>
                                                        <td>{elem?.name}</td>
                                                        <td>
                                                            {elem.status === true ? (
                                                                <span className="badge text-bg-success">True</span>
                                                            ) : (
                                                                <span className="badge text-bg-danger">False</span>
                                                            )}
                                                        </td>
                                                        <td className="text-end">
                                                            <div className="dropdown dropdown-action">
                                                                {/* eslint-disable jsx-a11y/anchor-is-valid */}
                                                                <a href="#" className="action-icon dropdown-toggle"
                                                                    data-bs-toggle="dropdown" aria-expanded="false"><i
                                                                        className="material-icons">more_vert</i></a>
                                                                <div className="dropdown-menu dropdown-menu-right">
                                                                    <button type='button' onClick={() => navigate('/user/manage-designation', { state: elem })} className="dropdown-item"><i
                                                                        className="fa-solid fa-pencil m-r-5"></i> Edit</button>
                                                                    <button type='button' className="dropdown-item" onClick={() => handleDelete(elem?._id, elem?.name)}><i
                                                                        className="fa-regular fa-trash-can m-r-5"></i> Delete</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}

                                                {commentsData?.length === 0 ? (
                                                    <tr>
                                                        <td colspan="999">
                                                            <div className="no-table-data">
                                                                No Data Found!
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ) : null}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row align-center mt-3 user-account">
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-6">
                                            <div className="dataTables_info" id="DataTables_Table_0_info" role="status"
                                                aria-live="polite">Showing {Math.min(limit, totalItems)} from {totalItems} data</div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-6 ">
                                            <div className="dataTables_paginate paging_simple_numbers"
                                                id="DataTables_Table_0_paginate">
                                                <Pagination
                                                    total={totalItems}
                                                    itemsPerPage={limit}
                                                    currentPage={currentPage}
                                                    onPageChange={(page) => setCurrentPage(page)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : <Loader />}
                </div>
                <CopyRight />
            </div>
        </div>
    )
}

export default DesignationList